import type React from 'react';
import { Stack, Typography } from '@mui/material';
import PalletLink from '../../pallet-ui/links/link/pallet-link';
import PalletLogo from './icons/pallet-logo.svg?react';
import theme from '../../theme';
import {
  TRYPALLET_ABOUT_US_URL,
  TRYPALLET_CONTACT_US_URL,
  TRYPALLET_PRIVACY_POLICY_URL,
} from './constants';

const PalletFrame: React.FC<{ readonly children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Stack width="100vw" height="100vh" px={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={1}
      >
        <PalletLogo color="black" />
        <Stack direction="row" gap={5}>
          <PalletLink href={TRYPALLET_PRIVACY_POLICY_URL} underline="none">
            <Typography
              color={theme.palette.concreteGrey[80]}
              sx={{ '&:hover': { color: theme.palette.concreteGrey[90] } }}
            >
              Privacy policy
            </Typography>
          </PalletLink>
          <PalletLink href={TRYPALLET_ABOUT_US_URL} underline="none">
            <Typography
              color={theme.palette.concreteGrey[80]}
              sx={{ '&:hover': { color: theme.palette.concreteGrey[90] } }}
            >
              About
            </Typography>
          </PalletLink>
          <PalletLink href={TRYPALLET_CONTACT_US_URL} underline="none">
            <Typography
              color={theme.palette.concreteGrey[80]}
              sx={{ '&:hover': { color: theme.palette.concreteGrey[90] } }}
            >
              Contact
            </Typography>
          </PalletLink>
        </Stack>
      </Stack>
      <Stack flex={1} borderRadius={2}>
        {children}
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={2}
      >
        <Typography color={theme.palette.concreteGrey[60]}>
          &copy; 2025 Pallet
        </Typography>
        <Typography color={theme.palette.concreteGrey[60]}>
          The future of freight logistics
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PalletFrame;
