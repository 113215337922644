import { AppBar, IconButton, Tooltip, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { CUSTOMER_PORTAL_SIDEBAR_OPEN_KEY } from '../../../common/local-storage/keys';
import { chooseForegroundColor } from '../../../common/utils/colors';
import LeftPanelOpen from '../../../icons/left-panel-open.svg?react';
import { getAppBarColor } from '../../../layouts/dashboard/app-bar';
import {
  type CustomerPortalPage,
  type DashboardDrawerItem,
} from '../../../layouts/dashboard/types';
import theme from '../../../theme';

const styles = {
  appBar: {
    position: 'sticky',
    flexDirection: 'row',
    alignItems: 'center',
    paddingY: 2,
    paddingX: 4,
    height: 45,
  },
};

type CustomerPortalAppBarProps = {
  readonly selectedDrawerItem?: DashboardDrawerItem<CustomerPortalPage>;
};
const CustomerPortalAppBar = ({
  selectedDrawerItem,
}: CustomerPortalAppBarProps) => {
  const { pathname } = useLocation();
  const [sidebarOpen, setSidebarOpen] = useLocalStorageState(
    CUSTOMER_PORTAL_SIDEBAR_OPEN_KEY,
    { defaultValue: true },
  );

  const selectedTab = selectedDrawerItem?.tabs.find(
    (tab) => pathname === tab.url,
  );

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        ...styles.appBar,
        backgroundColor: getAppBarColor(),
        borderBottom: `1px solid ${theme.palette.borderColor.main}`,
      }}
    >
      {!sidebarOpen && (
        <Tooltip title="Open sidebar">
          <IconButton
            size="small"
            sx={{
              marginRight: 1,
            }}
            onClick={() => {
              setSidebarOpen(true);
            }}
          >
            <LeftPanelOpen fill={chooseForegroundColor(getAppBarColor())} />
          </IconButton>
        </Tooltip>
      )}
      <Typography
        variant="h6"
        sx={{
          color: chooseForegroundColor(getAppBarColor()),
        }}
      >
        {selectedTab?.name}
      </Typography>
    </AppBar>
  );
};

export default CustomerPortalAppBar;
