import {
  Box,
  Button,
  Checkbox,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import { v4 } from 'uuid';
import { shallow } from 'zustand/shallow';
import { STOP_CHARGE_TOTAL_TEST_ID } from '../../../../../../../../../constants';
import { getStopAddCustomChargeButtonTestId } from '../../../../../../../../../utils';
import { FeatureFlag } from '../../../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../../../../../common/react-hooks/use-me';
import {
  type AccessorialType,
  CustomChargeBillingMethod,
  StopType,
  useAccessorialsForCustomChargeRowQuery,
  ShipmentType,
} from '../../../../../../../../generated/graphql';
import useBillingReviewStore from '../../../../../../../invoices/billing-review-store';
import { getAccessorialTypeFromTypename } from '../../../../../../../management/components/accessorials/common';
import { useAutoApplyAccessorials } from '../../../../../../../shipments/hooks/use-auto-apply-accessorials';
import { useOrderFormEditAccess } from '../../../../contexts/order-form-edit-access-context';
import {
  type CustomChargeValues,
  type OrderFormFieldValues,
} from '../../../../forms/types';
import { calculateTotalCharge } from '../../../../forms/utils';
import { useShouldShowStopChargesSection } from '../../../../hooks/use-expected-order-components';
import { useShouldRateOrder } from '../../../../hooks/use-should-rate-order';
import useSpecialChargeUpdate, {
  SPECIAL_LOADING_MESSAGE,
} from '../../../../hooks/use-special-charge-update';
import { getOtherStopIdx } from '../../../constants';
import CustomChargeRow from '../custom-charge/custom-charge-row';
import { CUSTOM_CHARGE_KEY } from '../custom-charge/labels';
import FuelChargeRow from '../fuel-charges/fuel-charge-row';
import { QuickAddAccessorialsRow } from '../quick-add-accessorials-row';
import StopFreightChargeRow from './stop-freight-charge-row';
import { type OrderRegularShipmentContext } from '../../../../types';

type StopChargesProps = {
  readonly idx: number;
  readonly inBillingReview: boolean;
};

const StopCharges = ({ idx, inBillingReview }: StopChargesProps) => {
  const { companyConfiguration } = useMe();

  const {
    showSection: showStopChargesSection,
    allowAddCharges: allowAddStopCharges,
  } = useShouldShowStopChargesSection({
    stopIdx: idx,
  });

  const defaultChargeToAccessorial =
    companyConfiguration?.defaultToAccessorial === true;

  const ffAccessorialQuickAdd = useFeatureFlag(
    FeatureFlag.FF_ACCESSORIAL_QUICK_ADD,
  );
  const [billingReviewDataLoading] = useBillingReviewStore(
    (state) => [state.openedOrderDataLoading],
    shallow,
  );

  const [newlyAddedCharge, setNewlyAddedCharge] = useState(false);
  const { control, setValue } = useFormContext<OrderFormFieldValues>();

  const contactUuid: string = useWatch({ control, name: 'contactUuid' });
  const isSpecial = useWatch({ control, name: `stops.${idx}.isSpecial` });
  const terminalUuid = useWatch({ control, name: `stops.${idx}.terminalUuid` });
  const tags = useWatch({ control, name: 'tags' });
  const serviceUuid = useWatch({ control, name: 'serviceUuid' });
  const customCharges = filterNotNil(
    useWatch({ control, name: `stops.${idx}.customCharges` }) ?? [],
  );

  const { data: accessorialsForCustomChargeData } =
    useAccessorialsForCustomChargeRowQuery({
      fetchPolicy: 'cache-first',
      variables: {
        billingPartyContactUuid: contactUuid,
        terminalUuid,
      },
      skip: isNil(contactUuid),
    });

  const accessorialsForCustomCharge =
    accessorialsForCustomChargeData?.accessorialsByBillingContact ?? [];
  const quickAddAccessorials = accessorialsForCustomCharge.filter(
    (acc) => acc.enableQuickAdd,
  );

  const {
    specialErrorMessage,
    specialForStopLoading,
    onSpecialChargeManuallyRemoved,
    onCheckIsSpecial,
  } = useSpecialChargeUpdate({
    dataLoading: inBillingReview ? billingReviewDataLoading : false,
    stopIndex: idx,
  });

  const stopType = useWatch({ control, name: `stops.${idx}.stopType` });

  const otherStopIdx = getOtherStopIdx(idx);
  const otherStopType = useWatch({
    control,
    name: `stops.${otherStopIdx}.stopType`,
  });
  const addressType = useWatch({
    control,
    name: `stops.${idx}.standardStopType`,
  });
  const hideFromBilling = useWatch({
    control,
    name: `stops.${idx}.hideFromBilling`,
  });
  const freightChargeUuid = useWatch({
    control,
    name: `stops.${idx}.freightCharge.uuid`,
  });
  const freightTotal = useWatch({
    control,
    name: `stops.${idx}.freightCharge.totalCharge`,
  });
  const fuelTotal = useWatch({
    control,
    name: `stops.${idx}.freightCharge.fuelCharge.totalCharge`,
  });

  const totalCharge = calculateTotalCharge({
    freightChargeTotal: freightTotal ?? 0,
    fuelChargeTotal: fuelTotal ?? 0,
    customCharges,
  });
  const isHazmat = useWatch({ control, name: 'hazmat' });
  const isInBond = useWatch({ control, name: 'inBond' });

  useEffect(() => {
    try {
      // this was throwing an error and breaking frontend app, was unable to repro so wrapping in try catch
      setValue(`stops.${idx}.totalCharge`, totalCharge);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCharge]);

  const theme = useTheme();
  const { autoApplyAccessorials, loading: autoApplyAccessorialsLoading } =
    useAutoApplyAccessorials({ contactUuid });
  const detailedStatus = useWatch({ control, name: 'detailedStatus' });
  const { shouldRateOrder } = useShouldRateOrder({
    detailedStatus,
  });

  useEffect(() => {
    if (autoApplyAccessorialsLoading || !shouldRateOrder) {
      return;
    }
    autoApplyAccessorials({ stopIdx: idx });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addressType,
    autoApplyAccessorialsLoading,
    otherStopType,
    stopType,
    serviceUuid,
    terminalUuid,
    tags,
    isHazmat,
    isInBond,
  ]);

  const specialChargeUuid = customCharges.find((customCharge) => {
    const accessorial = accessorialsForCustomCharge?.find(
      (itrAccessorial) => itrAccessorial.uuid === customCharge.accessorialUuid,
    );
    return accessorial?.__typename === 'SpecialAccessorialEntity';
  })?.uuid;

  const onAddCharge = (accessorialOption?: {
    // The value is either the UUID of the accessorial or CUSTOM_CHARGE_KEY.
    value: string;
    label: string;
  }) => {
    let accessorialUuid: string | null = null;
    let accessorialType: AccessorialType | null = null;
    const isAccessorial =
      !isNil(accessorialOption) &&
      accessorialOption.value !== CUSTOM_CHARGE_KEY;
    if (isAccessorial) {
      accessorialUuid = accessorialOption.value;
      const accessorial = accessorialsForCustomCharge.find(
        (itrAccessorial) => itrAccessorial.uuid === accessorialUuid,
      );
      if (!isNil(accessorial?.__typename)) {
        accessorialType = getAccessorialTypeFromTypename(
          accessorial.__typename,
        );
      }
    }
    const newCustomCharge: CustomChargeValues = {
      deductionTotal: 0,
      quantity: 1,
      rate: 0,
      totalCharge: 0,
      isAutoApplied: false,
      accessorialUuid,
      accessorialType,
      specialAccessorialMatrixItemUuid: null,
      zoneBasedAccessorialMatrixItemUuid: null,
      fuelSurchargePercentageRate: 0,
      billingMethod:
        isAccessorial || defaultChargeToAccessorial
          ? CustomChargeBillingMethod.Accessorial
          : CustomChargeBillingMethod.AdHoc,
      uuid: v4(),
      isLocal: true,
      name: accessorialOption?.label ?? '',
      accessorialName: accessorialOption?.label ?? null,
      zoneUuid: null,
      chargeGroupUuid: null,
      accessorialRangeUuid: null,
      postedFuelSurchargeRate: null,
      description: null,
      authoCode: null,
      settlementPercentageRate: null,
      settlementFlatRate: null,
      settlementBillingMethod: null,
      useAccessorialRate: true,
    };

    if (defaultChargeToAccessorial) {
      setNewlyAddedCharge(true);
    }

    setValue(`stops.${idx}.customCharges`, [...customCharges, newCustomCharge]);
  };

  const isRecoveryOrTransfer =
    stopType === StopType.Transfer || stopType === StopType.Recovery;

  const addChargeButtonTestId = getStopAddCustomChargeButtonTestId({
    stopIdx: idx,
  });

  const addChargeRowSx =
    ffAccessorialQuickAdd && quickAddAccessorials.length > 0
      ? {
          borderBottom: 'none',
          paddingBottom: 0,
        }
      : undefined;

  const { disabledIfFinalizedOrLater, disabledIfInvoicePosted } =
    useOrderFormEditAccess();

  // TODO(Luke): Why is this happening now when it wasn't before?
  if (isNil(stopType)) {
    return null;
  }

  if (!showStopChargesSection) {
    return null;
  }

  const context: OrderRegularShipmentContext = {
    shipmentType: ShipmentType.Regular,
    stopIdx: idx,
    inSettlement: false,
  };

  return (
    <>
      <TableRow
        sx={{ backgroundColor: theme.palette.grey[100] }}
        data-testid="order-form-charges-stop-row"
      >
        <TableCell data-testid="order-form-charges-stop-row-header">
          {stopType?.toString().toUpperCase()}
        </TableCell>
        <TableCell colSpan={4} />
      </TableRow>
      {allowAddStopCharges &&
        ffAccessorialQuickAdd &&
        !disabledIfInvoicePosted &&
        quickAddAccessorials.length > 0 && (
          <QuickAddAccessorialsRow
            quickAddAccessorials={quickAddAccessorials}
            onAddCharge={onAddCharge}
          />
        )}
      {stopType !== StopType.Recovery && (
        <StopFreightChargeRow
          key={freightChargeUuid}
          stopIdx={idx}
          inBillingReview={inBillingReview}
        />
      )}
      {!hideFromBilling && (
        <>
          {stopType !== StopType.Recovery && (
            <FuelChargeRow context={context} idx={idx} />
          )}
          {customCharges.map(({ uuid }, customChargeIdx) => (
            <CustomChargeRow
              // because of the way we access custom charges inside custom-charge-row (using the index syntax),
              // we have to key on that index to avoid staleness when the array changes size
              // eslint-disable-next-line react/no-array-index-key
              key={customChargeIdx}
              context={context}
              customChargeIdx={customChargeIdx}
              accessorials={accessorialsForCustomCharge}
              inBillingReview={inBillingReview}
              newlyAddedCharge={newlyAddedCharge}
              setNewlyAddedCharge={setNewlyAddedCharge}
              onRemoved={() => {
                if (uuid === specialChargeUuid) {
                  onSpecialChargeManuallyRemoved();
                }
              }}
            />
          ))}
          <TableRow>
            <TableCell sx={addChargeRowSx}>
              <Button
                disabled={disabledIfFinalizedOrLater || !allowAddStopCharges}
                data-testid={addChargeButtonTestId}
                onClick={() => {
                  onAddCharge();
                }}
              >
                + Add charge
              </Button>
            </TableCell>
            <TableCell sx={addChargeRowSx} />
            <TableCell sx={addChargeRowSx} />
            <TableCell sx={addChargeRowSx}>
              {inBillingReview && !isRecoveryOrTransfer && (
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox
                      checked={isSpecial}
                      disabled={disabledIfFinalizedOrLater}
                      onChange={(e) => {
                        onCheckIsSpecial(e.target.checked);
                      }}
                    />
                    Is special
                  </Box>
                  {specialForStopLoading
                    ? SPECIAL_LOADING_MESSAGE
                    : specialErrorMessage}
                </Box>
              )}
            </TableCell>
            <TableCell sx={addChargeRowSx} />
          </TableRow>
          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell />
            <TableCell />
            <TableCell>
              <Typography
                textAlign="right"
                data-testid={STOP_CHARGE_TOTAL_TEST_ID}
              >
                {hideFromBilling || isNil(totalCharge)
                  ? '-'
                  : currency(totalCharge).format()}
              </Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </>
      )}
    </>
  );
};

export default memo(StopCharges);
