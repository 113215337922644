import Decimal from 'decimal.js';
import { isNil } from 'lodash';
import { filterNotNil } from 'shared/array';
import { safeDivide, safeMultiply } from 'shared/math';
import { isNilOrEmptyString } from 'shared/string';
import {
  CustomChargeBillingMethod,
  FreightBillingMethod,
  ShipmentType,
  type FulfillmentType,
  type RateOrderCustomChargeInput,
  type RateOrderDependentSettlementBillLineItemInput,
  type RateOrderFreightChargeInput,
  type RateOrderFuelChargeInput,
  type RateOrderIndependentCustomCostInput,
  type RateOrderIndependentFreightCostInput,
  type RateOrderIndependentFuelCostInput,
  type RateOrderIndependentSettlementBillLineItemInput,
  type RateOrderLineHaulShipmentAndChargesInput,
  type RateOrderOrderChargesShipmentAndChargesInput,
  type RateOrderRegularShipmentAndChargesInput,
  StopType,
  FuelBillingMethod,
} from '../../generated/graphql';
import {
  type CustomChargeValues,
  type CustomCostValues,
  type FreightChargeValues,
  type FreightCostValues,
  type FuelChargeValues,
  type FuelCostValues,
  type LineHaulShipmentValues,
  type OrderChargesShipmentValues,
  type IndependentSettlementBillLineItemValues,
  type StopValues,
} from './components/order-form/forms/types';
import {
  getExpectedLineHaulShipmentStatus,
  getExpectedOrderChargesShipmentStatus,
  getExpectedRegularShipmentsStatus,
} from './components/order-form/utils';
import { isNotNil } from 'shared/optional';
import { Temporal } from 'temporal-polyfill';
import { dateToPlainDate } from 'shared/plain-date';

/**
 * Given the freight charge values from the order form, converts it into the shape
 * expected by the rateOrder query
 * @param freightCharge
 * @param isLocal
 */
export function convertFreightChargeToQueryInput({
  freightCharge,
  isLocal,
}: {
  freightCharge: FreightChargeValues | null | undefined;
  isLocal?: boolean | null | undefined;
}): RateOrderFreightChargeInput | null {
  if (isNil(freightCharge)) {
    return null;
  }
  const { billingMethod, tariffUuid, rate, quantity } = freightCharge;
  if (isNil(rate) || isNil(quantity)) {
    return null;
  }

  const existingFreightChargeUuid =
    isLocal === true ? null : freightCharge.uuid;

  return {
    existingFreightChargeUuid,
    billingMethod,
    tariffUuid,
    rateUsdCents: null, // TODO: Remove once field is fully deprecated on the backend.
    rateUsdDecimalCents: new Decimal(rate).mul(100),
    quantityInHundreds: safeMultiply(quantity, 100),
    settlementFlatRate: freightCharge.settlementFlatRate,
    settlementPercentageRate: freightCharge.settlementPercentageRate,
  };
}

/**
 * Given the freight cost values from the order form, converts it into the shape
 * expected by the rateOrder query
 * @param freightCost
 * @param isLocal
 */
export function convertIndependentFreightCostToQueryInput({
  freightCost,
  isLocal,
}: {
  freightCost: FreightCostValues | null | undefined;
  isLocal: boolean;
}): RateOrderIndependentFreightCostInput | null {
  if (isNil(freightCost)) {
    return null;
  }
  const { billingMethod, tariffUuid, rate, quantity } = freightCost;

  const rateToUseCents = isNil(rate) ? null : new Decimal(rate).mul(100);

  if (isNil(rate) || isNil(quantity) || isNil(billingMethod)) {
    return null;
  }

  const existingFreightCostId = isLocal ? null : freightCost.uuid;

  return {
    existingFreightCostId,
    billingMethod,
    tariffUuid,
    rateUsdCents:
      billingMethod === FreightBillingMethod.FlatRate ? null : rateToUseCents,
    flatRateUsdCents:
      billingMethod === FreightBillingMethod.FlatRate ? rateToUseCents : null,
    quantity,
  };
}

/**
 * Given the fuel charge values from the order form, converts it into the shape
 * expected by the rateOrder query
 * @param fuelCharge
 * @param isLocal
 */
export function convertFuelChargeToQueryInput({
  fuelCharge,
  isLocal,
}: {
  fuelCharge: FuelChargeValues | null | undefined;
  isLocal: boolean | null | undefined;
}): RateOrderFuelChargeInput | null {
  if (isNil(fuelCharge)) {
    return null;
  }
  const { billingMethod, flatRateDollars, surchargeRate } = fuelCharge;

  const existingFuelChargeUuid = isLocal === true ? null : fuelCharge.uuid;

  return {
    existingFuelChargeUuid,
    type: billingMethod,
    flatRateUsdCents: isNil(flatRateDollars)
      ? null
      : safeMultiply(flatRateDollars, 100),
    // Should we be coalescing here?
    surchargeRate: safeDivide(surchargeRate ?? 0, 100, 10),
    settlementFlatRate: fuelCharge.settlementFlatRate,
    settlementPercentageRate: fuelCharge.settlementPercentageRate,
  };
}

/**
 * Given the fuel cost values from the order form, converts it into the shape
 * expected by the rateOrder query
 * @param fuelCost
 * @param isLocal
 */
export function convertIndependentFuelCostToQueryInput({
  fuelCost,
  isLocal,
}: {
  fuelCost: FuelCostValues | null | undefined;
  isLocal: boolean;
}): RateOrderIndependentFuelCostInput | null {
  if (isNil(fuelCost)) {
    return null;
  }
  const { billingMethod, flatRateDollars, surchargeRate } = fuelCost;

  if (billingMethod === FuelBillingMethod.FlatRate && isNil(flatRateDollars)) {
    return null;
  }

  const existingFuelCostId = isLocal ? null : fuelCost.uuid;

  return {
    existingFuelCostId,
    billingMethod,
    flatRateUsdCents: isNil(flatRateDollars)
      ? null
      : new Decimal(flatRateDollars).mul(100),
    surchargeRate: new Decimal(surchargeRate ?? 0).div(100),
  };
}

/**
 * Given the custom charge values from the order form, converts it into the shape
 * expected by the rateOrder query
 * @param customCharge
 * @param isLocal
 */
export function convertCustomChargesToQueryInput({
  customCharge,
  isLocal,
}: {
  customCharge: CustomChargeValues;
  isLocal: boolean | null | undefined;
}): RateOrderCustomChargeInput | null {
  const {
    uuid,
    billingMethod,
    quantity,
    rate,
    accessorialUuid,
    useAccessorialRate,
    zoneBasedAccessorialMatrixItemUuid,
    specialAccessorialMatrixItemUuid,
    chargeGroupUuid: specialAccessorialChargeGroupUuid,
    fuelSurchargePercentageRate,
    settlementFlatRate,
    settlementPercentageRate,
  } = customCharge;

  if (
    isNil(quantity) ||
    (billingMethod === CustomChargeBillingMethod.Accessorial &&
      isNil(accessorialUuid))
  ) {
    return null;
  }

  const existingCustomChargeUuid = isLocal === true ? null : uuid;

  return {
    existingCustomChargeUuid,
    billingMethod,
    quantity,
    rateUsdDecimalCents: isNil(rate) ? null : safeMultiply(rate, 100, 10),
    accessorialUuid,
    useAccessorialRate,
    zoneBasedAccessorialMatrixItemUuid,
    specialAccessorialMatrixItemUuid,
    specialAccessorialChargeGroupUuid,
    fuelSurchargeRate: isNil(fuelSurchargePercentageRate)
      ? null
      : safeDivide(fuelSurchargePercentageRate, 100, 10),
    settlementFlatRate,
    settlementPercentageRate,
  };
}

/**
 * Given the custom cost values from the order form, converts it into the shape
 * expected by the rateOrder query
 * @param customCost
 * @param isLocal
 */
export function convertIndependentCustomCostToQueryInput({
  customCost,
}: {
  customCost: CustomCostValues;
}): RateOrderIndependentCustomCostInput | null {
  const {
    uuid,
    billingMethod,
    quantity,
    rate,
    accessorialUuid,
    useAccessorialRate,
    zoneBasedAccessorialMatrixItemUuid,
    specialAccessorialMatrixItemUuid,
    chargeGroupUuid: specialAccessorialChargeGroupUuid,
    fuelSurchargePercentageRate,
    isLocal,
  } = customCost;

  const isAccessorialChargeMissingUuid =
    billingMethod === CustomChargeBillingMethod.Accessorial &&
    isNil(accessorialUuid);

  if (
    isNil(billingMethod) ||
    isNil(quantity) ||
    isAccessorialChargeMissingUuid
  ) {
    return null;
  }

  const existingCustomCostId = isLocal ? null : uuid;

  return {
    existingCustomCostId,
    billingMethod,
    quantity,
    rateUsdCents: isNil(rate) ? null : new Decimal(rate).mul(100),
    accessorialUuid,
    useAccessorialRate,
    zoneBasedAccessorialMatrixItemUuid,
    specialAccessorialMatrixItemUuid,
    specialAccessorialChargeGroupUuid,
    fuelSurchargeRate: isNil(fuelSurchargePercentageRate)
      ? null
      : new Decimal(fuelSurchargePercentageRate).div(100),
  };
}

/**
 * Given a dependent settlement bill line item, converts it
 * into the shape expected by the rateOrder query
 * by the rateOrder query
 * @param dependentSettlementBillLineItems
 * @param isLocal
 */
function convertDependentSettlementBillLineItemsToQueryInput({
  dependentSettlementBillLineItems,
}: {
  dependentSettlementBillLineItems: IndependentSettlementBillLineItemValues;
}): RateOrderDependentSettlementBillLineItemInput {
  // TODO: Will be implemented as part of Robust Settlement M2:
  // https://linear.app/trypallet/project/robust-settlement-model-b8eb0fd244c8/overview#milestone-dadef2c4-491d-453a-8190-aff7b7dfbb06
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {} as RateOrderDependentSettlementBillLineItemInput;
}

/**
 * Given an indepedent settlement bill line item, converts it into
 * into the shape expected by the rateOrder query
 * by the rateOrder query
 * @param independentSettlementBillLineItem
 * @param isLocal
 */
export function convertIndependentSettlementBillLineItemsToQueryInput({
  independentSettlementBillLineItem,
}: {
  independentSettlementBillLineItem: IndependentSettlementBillLineItemValues;
}): RateOrderIndependentSettlementBillLineItemInput {
  const { isLocal } = independentSettlementBillLineItem;
  return {
    settlementMode: independentSettlementBillLineItem.settlementMode,
    driverUuid: independentSettlementBillLineItem.driverUuid,
    settlementBillUuid:
      // rename in schema
      independentSettlementBillLineItem.driverSettlementBillUuid,
    settlementBillFinalizedDate:
      independentSettlementBillLineItem.settlementBillFinalizedDate,
    freightCost: convertIndependentFreightCostToQueryInput({
      freightCost: independentSettlementBillLineItem.freightCost,
      isLocal,
    }),
    fuelCost: convertIndependentFuelCostToQueryInput({
      fuelCost: independentSettlementBillLineItem.freightCost?.fuelCost,
      isLocal,
    }),
    customCosts: filterNotNil(
      independentSettlementBillLineItem.customCosts?.map((customCost) =>
        convertIndependentCustomCostToQueryInput({
          customCost,
        }),
      ) ?? [],
    ),
  };
}

/**
 * Given the settlement bill line items from a stop on the order form, converts it into
 * corresponding lists of independent and dependent settlement bill line items expected
 * by the rateOrder query
 * @param settlementBillLineItems
 * @param isLocal
 */
export function convertSettlementBillLineItemsToQueryInput({
  settlementBillLineItems,
}: {
  settlementBillLineItems:
    | IndependentSettlementBillLineItemValues[]
    | null
    | undefined;
}): {
  independentSettlementBillLineItems: RateOrderIndependentSettlementBillLineItemInput[];
  dependentSettlementBillLineItems: RateOrderDependentSettlementBillLineItemInput[];
} {
  const independentSettlementBillLineItems: RateOrderIndependentSettlementBillLineItemInput[] =
    settlementBillLineItems?.map((independentSettlementBillLineItem) =>
      convertIndependentSettlementBillLineItemsToQueryInput({
        independentSettlementBillLineItem,
      }),
    ) ?? [];

  // TODO: Will be implemented as part of Robust Settlement M2:
  // https://linear.app/trypallet/project/robust-settlement-model-b8eb0fd244c8/overview#milestone-dadef2c4-491d-453a-8190-aff7b7dfbb0
  //   convertDependentSettlementBillLineItemsToQueryInput(lineItem),
  const dependentSettlementBillLineItems: RateOrderDependentSettlementBillLineItemInput[] =
    [];

  return {
    independentSettlementBillLineItems,
    dependentSettlementBillLineItems,
  };
}

/**
 * Given the stop values from the order form, converts it into the shape
 * expected by the rateOrder query
 * @param stop
 * @param fulfillmentType
 */
export function convertStopToQueryInput({
  stop,
  fulfillmentType,
}: {
  stop: StopValues;
  fulfillmentType?: FulfillmentType | null | undefined;
}): RateOrderRegularShipmentAndChargesInput | null {
  const { stopType } = stop;

  if (stopType === StopType.None) {
    return null;
  }

  const response = getExpectedRegularShipmentsStatus({
    stopType,
    fulfillmentType,
  });

  const shouldIncludeFreightAndFuelCharge =
    response.present && response.freightChargePresent;

  const {
    independentSettlementBillLineItems,
    dependentSettlementBillLineItems,
  } = convertSettlementBillLineItemsToQueryInput({
    settlementBillLineItems: stop.settlementBillLineItems,
  });

  return {
    charges: {
      freightCharge: shouldIncludeFreightAndFuelCharge
        ? convertFreightChargeToQueryInput({
            freightCharge: stop.freightCharge,
            isLocal: stop.isLocal,
          })
        : null,
      fuelCharge: shouldIncludeFreightAndFuelCharge
        ? convertFuelChargeToQueryInput({
            fuelCharge: stop.freightCharge?.fuelCharge,
            isLocal: stop.isLocal,
          })
        : null,
      customCharges: filterNotNil(
        stop.customCharges?.map((customCharge) =>
          convertCustomChargesToQueryInput({
            customCharge,
            isLocal: stop.isLocal,
          }),
        ) ?? [],
      ),
    },
    shipmentDetails: {
      shipmentType: ShipmentType.Regular,
      overridePackageWeight: false,
      stop: {
        stopType,
        // Need to exclude excess properties because passing all of address will fail
        // at runtime despite no typeerror due to the lack of exact types
        address: isNil(stop.address)
          ? null
          : {
              line1: stop.address.line1,
              line2: stop.address.line2,
              city: stop.address.city,
              state: stop.address.state,
              zip: stop.address.zip,
              country: stop.address.country,
              latitude: stop.address.latitude,
              longitude: stop.address.longitude,
            },
        serviceDate: stop.serviceDate,
        serviceDateIso8601: stop.serviceDateV2?.toString(),
        appointmentDate: stop.appointmentTime, // TODO: Fix this
        completedAt: stop.completedAt,
        miles: stop.miles,
        terminalUuid: stop.terminalUuid,
      },
    },
    independentSettlementBillLineItems,
    dependentSettlementBillLineItems,
  };
}

/**
 * Given the line haul shipment values from the order form, converts it into the shape
 * expected by the rateOrder query
 * @param lineHaul The line haul shipment values from the order form
 * @param lineHaulLaneUuid The line haul lane uuid from the order form
 */
export function convertLineHaulShipmentToQueryInput({
  lineHaulShipment,
  lineHaulLaneUuid,
  originTerminalUuid,
  destinationTerminalUuid,
  inboundDate,
  outboundDate,
  companyTimezone,
  ffLineHaulNetworks,
}: {
  lineHaulShipment: LineHaulShipmentValues;
  lineHaulLaneUuid?: string | null;
  originTerminalUuid?: string | null;
  destinationTerminalUuid?: string | null;
  inboundDate?: Date | null;
  outboundDate?: Date | null;
  companyTimezone: string;
  ffLineHaulNetworks: boolean;
}): RateOrderLineHaulShipmentAndChargesInput | null {
  // A line haul lane is only required if FF_LINE_HAUL_NETWORKS is disabled
  if (!ffLineHaulNetworks && isNilOrEmptyString(lineHaulLaneUuid)) {
    return null;
  }

  const { freightCharge } = lineHaulShipment;

  const response = getExpectedLineHaulShipmentStatus(true); // If we get to this point, we know that the line haul lane exists and can simply pass in true for the parameter
  const shouldIncludeFreightAndFuelCharge =
    response.present && response.freightChargePresent;

  // Note that dateForFuelCharge is poorly named. It is actually the lineHaulCompletedDate
  const serviceDate =
    lineHaulShipment.dateForFuelCharge ??
    inboundDate ??
    outboundDate ??
    new Date();
  const serviceDateV2 = isNotNil(lineHaulShipment.dateForFuelCharge)
    ? dateToPlainDate(lineHaulShipment.dateForFuelCharge, companyTimezone)
    : isNotNil(inboundDate)
      ? dateToPlainDate(inboundDate, companyTimezone)
      : isNotNil(outboundDate)
        ? dateToPlainDate(outboundDate, companyTimezone)
        : Temporal.Now.plainDateISO();

  return {
    charges: {
      freightCharge: shouldIncludeFreightAndFuelCharge
        ? convertFreightChargeToQueryInput({
            freightCharge,
            isLocal: lineHaulShipment.isLocal,
          })
        : null,
      fuelCharge: shouldIncludeFreightAndFuelCharge
        ? convertFuelChargeToQueryInput({
            fuelCharge: freightCharge?.fuelCharge,
            isLocal: lineHaulShipment.isLocal,
          })
        : null,
      customCharges: [],
    },
    shipmentDetails: {
      shipmentType: ShipmentType.LineHaul,
      laneUuid: lineHaulLaneUuid,
      completedAt: lineHaulShipment.dateForFuelCharge,
      originTerminalUuid,
      destinationTerminalUuid,
      serviceDate,
      serviceDateIso8601: serviceDateV2.toString(),
    },
    // TODO(shanktt BKO-1306): Implement
    independentSettlementBillLineItems: [],
    dependentSettlementBillLineItems: [],
  };
}

/**
 * Given the order charges shipment values from the order form, converts it into the shape
 * expected by the rateOrder query
 * @param orderCharges
 */
export function convertOrderChargesShipmentToQueryInput({
  orderCharges,
  fulfillmentType,
  inboundStopType,
  outboundStopType,
}: {
  orderCharges: OrderChargesShipmentValues;
  inboundStopType: StopType | null | undefined;
  outboundStopType: StopType | null | undefined;
  fulfillmentType: FulfillmentType | null | undefined;
}): RateOrderOrderChargesShipmentAndChargesInput | null {
  const { freightCharge, customCharges } = orderCharges;

  const response = getExpectedOrderChargesShipmentStatus({
    inboundStopType,
    outboundStopType,
    fulfillmentType,
  });
  const shouldIncludeFreightAndFuelCharge =
    response.present && response.freightChargePresent;

  return {
    charges: {
      freightCharge: shouldIncludeFreightAndFuelCharge
        ? convertFreightChargeToQueryInput({
            freightCharge,
            isLocal: orderCharges.isLocal,
          })
        : null,
      fuelCharge: shouldIncludeFreightAndFuelCharge
        ? convertFuelChargeToQueryInput({
            fuelCharge: freightCharge?.fuelCharge,
            isLocal: orderCharges.isLocal,
          })
        : null,
      customCharges: filterNotNil(
        customCharges?.map((customCharge) =>
          convertCustomChargesToQueryInput({
            customCharge,
            isLocal: orderCharges.isLocal,
          }),
        ) ?? [],
      ),
    },
    shipmentDetails: {
      shipmentType: ShipmentType.OrderCharges,
    },
    // TODO(shanktt BKO-1306): Implement
    independentSettlementBillLineItems: [],
    dependentSettlementBillLineItems: [],
  };
}
