import { isEmpty, isNil } from 'lodash';
import { filterNotNil } from 'shared/array';
import {
  type FilterCondition,
  type FilterFieldToTypeMap,
  type FilterGroup,
  type FilterTypeToOperatorMap,
  type NullableFilterCondition,
  type NullableFilterGroup,
} from './types-v2';

/**
 * Returns true if the nested filter group condition is a filter group
 * (i.e. not a single filter condition)
 */
export const isFilterGroup = <
  TFilterField extends string,
  TFilterType extends string,
  TFilterTypeToOperatorMap extends FilterTypeToOperatorMap<TFilterType>,
  TFilterFieldToTypeMap extends FilterFieldToTypeMap<TFilterField, TFilterType>,
>(
  filter: NullableFilterGroup<
    TFilterField,
    TFilterType,
    TFilterTypeToOperatorMap,
    TFilterFieldToTypeMap
  >['conditions'][number],
): filter is NullableFilterGroup<
  TFilterField,
  TFilterType,
  TFilterTypeToOperatorMap,
  TFilterFieldToTypeMap
> => {
  return (
    'operator' in filter &&
    (filter.operator === 'AND' || filter.operator === 'OR') &&
    'conditions' in filter &&
    Array.isArray(filter.conditions)
  );
};

/**
 * Returns true if the nested filter group condition is a single filter condition
 * (i.e. not a filter group)
 */
export const isFilterCondition = <
  TFilterField extends string,
  TFilterType extends string,
  TFilterTypeToOperatorMap extends FilterTypeToOperatorMap<TFilterType>,
  TFilterFieldToTypeMap extends FilterFieldToTypeMap<TFilterField, TFilterType>,
>(
  filter: NullableFilterGroup<
    TFilterField,
    TFilterType,
    TFilterTypeToOperatorMap,
    TFilterFieldToTypeMap
  >['conditions'][number],
): filter is NullableFilterCondition<
  TFilterField,
  TFilterType,
  TFilterTypeToOperatorMap,
  TFilterFieldToTypeMap
> => {
  return !isFilterGroup(filter);
};

/**
 * Returns true if all nested filter groups in the filter group are empty
 */
export const isFilterGroupEmpty = <
  TFilterField extends string,
  TFilterType extends string,
  TFilterTypeToOperatorMap extends FilterTypeToOperatorMap<TFilterType>,
  TFilterFieldToTypeMap extends FilterFieldToTypeMap<TFilterField, TFilterType>,
>(
  filters: FilterGroup<
    TFilterField,
    TFilterType,
    TFilterTypeToOperatorMap,
    TFilterFieldToTypeMap
  >,
): boolean => {
  return filters.conditions.every((condition) => {
    if (isFilterGroup(condition)) {
      // This is a nested filter group
      return isFilterGroupEmpty(condition);
    }
    // This is a leaf filter condition
    return false;
  });
};

/**
 * Returns true if at least one filter condition in the filter group is partially empty
 * (i.e. it has at least one field not empty and at least one field empty)
 */
export const isFilterGroupPartiallyEmpty = <
  TFilterField extends string,
  TFilterType extends string,
  TFilterTypeToOperatorMap extends FilterTypeToOperatorMap<TFilterType>,
  TFilterFieldToTypeMap extends FilterFieldToTypeMap<TFilterField, TFilterType>,
>(
  filterGroup: NullableFilterGroup<
    TFilterField,
    TFilterType,
    TFilterTypeToOperatorMap,
    TFilterFieldToTypeMap
  >,
): boolean => {
  return filterGroup.conditions.some((condition) => {
    if (isFilterGroup(condition)) {
      return isFilterGroupPartiallyEmpty(condition);
    }

    const { field, operator, value } = condition;
    // TODO: eventually, check if operator is unary
    const isValueRequired = !isNil(operator);
    const isValueEmpty =
      isNil(value) || (Array.isArray(value) && isEmpty(value));

    const emptyStatuses = filterNotNil([
      isNil(field),
      isNil(operator),
      isValueRequired ? isValueEmpty : null,
    ]);

    // Return true if one field is empty, but not all fields are empty
    return emptyStatuses.some(Boolean) && !emptyStatuses.every(Boolean);
  });
};

export const filterEmptyFilterConditions = <
  TFilterField extends string,
  TFilterType extends string,
  TFilterTypeToOperatorMap extends FilterTypeToOperatorMap<TFilterType>,
  TFilterFieldToTypeMap extends FilterFieldToTypeMap<TFilterField, TFilterType>,
>(
  filterGroup: NullableFilterGroup<
    TFilterField,
    TFilterType,
    TFilterTypeToOperatorMap,
    TFilterFieldToTypeMap
  >,
): FilterGroup<
  TFilterField,
  TFilterType,
  TFilterTypeToOperatorMap,
  TFilterFieldToTypeMap
> => {
  const filteredConditions = filterGroup.conditions.map((condition) => {
    if (isFilterGroup(condition)) {
      return filterEmptyFilterConditions(condition);
    }
    const { field, operator, value } = condition;
    if (isNil(field) || isNil(operator) || isNil(value)) {
      return null;
    }
    const nonNullableCondition: FilterCondition<
      TFilterField,
      TFilterType,
      TFilterTypeToOperatorMap,
      TFilterFieldToTypeMap
    > = {
      field,
      operator,
      value,
    };
    return nonNullableCondition;
  });
  return {
    operator: filterGroup.operator,
    conditions: filterNotNil(filteredConditions),
  };
};
