import { Checkbox } from '@mui/material';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useState } from 'react';
import { type DriverSettlementBillV2Fragment } from '../../../generated/graphql';
import { CHECKBOX_CELL_WIDTH, CompactRow, SettlementTableCell } from './common';

type SettlementBillRowProps = {
  readonly settlementBill: DriverSettlementBillV2Fragment;
  readonly onCheckboxChange: (settlementBillId: string) => void;
  readonly isChecked: boolean;
  readonly toggleOpenedSettlementBill: (uuid: string) => void;
  readonly isOpened: boolean;
};

export const SettlementBillRow = ({
  settlementBill,
  onCheckboxChange,
  isChecked,
  toggleOpenedSettlementBill,
  isOpened,
}: SettlementBillRowProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const { payPeriod, name, driver, uuid, paymentInformation } = settlementBill;
  const settlementDateString = settlementBill.settlementDate as
    | string
    | null
    | undefined;

  return (
    <CompactRow
      hover
      selected={isOpened}
      role="checkbox"
      sx={{ cursor: 'pointer' }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onClick={() => {
        toggleOpenedSettlementBill(uuid);
      }}
    >
      <SettlementTableCell sx={{ width: CHECKBOX_CELL_WIDTH }}>
        <Checkbox
          checked={isChecked}
          onClick={(e) => {
            onCheckboxChange(uuid);
            e.stopPropagation();
          }}
        />
      </SettlementTableCell>
      <SettlementTableCell>
        {isNil(settlementDateString)
          ? 'N/A'
          : dayjs(settlementDateString).format('MM/DD/YY')}
      </SettlementTableCell>
      <SettlementTableCell>{payPeriod}</SettlementTableCell>
      <SettlementTableCell>{name}</SettlementTableCell>
      <SettlementTableCell>{driver?.user.name}</SettlementTableCell>
      <SettlementTableCell>
        {currency(paymentInformation.eligiblePay.value).format()}
      </SettlementTableCell>
      <SettlementTableCell>
        {currency(paymentInformation.netAdjustments.value).format()}
      </SettlementTableCell>
      <SettlementTableCell>
        {currency(paymentInformation.finalPay.value).format()}
      </SettlementTableCell>
    </CompactRow>
  );
};
