import { Button, useTheme } from '@mui/material';

type SendJobButtonProps = {
  readonly onClick: () => void;
};

const SendJobButton = ({ onClick }: SendJobButtonProps) => {
  const theme = useTheme();
  return (
    <Button
      sx={{
        backgroundColor: theme.palette.airfreightBlue[60],
        '&:hover': {
          backgroundColor: theme.palette.airfreightBlue[70],
        },
        color: 'white',
      }}
      variant="contained"
      size="small"
      onClick={onClick}
    >
      Send job
    </Button>
  );
};

export default SendJobButton;
