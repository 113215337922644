import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Collapse, IconButton, TableCell, Tooltip } from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { useState } from 'react';
import { StyledTableRow } from '../../../../components/common/styled-table-row';
import { type IndependentSettlementBillLineItemValues } from '../../../../forms/types';
import { DriverSettlementEditor } from './driver-settlement-editor';

type StopSettlementTableRowProps = {
  readonly lineItem: IndependentSettlementBillLineItemValues;
  readonly lineItemIdx: number;
  readonly stopIdx: number;
  readonly disabledIfNoAccess: boolean;
  readonly driverName: string;
  readonly onDelete: () => void;
};

export const StopSettlementTableRow = ({
  lineItem,
  lineItemIdx,
  stopIdx,
  disabledIfNoAccess,
  driverName,
  onDelete,
}: StopSettlementTableRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const settlementFinalized = !isNil(lineItem.settlementBillFinalizedDate);
  const editingDisabled = settlementFinalized || disabledIfNoAccess;

  return (
    <>
      <StyledTableRow
        key={lineItem.id}
        isCompact
        sx={{
          backgroundColor: isExpanded ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
          cursor: 'pointer',
        }}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <TableCell width={20} padding="none">
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded(!isExpanded);
            }}
          >
            {isExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell sx={{ minWidth: 350 }}>{driverName}</TableCell>
        <TableCell>Independent</TableCell>
        <TableCell sx={{ minWidth: 200, textAlign: 'right' }}>
          {currency(lineItem.totalCharge).format()}
        </TableCell>
        <TableCell>
          {/* span required so the tooltip has an anchor when the button is disabled */}
          <Tooltip
            title={settlementFinalized ? 'Settlement is on finalized bill' : ''}
            placement="right"
          >
            <span>
              <IconButton disabled={editingDisabled} onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </TableCell>
      </StyledTableRow>
      <StyledTableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse unmountOnExit in={isExpanded} timeout="auto">
            <DriverSettlementEditor
              stopIdx={stopIdx}
              settlementBillLineItemIdx={lineItemIdx}
              editingDisabled={editingDisabled}
            />
          </Collapse>
        </TableCell>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />
      </StyledTableRow>
    </>
  );
};
