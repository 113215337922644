import { isEmpty, isNil, uniq } from 'lodash';
import { useMemo, useState } from 'react';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useMe from '../../../common/react-hooks/use-me';
import { useTableFields } from '../../../common/react-hooks/use-table-fields';
import {
  type AuditReportBucketFragment,
  FilterViewPage,
  OrderTableField,
} from '../../../generated/graphql';
import { OrdersTableWithFiltersAgGrid } from '../../ag-grid/orders/orders-table-with-filters-ag-grid';
import { type DefaultFilterTabsConfigs } from '../../ag-grid/orders/types';
import { useOrdersTableColumns } from '../../orders/components/order-form/hooks/use-orders-table-columns';
import { AuditOrderTabs } from '../types';

const AuditReportOrdersTable = ({
  bucket,
}: {
  readonly bucket: AuditReportBucketFragment;
}) => {
  const { userUuid } = useMe();
  const { orderTableFields } = useTableFields();
  const {
    startDate,
    endDate,
    daysPastDue,
    contactUuid,

    __typename,
    ...bucketData
  } = bucket;

  const allUuids = Object.values(bucketData).flat();

  const [shouldRefreshGrid, setShouldRefreshGrid] = useState(false);

  const orderTableHeaders = useMemo(() => {
    /**
     * It seems that the table should always be displaying a certain set of fields.
     * This retains the necessary columns while providing the functionality of custom
     * order columns that is part of the orders table.
     */
    const DEFAULT_FIELDS = [
      OrderTableField.Name,
      OrderTableField.BillOfLadingNumber,
      OrderTableField.PrimaryConsignee,
      OrderTableField.Attempted,
      OrderTableField.OnHold,
    ];
    const tableFields =
      !isNil(orderTableFields) && !isEmpty(orderTableFields)
        ? uniq([...orderTableFields, ...DEFAULT_FIELDS])
        : DEFAULT_FIELDS;
    return tableFields;
  }, [orderTableFields]);

  const columnDefinitions = useOrdersTableColumns({
    pageType: FilterViewPage.OrdersAudit,
  });

  const filterTabsConfig: DefaultFilterTabsConfigs<AuditOrderTabs> =
    useMemo(() => {
      return {
        defaultTab: AuditOrderTabs.All,
        baseTab: AuditOrderTabs.All,
        tabs: [
          {
            value: AuditOrderTabs.All,
            label: AuditOrderTabs.All,
            filtersToApply: {
              uuids: allUuids,
            },
          },
          {
            value: AuditOrderTabs.NoAction,
            label: AuditOrderTabs.NoAction,
            filtersToApply: {
              uuids: bucket.noAction,
            },
          },
          {
            value: AuditOrderTabs.OnHand,
            label: AuditOrderTabs.OnHand,
            filtersToApply: {
              uuids: bucket.onHand,
            },
          },
          {
            value: AuditOrderTabs.Assigned,
            label: AuditOrderTabs.Assigned,
            filtersToApply: {
              uuids: bucket.assigned,
            },
          },
          {
            value: AuditOrderTabs.PartiallyComplete,
            label: AuditOrderTabs.PartiallyComplete,
            filtersToApply: {
              uuids: bucket.partiallyComplete,
            },
          },
          {
            value: AuditOrderTabs.Attempted,
            label: AuditOrderTabs.Attempted,
            filtersToApply: {
              uuids: bucket.attempted,
            },
          },
          {
            value: AuditOrderTabs.OnHold,
            label: AuditOrderTabs.OnHold,
            filtersToApply: {
              uuids: bucket.onHold,
            },
          },
          {
            value: AuditOrderTabs.BillingIssues,
            label: AuditOrderTabs.BillingIssues,
            filtersToApply: {
              getOrderTableFieldValuesInput: {
                uuids: bucket.billingIssues,
                orderTableFields: orderTableHeaders,
              },
            },
          },
          {
            value: AuditOrderTabs.Unfinalized,
            label: AuditOrderTabs.Unfinalized,
            filtersToApply: {
              uuids: bucket.unfinalized,
            },
          },
          {
            value: AuditOrderTabs.UnfinalizedCancelled,
            label: AuditOrderTabs.UnfinalizedCancelled,
            filtersToApply: {
              uuids: bucket.cancelledUnfinalized,
            },
          },
          {
            value: AuditOrderTabs.FinalizedCancelled,
            label: AuditOrderTabs.FinalizedCancelled,
            filtersToApply: {
              uuids: bucket.cancelledFinalized,
            },
          },
          {
            value: AuditOrderTabs.FinalizedNoCharge,
            label: AuditOrderTabs.FinalizedNoCharge,
            filtersToApply: {
              uuids: bucket.noChargeFinalized,
            },
          },
          {
            value: AuditOrderTabs.OnInvoiceNotPosted,
            label: AuditOrderTabs.OnInvoiceNotPosted,
            filtersToApply: {
              uuids: bucket.onInvoiceUnposted,
            },
          },
          {
            value: AuditOrderTabs.OnInvoicePosted,
            label: AuditOrderTabs.OnInvoicePosted,
            filtersToApply: {
              uuids: bucket.onInvoicePosted,
            },
          },
        ],
      };
    }, [bucket, allUuids, orderTableHeaders]);

  return (
    <OrdersTableWithFiltersAgGrid<AuditOrderTabs>
      shouldShowCustomerFilter
      shouldRememberFilters
      showTotalCount
      shouldShowGenerateReportButtons
      shouldAllowSavedViews
      controlBarSpacing={false}
      columnDefinitions={columnDefinitions}
      userOrderTableFields={orderTableHeaders}
      pageSize={25}
      pageType={FilterViewPage.OrdersAudit}
      defaultFilterTabsConfigs={filterTabsConfig}
      enableNumberOfOrdersQuery={false}
      shouldRefreshGrid={shouldRefreshGrid}
      setShouldRefreshGrid={setShouldRefreshGrid}
      userUuid={userUuid}
      enableHeaderCheckboxSelection={false}
    />
  );
};

export default AuditReportOrdersTable;
