import { Decimal } from 'decimal.js';
import { isNil } from 'lodash';
import {
  FreightBillingMethod,
  FuelBillingMethod,
  ShipmentType,
  type RateOrderRegularShipmentAndChargesInput,
  type RateOrderTotalDollarsQuery,
  type StopType,
} from '../../../../generated/graphql';
import { ADDRESS_SCHEMA } from '../../../customer-portal/components/customer-portal-order-form/forms/customer-portal-order-schema';
import { CustomerPortalInformationLocationType } from '../../../customer-portal/components/customer-portal-order-form/forms/enums';
import { type CustomerPortalInboundInformation } from '../../../customer-portal/components/customer-portal-order-form/forms/types';
import { QuoteFormStatus } from '../../../customer-portal/components/customer-portal-quote-form/constants';
import { isPartnerCarrierStop } from '../../../orders/components/order-form/forms/stop-type';

/**
 * Determines the QuoteFormStatus based on the provided price and conditions.
 *
 * @param {Object} params - The parameters for determining the quote status.
 * @param {number | null | undefined} params.price - The price to evaluate.
 * @param {boolean} params.isStale - Indicates if the quote is stale.
 * @param {boolean} params.missingInfo - Indicates if there is missing information.
 * @returns {QuoteFormStatus} - The status of the quote form based on the provided conditions.
 */
export const convertPriceToQuoteStatus = ({
  price,
  isStale,
  missingInfo,
}: {
  price: number | null | undefined;
  isStale: boolean;
  missingInfo: boolean;
}): QuoteFormStatus => {
  if (missingInfo) {
    return QuoteFormStatus.MissingInfo;
  }
  if (isNil(price)) {
    return QuoteFormStatus.Creating;
  }
  if (price <= 0) {
    return QuoteFormStatus.Invalid;
  }
  if (isStale) {
    return QuoteFormStatus.Stale;
  }
  return QuoteFormStatus.Quoted;
};

export const missingStopInfo = (
  stop: CustomerPortalInboundInformation,
  stopType: StopType | null,
): boolean => {
  if (isNil(stopType)) {
    return true;
  }
  if (isPartnerCarrierStop(stopType)) {
    if (stop.locationType !== CustomerPortalInformationLocationType.Terminal) {
      // This would be a bad state, but TypeScript can't guarantee against it
      // unless we move stopType into the schema
      return true;
    }
    // Despite the non-null assertion in the schema, the terminalId can be
    // null since it's an RHF field that we initialize with `undefined`
    return isNil(stop.terminalId);
  }
  // Non-partner carrier stop
  if (stop.locationType !== CustomerPortalInformationLocationType.Address) {
    // Another bad state
    return true;
  }
  if (!ADDRESS_SCHEMA.safeParse(stop.address).success) {
    return true;
  }
  return false;
};

export const convertCustomerPortalStopToRateOrderStop = (
  stop: CustomerPortalInboundInformation,
  stopType: StopType,
  packageQuantity: number,
): RateOrderRegularShipmentAndChargesInput | null => {
  if (isPartnerCarrierStop(stopType)) {
    if (stop.locationType !== CustomerPortalInformationLocationType.Terminal) {
      return null;
    }
    // Despite the non-null assertion in the schema, the terminalId can be
    // null since it's an RHF field that we initialize with `undefined`
    if (isNil(stop.terminalId)) {
      return null;
    }
  } else {
    if (stop.locationType !== CustomerPortalInformationLocationType.Address) {
      return null;
    }
    if (isNil(stop.address)) {
      return null;
    }
  }
  return {
    charges: {
      customCharges: [],
      freightCharge: {
        billingMethod: FreightBillingMethod.Tariff,
        quantityInHundreds: packageQuantity * 100,
        rateUsdCents: 0,
        rateUsdDecimalCents: new Decimal(0),
        settlementFlatRate: null,
        settlementPercentageRate: 100,
        tariffUuid: null,
      },
      fuelCharge: {
        flatRateUsdCents: null,
        settlementFlatRate: null,
        settlementPercentageRate: 100,
        surchargeRate: 0,
        type: FuelBillingMethod.AutoCalculate,
      },
    },
    shipmentDetails: {
      shipmentType: ShipmentType.Regular,
      overridePackageWeight: false,
      stop: {
        stopType,
        completedAt: null,
        miles: null,
        serviceDate: stop.serviceDate,
        ...(stop.locationType === CustomerPortalInformationLocationType.Address
          ? {
              address: {
                line1: stop.address.line1,
                line2: stop.address.line2,
                city: stop.address.city,
                state: stop.address.state,
                zip: stop.address.zipcode,
                country: stop.address.country,
                latitude: null,
                longitude: null,
              },
            }
          : { terminalUuid: stop.terminalId }),
      },
    },
  };
};

export const getCoalescedPrice = (
  data: RateOrderTotalDollarsQuery | undefined,
) => {
  return !isNil(data?.rateOrderContents) &&
    'totalDollars' in data.rateOrderContents
    ? (data?.rateOrderContents?.totalDollars?.value ?? null)
    : null;
};
