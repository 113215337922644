import {
  ArrowLeft,
  ArrowRight,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  Button,
  Card,
  Divider,
  InputAdornment,
  Skeleton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useState } from 'react';
import ClearTextFieldButton from '../../../../common/components/clear-text-field-button';
import {
  BookingRequestFilterPills,
  type FindBookingRequestsFiltersInput,
} from './booking-request-filters';
import {
  CUSTOMER_PORTAL_BOOKING_REQUESTS_DEFAULT_FILTERS,
  CustomerPortalBookingRequestTab,
} from './constants';
import CustomerPortalBookingRequestsTableRow from './customer-portal-booking-requests-table-row';
import { useCustomerPortalBookingRequests } from './hooks/use-customer-portal-booking-requests';

const PAGE_SIZE = 10;

const LOADING_SKELETON_ROWS = Array.from({ length: PAGE_SIZE }).map((_, i) => (
  // eslint-disable-next-line react/no-array-index-key
  <TableRow key={i}>
    <TableCell colSpan={6}>
      <Skeleton variant="rectangular" animation="wave" width="100%" />
    </TableCell>
  </TableRow>
));

type Cursor = {
  value: string;
  direction: 'forward' | 'backward';
};

const getPaginationVariables = (cursor: Cursor | null) => {
  if (isNil(cursor)) {
    return { first: PAGE_SIZE };
  }
  if (cursor.direction === 'forward') {
    return { first: PAGE_SIZE, after: cursor.value };
  }
  return { last: PAGE_SIZE, before: cursor.value };
};

const CustomerPortalBookingRequestsTable = () => {
  const [cursor, setCursor] = useState<Cursor | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  // State for the controlled search text input
  const [searchText, setSearchText] = useState<string>('');
  // State actually used for search, which needs to be explicitly triggered by the user
  const [appliedSearchText, setAppliedSearchText] = useState<string>('');

  const [activeTab, setActiveTab] = useState<CustomerPortalBookingRequestTab>(
    CustomerPortalBookingRequestTab.ALL,
  );

  // eslint-disable-next-line react/hook-use-state
  const [filters, rawSetFilters] = useState<FindBookingRequestsFiltersInput>(
    () => CUSTOMER_PORTAL_BOOKING_REQUESTS_DEFAULT_FILTERS[activeTab],
  );

  const setFilters = (filters: FindBookingRequestsFiltersInput) => {
    rawSetFilters(filters);
    setCursor(null);
    setCurrentPage(1);
  };

  const handleTabChange = (
    _event: React.SyntheticEvent,
    newValue: CustomerPortalBookingRequestTab,
  ) => {
    setActiveTab(newValue);
    setFilters(CUSTOMER_PORTAL_BOOKING_REQUESTS_DEFAULT_FILTERS[newValue]);
    setCursor(null);
    setCurrentPage(1);
  };

  const { data, loading } = useCustomerPortalBookingRequests({
    ...getPaginationVariables(cursor),
    searchText: appliedSearchText,
    filters,
  });
  const { edges, pageInfo, totalCount } =
    data?.getOrderTableFieldValues.formattedOrderConnection ?? {};

  const totalPages = Math.ceil((totalCount ?? 0) / PAGE_SIZE);

  const handlePreviousPage = () => {
    if (isNil(pageInfo?.startCursor)) {
      setCursor(null);
    } else {
      setCursor({
        value: pageInfo.startCursor,
        direction: 'backward',
      });
    }
    setCurrentPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    if (isNil(pageInfo?.endCursor)) {
      setCursor(null);
    } else {
      setCursor({
        value: pageInfo.endCursor,
        direction: 'forward',
      });
    }
    setCurrentPage((prev) => prev + 1);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
    setAppliedSearchText(value);
    setCursor(null);
    setCurrentPage(1);
  };

  return (
    <Stack height="100%">
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="All" value={CustomerPortalBookingRequestTab.ALL} />
        <Tab
          label="Quoted requests"
          value={CustomerPortalBookingRequestTab.QUOTED_REQUESTS}
        />
        <Tab
          label="Rejected requests"
          value={CustomerPortalBookingRequestTab.REJECTED_REQUESTS}
        />
      </Tabs>
      <Divider />
      <Stack gap={2} p={2} minHeight="0">
        <Stack direction="row" gap={2} alignItems="center">
          <TextField
            size="small"
            placeholder="Search"
            InputProps={{
              style: { backgroundColor: 'white' },
              sx: { '& input': { textOverflow: 'ellipsis' } },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: isEmpty(searchText) ? undefined : (
                <ClearTextFieldButton
                  searchText={searchText}
                  handleClearSearchText={() => {
                    handleSearch('');
                  }}
                />
              ),
            }}
            value={searchText}
            sx={{ width: '200px' }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch(searchText);
              }
            }}
            onBlur={() => {
              handleSearch(searchText);
            }}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          <BookingRequestFilterPills
            filters={filters}
            setFilters={setFilters}
            wrap={false}
          />
        </Stack>
        <Card>
          <TableContainer sx={{ overflowY: 'auto', height: '100%' }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>HAWB</TableCell>
                  <TableCell>Carrier</TableCell>
                  <TableCell>Submitted at</TableCell>
                  <TableCell>Charges</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {loading
                  ? LOADING_SKELETON_ROWS
                  : edges?.map(({ node: order }) => (
                      <CustomerPortalBookingRequestsTableRow
                        key={order.uuid}
                        order={order}
                      />
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Button
            disabled={loading || currentPage === 1}
            variant="contained"
            startIcon={<ArrowLeft />}
            onClick={handlePreviousPage}
          >
            Previous page
          </Button>
          <Typography variant="body2">
            Page {currentPage} of {loading ? '-' : totalPages}
          </Typography>
          <Button
            disabled={loading || currentPage === totalPages}
            variant="contained"
            endIcon={<ArrowRight />}
            onClick={handleNextPage}
          >
            Next page
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CustomerPortalBookingRequestsTable;
