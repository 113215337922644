import { Add } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isNilOrEmptyString } from 'shared/string';
import { Sidebar } from '../../../common/components/sidebar';
import { FeatureFlag } from '../../../common/feature-flags';
import { CUSTOMER_PORTAL_SIDEBAR_OPEN_KEY } from '../../../common/local-storage/keys';
import { useClientUuidFromSessionCookie } from '../../../common/react-hooks/use-client-uuid-from-session-cookie';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import { useWhiteLabelCompanyQuery } from '../../../generated/graphql';
import useContactsForMeAsThirdPartyUser from '../hooks/use-contacts-for-me-as-third-party-user';
import useCustomerPortalDrawerItems from '../hooks/use-customer-portal-drawer-items';
import CustomerPortalCompanySelectModal from './customer-portal-order-form/customer-portal-company-select-modal';
import { CustomerPortalSidebarLogo } from './customer-portal-sidebar-logo';
import { CustomerPortalUserMenu } from './customer-portal-user-menu';

const CustomerPortalSidebar = React.memo(() => {
  const { drawerItems } = useCustomerPortalDrawerItems();

  const [isCompanySelectModalOpen, setIsCompanySelectModalOpen] =
    useState(false);

  const ffWhiteLabelCustomerPortal = useFeatureFlag(
    FeatureFlag.FF_WHITE_LABEL_CUSTOMER_PORTAL,
  );

  const clientUuidResult = useClientUuidFromSessionCookie();
  const isWhiteLabelUser =
    ffWhiteLabelCustomerPortal && clientUuidResult.kind === 'success';

  const { data: companyData } = useWhiteLabelCompanyQuery(
    isWhiteLabelUser
      ? {
          variables: {
            uuid: clientUuidResult.clientUuid,
          },
        }
      : { skip: true },
  );
  const navigate = useNavigate();

  const ffCustomerPortalOrderEntry = useFeatureFlag(
    FeatureFlag.FF_CUSTOMER_PORTAL_ORDER_ENTRY,
  );
  const ffCustomerPortalQuoteEntry = useFeatureFlag(
    FeatureFlag.FF_CUSTOMER_PORTAL_QUOTE_ENTRY,
  );

  const {
    distinctCompanies: companiesWithOrderEntryEnabled,
    contacts: contactsWithOrderEntryEnabled,
  } = useContactsForMeAsThirdPartyUser({
    filterByCustomerPortalOrderEntryEnabled: true,
  });

  const handleAddOrder = () => {
    if (isWhiteLabelUser) {
      const contactUuid = contactsWithOrderEntryEnabled.find(
        (c) => c.company.uuid === clientUuidResult.clientUuid,
      )?.uuid;
      if (isNilOrEmptyString(contactUuid)) {
        return;
      }
      navigate('/customer-portal/orders/add', {
        state: { contactUuid },
      });
    } else if (companiesWithOrderEntryEnabled.length === 1) {
      const firstContactUuid = contactsWithOrderEntryEnabled[0]?.uuid;
      if (isNilOrEmptyString(firstContactUuid)) {
        return;
      }
      navigate('/customer-portal/orders/add', {
        state: { contactUuid: firstContactUuid },
      });
    } else {
      setIsCompanySelectModalOpen(true);
    }
  };

  const drawerActions = ffCustomerPortalOrderEntry &&
    !isEmpty(companiesWithOrderEntryEnabled) && (
      <>
        {isCompanySelectModalOpen && (
          <CustomerPortalCompanySelectModal
            onClose={() => {
              setIsCompanySelectModalOpen(false);
            }}
          />
        )}
        <Stack gap={1}>
          <Button
            startIcon={<Add />}
            sx={{
              color: '#fff',
              whiteSpace: 'nowrap',
              backgroundColor: (theme) => theme.palette.airfreightBlue[70],
              '&:hover': {
                backgroundColor: (theme) => theme.palette.airfreightBlue[60],
              },
            }}
            onClick={handleAddOrder}
          >
            Add order
          </Button>
          {ffCustomerPortalQuoteEntry && (
            <Button
              variant="outlined"
              sx={{
                color: '#fff',
                whiteSpace: 'nowrap',
                borderColor: (theme) => theme.palette.airfreightBlue[70],
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.airfreightBlue[70],
                  borderColor: (theme) => theme.palette.airfreightBlue[60],
                },
              }}
              onClick={handleAddOrder}
            >
              Get quote
            </Button>
          )}
        </Stack>
      </>
    );

  return (
    <Sidebar
      logo={
        <CustomerPortalSidebarLogo
          companyName={companyData?.companyByUuid?.name}
          companyLandscapeLogoUrl={
            companyData?.companyByUuid?.companyLandscapeLogoUrl
          }
        />
      }
      drawerItems={drawerItems}
      actionItems={drawerActions}
      sidebarOpenLocalStorageKey={CUSTOMER_PORTAL_SIDEBAR_OPEN_KEY}
      userMenu={
        <CustomerPortalUserMenu
          companySquareLogoUrl={
            companyData?.companyByUuid?.companySquareLogoUrl ?? null
          }
        />
      }
    />
  );
});

export default CustomerPortalSidebar;
