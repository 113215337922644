import isEqual from 'fast-deep-equal';
import { isNil } from 'lodash';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { percent } from 'shared/units/scalar';
import { useDebouncedCallback } from 'use-debounce';
import useFormUtils from '../../../common/react-hooks/use-form-utils';
import useMe from '../../../common/react-hooks/use-me';
import {
  convertKilogramsToPounds,
  convertToInches,
} from '../../../common/utils/utils';
import {
  FuelBillingMethod,
  OrderDetailedStatus,
  PackageType,
  type RateOrderInput,
  type RateOrderQuery,
  useRateOrderLazyQuery,
} from '../../../generated/graphql';
import OrderFormChargesContext from '../components/order-form/components/charges/components/order-form-charges-context';
import {
  INBOUND_STOP_IDX,
  OUTBOUND_STOP_IDX,
} from '../components/order-form/components/constants';
import { type OrderFormFieldValues } from '../components/order-form/forms/types';
import {
  convertLineHaulShipmentToQueryInput,
  convertOrderChargesShipmentToQueryInput,
  convertStopToQueryInput,
} from '../rate-order-utils';
import { Temporal } from 'temporal-polyfill';
import { exhaustive } from 'shared/switch';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import { FeatureFlag } from '../../../common/feature-flags';

export const DEBOUNCE_WAIT_TIME_MS = 500;

type DistributivePick<T, K extends keyof any> = T extends any
  ? K extends keyof T
    ? T[K]
    : never
  : never;

export type ShipmentChargesResult = NonNullable<
  DistributivePick<
    RateOrderQuery['rateOrderContents'],
    'inboundShipmentCharges'
  >
>;

type ShipmentChargesPrefixPath =
  | `stops.${number}`
  | 'lineHaulShipment'
  | 'orderChargesShipment';

type ShipmentCostsPrefixPath =
  `stops.${number}.settlementBillLineItems.${number}`;

const isStopsPath = (
  path: ShipmentChargesPrefixPath,
): path is `stops.${number}` => {
  return path.startsWith('stops');
};

export const useRateOrderContents = () => {
  const { control, getValues } = useFormContext<OrderFormFieldValues>();
  const { setValueIfMatching } = useFormUtils<OrderFormFieldValues>();

  const { setIsRatingAccessorials, setIsSwitchingAccessorial } = useContext(
    OrderFormChargesContext,
  );

  const ffLineHaulNetworks = useFeatureFlag(FeatureFlag.FF_LINE_HAUL_NETWORKS);

  const { companyData } = useMe();

  const companyUuid = companyData?.uuid;
  const companyName = companyData?.name;

  const orderUuid = useWatch({
    control,
    name: 'uuid',
  });
  const contactUuid = useWatch({
    control,
    name: 'contactUuid',
  });
  const serviceUuid = useWatch({
    control,
    name: 'serviceUuid',
  });
  const vehicleTypeUuid = useWatch({
    control,
    name: 'vehicleTypeUuid',
  });
  const packages = useWatch({
    control,
    name: 'packages',
  });
  const totalSkids = useWatch({
    control,
    name: 'totalSkids',
  });
  const dimFactor = useWatch({
    control,
    name: 'dimFactor',
  });
  // TODO(Luke): Just derive on BE don't trust FE
  const detailedStatus = useWatch({
    control,
    name: 'detailedStatus',
  });

  const lineHaulShipment = useWatch({
    control,
    name: 'lineHaulShipment',
  });

  const lineHaulLaneUuid = useWatch({
    control,
    name: 'lineHaulLaneUuid',
  });

  const orderChargesShipment = useWatch({
    control,
    name: 'orderChargesShipment',
  });

  const stops = useWatch({
    control,
    name: 'stops',
  });

  const fulfillmentType = useWatch({
    control,
    name: 'fulfillmentType',
  });

  const useCentimeters = useWatch({
    control,
    name: 'useCentimeters',
  });

  const useKilograms = useWatch({
    control,
    name: 'useKilograms',
  });

  const [rateOrderContents] = useRateOrderLazyQuery();

  const prevRateOrderInputRef = useRef<RateOrderInput | null>(null);

  const rateOrderInput: RateOrderInput | null = useMemo(() => {
    if (isNil(companyUuid) || isNil(companyName) || isNil(dimFactor)) {
      return null;
    }
    const inboundStop = stops?.[INBOUND_STOP_IDX];
    const outboundStop = stops?.[OUTBOUND_STOP_IDX];
    const newRateOrderInput: RateOrderInput = {
      companyUuid,
      contactUuid,
      serviceUuid,
      vehicleTypeUuid,
      inboundShipment: isNil(inboundStop)
        ? null
        : convertStopToQueryInput({
            stop: inboundStop,
          }),
      outboundShipment: isNil(outboundStop)
        ? null
        : convertStopToQueryInput({
            stop: outboundStop,
          }),
      lineHaul: isNil(lineHaulShipment)
        ? null
        : convertLineHaulShipmentToQueryInput({
            lineHaulShipment,
            lineHaulLaneUuid,
            originTerminalUuid: inboundStop?.terminalUuid,
            destinationTerminalUuid: outboundStop?.terminalUuid,
            inboundDate: inboundStop?.serviceDate,
            outboundDate: outboundStop?.serviceDate,
            // Defaults to the browser's timezone if the company timezone is not set.
            companyTimezone: companyData?.timeZone ?? Temporal.Now.timeZoneId(),
            ffLineHaulNetworks,
          }),
      orderCharges: isNil(orderChargesShipment)
        ? null
        : convertOrderChargesShipmentToQueryInput({
            orderCharges: orderChargesShipment,
            inboundStopType: inboundStop?.stopType,
            outboundStopType: outboundStop?.stopType,
            fulfillmentType,
          }),
      packages:
        packages?.map((pkg) => ({
          weight:
            useKilograms === true
              ? convertKilogramsToPounds(pkg.weight)
              : pkg.weight,
          length:
            useCentimeters === true ? convertToInches(pkg.length) : pkg.length,
          width:
            useCentimeters === true ? convertToInches(pkg.width) : pkg.width,
          height:
            useCentimeters === true ? convertToInches(pkg.height) : pkg.height,
          quantity: pkg.quantity,
        })) ?? [],
      totalSkids,
      dimFactor,
      /**
       * This is applied here as a temporary measure to ensure that quotes are rated.
       * TODO (@vidhur2k): Replace this with a more robust solution to ensure that quotes are rated.
       */
      detailedStatus: detailedStatus ?? OrderDetailedStatus.Creating,
    };

    if (isEqual(prevRateOrderInputRef.current, newRateOrderInput)) {
      return prevRateOrderInputRef.current;
    }

    prevRateOrderInputRef.current = newRateOrderInput;
    return newRateOrderInput;
  }, [
    companyUuid,
    companyName,
    dimFactor,
    totalSkids,
    stops,
    contactUuid,
    serviceUuid,
    vehicleTypeUuid,
    lineHaulShipment,
    lineHaulLaneUuid,
    companyData?.timeZone,
    orderChargesShipment,
    fulfillmentType,
    packages,
    detailedStatus,
    useKilograms,
    useCentimeters,
    ffLineHaulNetworks,
  ]);

  const compareCondition = {
    comparePath: 'uuid',
    value: orderUuid,
  } as const;

  const updateFreightChargeAfterRating = (
    prefixPath: ShipmentChargesPrefixPath,
    freightCharge: ShipmentChargesResult['freightCharge'],
  ) => {
    if (isNil(freightCharge)) {
      return;
    }

    const {
      tariffUuid,
      quantity,
      discountRate,
      rateDollars,
      totalDollars,
      miles,
      settlementFlatRate,
      settlementPercentageRate,
    } = freightCharge;

    setValueIfMatching(
      `${prefixPath}.freightCharge.tariffUuid`,
      tariffUuid ?? null,
      compareCondition,
    );
    setValueIfMatching(
      `${prefixPath}.freightCharge.quantity`,
      quantity,
      compareCondition,
    );
    setValueIfMatching(
      `${prefixPath}.freightCharge.discountRate`,
      discountRate,
      compareCondition,
    );
    setValueIfMatching(
      `${prefixPath}.freightCharge.rate`,
      rateDollars.value,
      compareCondition,
    );
    setValueIfMatching(
      `${prefixPath}.freightCharge.totalCharge`,
      totalDollars.value,
      compareCondition,
    );
    // LH shipments do not have miles. We have to ensure that the value is not set for LH shipments to avoid an error.
    if (!isNil(miles) && prefixPath !== 'lineHaulShipment') {
      setValueIfMatching(`${prefixPath}.miles`, miles, compareCondition);
    }
    if (!isNil(settlementFlatRate)) {
      setValueIfMatching(
        `${prefixPath}.freightCharge.settlementFlatRate`,
        settlementFlatRate.value,
        compareCondition,
      );
    }
    if (!isNil(settlementPercentageRate)) {
      setValueIfMatching(
        `${prefixPath}.freightCharge.settlementPercentageRate`,
        settlementPercentageRate.amount.toNumber(),
        compareCondition,
      );
    }
  };

  const updateFuelChargeAfterRating = (
    prefixPath: ShipmentChargesPrefixPath,
    fuelCharge: ShipmentChargesResult['fuelCharge'],
  ) => {
    if (isNil(fuelCharge)) {
      return;
    }

    const {
      totalDollars,
      surchargeRatePercentage,
      settlementFlatRate,
      settlementPercentageRate,
    } = fuelCharge;

    const fuelBillingMethod = getValues(
      `${prefixPath}.freightCharge.fuelCharge.billingMethod`,
    );

    if (!isNil(totalDollars)) {
      setValueIfMatching(
        `${prefixPath}.freightCharge.fuelCharge.totalCharge`,
        totalDollars?.value,
        compareCondition,
      );

      switch (fuelBillingMethod) {
        // The rating API doesn't return a flat rate for fuel charges, so we
        // use the total charge as the flat rate.
        case FuelBillingMethod.FlatRate: {
          setValueIfMatching(
            `${prefixPath}.freightCharge.fuelCharge.flatRateDollars`,
            totalDollars?.value ?? null,
            compareCondition,
          );
          break;
        }
        case FuelBillingMethod.AutoCalculate:
        case FuelBillingMethod.Percentage: {
          setValueIfMatching(
            `${prefixPath}.freightCharge.fuelCharge.surchargeRate`,
            surchargeRatePercentage?.amount.toNumber() ?? null,
            compareCondition,
          );
          break;
        }
        case FuelBillingMethod.None: {
          break;
        }
        default: {
          exhaustive(fuelBillingMethod);
        }
      }
    }
    setValueIfMatching(
      `${prefixPath}.freightCharge.fuelCharge.settlementFlatRate`,
      settlementFlatRate?.value ?? null,
      compareCondition,
    );
    setValueIfMatching(
      `${prefixPath}.freightCharge.fuelCharge.settlementPercentageRate`,
      settlementPercentageRate?.amount.toNumber() ?? null,
      compareCondition,
    );
  };

  const updateFreightCostAfterRating = (
    prefixPath: ShipmentCostsPrefixPath,
    freightCost:
      | ShipmentChargesResult['independentSettlementBillLineItems'][number]['freightCost']
      | null
      | undefined,
  ) => {
    if (isNil(freightCost)) {
      return;
    }

    const {
      discountRate,
      miles,
      quantity,
      rateDollars,
      tariffUuid,
      totalDollars,
    } = freightCost;

    setValueIfMatching(
      `${prefixPath}.freightCost.tariffUuid`,
      tariffUuid ?? null,
      compareCondition,
    );
    setValueIfMatching(
      `${prefixPath}.freightCost.quantity`,
      quantity,
      compareCondition,
    );
    setValueIfMatching(
      `${prefixPath}.freightCost.discountRate`,
      discountRate,
      compareCondition,
    );
    setValueIfMatching(
      `${prefixPath}.freightCost.rate`,
      rateDollars?.value,
      compareCondition,
    );
    setValueIfMatching(
      `${prefixPath}.freightCost.totalCharge`,
      totalDollars.value,
      compareCondition,
    );
  };

  const updateFuelCostAfterRating = (
    prefixPath: ShipmentCostsPrefixPath,
    fuelCost:
      | ShipmentChargesResult['independentSettlementBillLineItems'][number]['fuelCost']
      | null
      | undefined,
  ) => {
    if (isNil(fuelCost)) {
      return;
    }
    const { surchargeRatePercentage, totalDollars } = fuelCost;

    const fuelBillingMethod = getValues(
      `${prefixPath}.freightCost.fuelCost.billingMethod`,
    );

    if (!isNil(totalDollars)) {
      setValueIfMatching(
        `${prefixPath}.freightCost.fuelCost.totalCharge`,
        totalDollars?.value,
        compareCondition,
      );

      switch (fuelBillingMethod) {
        // The rating API doesn't return a flat rate for fuel charges, so we
        // use the total charge as the flat rate.
        case FuelBillingMethod.FlatRate: {
          setValueIfMatching(
            `${prefixPath}.freightCost.fuelCost.flatRateDollars`,
            totalDollars?.value ?? null,
            compareCondition,
          );
          break;
        }
        case FuelBillingMethod.AutoCalculate:
        case FuelBillingMethod.Percentage: {
          setValueIfMatching(
            `${prefixPath}.freightCost.fuelCost.surchargeRate`,
            surchargeRatePercentage?.amount.toNumber() ?? null,
            compareCondition,
          );
          break;
        }
        case FuelBillingMethod.None: {
          break;
        }
        default: {
          exhaustive(fuelBillingMethod);
        }
      }
    }
  };

  const updateCustomChargeAfterRating = (
    prefixPath:
      | `stops.${number}.customCharges.${number}`
      | `orderChargesShipment.customCharges.${number}`,
    customCharge: ShipmentChargesResult['customCharges'][number],
  ) => {
    if (isNil(customCharge)) {
      return;
    }
    const {
      rateDollars,
      totalDollars,
      fuelSurchargePercentageRate,
      settlementPercentageRate,
      settlementFlatRate,
    } = customCharge;

    // TODO(Luke): Should these be nullable? What do we want to do if they're null?
    if (!isNil(totalDollars)) {
      setValueIfMatching(
        `${prefixPath}.totalCharge`,
        totalDollars?.value,
        compareCondition,
      );
    }
    if (!isNil(rateDollars)) {
      setValueIfMatching(
        `${prefixPath}.rate`,
        rateDollars?.value,
        compareCondition,
      );
    }
    if (!isNil(fuelSurchargePercentageRate)) {
      setValueIfMatching(
        `${prefixPath}.fuelSurchargePercentageRate`,
        fuelSurchargePercentageRate,
        compareCondition,
      );
    }
    setValueIfMatching(
      `${prefixPath}.settlementPercentageRate`,
      settlementPercentageRate?.in(percent).amount.toNumber() ?? null,
      compareCondition,
    );
    setValueIfMatching(
      `${prefixPath}.settlementFlatRate`,
      settlementFlatRate?.value ?? null,
      compareCondition,
    );
  };

  const updateCustomCostAfterRating = (
    prefixPath: `${ShipmentCostsPrefixPath}.customCosts.${number}`,
    customCost: ShipmentChargesResult['independentSettlementBillLineItems'][number]['customCosts'][number],
  ) => {
    if (isNil(customCost)) {
      return;
    }
    const { fuelSurchargePercentageRate, rateDollars, totalDollars } =
      customCost;

    // TODO(Luke): Should these be nullable? What do we want to do if they're null?
    if (!isNil(totalDollars)) {
      setValueIfMatching(
        `${prefixPath}.totalCharge`,
        totalDollars?.value,
        compareCondition,
      );
    }
    if (!isNil(rateDollars)) {
      setValueIfMatching(
        `${prefixPath}.rate`,
        rateDollars?.value,
        compareCondition,
      );
    }
    if (!isNil(fuelSurchargePercentageRate)) {
      setValueIfMatching(
        `${prefixPath}.fuelSurchargePercentageRate`,
        fuelSurchargePercentageRate,
        compareCondition,
      );
      setValueIfMatching(
        `${prefixPath}.postedFuelSurchargeRate`,
        fuelSurchargePercentageRate,
        compareCondition,
      );
    }
  };

  const updateSettlementBillCostsAfterRating = (
    prefixPath: ShipmentCostsPrefixPath,
    lineItemResult: ShipmentChargesResult['independentSettlementBillLineItems'][number],
  ) => {
    const existingLineItem = getValues(prefixPath);
    if (isNil(existingLineItem)) {
      // eslint-disable-next-line no-console
      console.warn(
        `Attempted to update settlement bill line item at ${prefixPath} but it does not exist. lineItemResult: ${JSON.stringify(lineItemResult)}`,
      );
      return;
    }
    updateFreightCostAfterRating(prefixPath, lineItemResult.freightCost);
    updateFuelCostAfterRating(prefixPath, lineItemResult.fuelCost);
    // This iteration logic assumes that the custom costs are returned in the same order as they were sent. This is what we do in other places as well.
    for (const [idx, customCost] of lineItemResult.customCosts.entries()) {
      updateCustomCostAfterRating(
        `${prefixPath}.customCosts.${idx}`,
        customCost,
      );
    }

    const { total } = lineItemResult;

    if (!isNil(total)) {
      setValueIfMatching(
        `${prefixPath}.totalCharge`,
        total.value,
        compareCondition,
      );
    }
  };

  const updateShipmentAfterRating = (
    prefixPath: ShipmentChargesPrefixPath,
    result: ShipmentChargesResult,
  ) => {
    const {
      freightCharge,
      fuelCharge,
      customCharges,
      independentSettlementBillLineItems,
    } = result;
    updateFreightChargeAfterRating(prefixPath, freightCharge);
    updateFuelChargeAfterRating(prefixPath, fuelCharge);

    // We do not support settlement bill line items on order charges and line haul shipments yet
    // This iteration logic assumes that the line items are returned in the same order as they were sent. This is what we do in other places as well.
    if (isStopsPath(prefixPath)) {
      for (const [
        idx,
        lineItem,
      ] of independentSettlementBillLineItems.entries()) {
        updateSettlementBillCostsAfterRating(
          `${prefixPath}.settlementBillLineItems.${idx}`,
          lineItem,
        );
      }
    }
    setIsRatingAccessorials(true);

    // LH shipments do not have custom charges. Updating this will throw an error since the shape does not include custom charges.
    if (prefixPath !== 'lineHaulShipment') {
      for (const [idx, customCharge] of customCharges.entries()) {
        updateCustomChargeAfterRating(
          `${prefixPath}.customCharges.${idx}`,
          customCharge,
        );
      }
    }

    setIsRatingAccessorials(false);
    setIsSwitchingAccessorial(false);
  };

  const updateOrderAfterRating = (
    result: RateOrderQuery['rateOrderContents'],
  ) => {
    if (isNil(result)) {
      return;
    }

    // The second clause here is logically equivalent to the first; this is just here
    // to satisfy TypeScript because the generated graphql union type has success: boolean
    // not success: true (literal types not supported) so need to discriminate on the presence
    // of some other key too
    if (!result.success || !('inboundShipmentCharges' in result)) {
      // TODO(Luke): Handle error
      return;
    }
    const {
      inboundShipmentCharges,
      outboundShipmentCharges,
      lineHaulCharges,
      orderCharges,
    } = result;
    if (!isNil(inboundShipmentCharges)) {
      updateShipmentAfterRating(
        `stops.${INBOUND_STOP_IDX}`,
        inboundShipmentCharges,
      );
    }
    if (!isNil(outboundShipmentCharges)) {
      updateShipmentAfterRating(
        `stops.${OUTBOUND_STOP_IDX}`,
        outboundShipmentCharges,
      );
    }

    if (!isNil(lineHaulCharges)) {
      updateShipmentAfterRating('lineHaulShipment', lineHaulCharges);
    }

    if (!isNil(orderCharges)) {
      updateShipmentAfterRating('orderChargesShipment', orderCharges);
    }
  };

  const abortController = useRef<AbortController>(new AbortController());
  const requestId = useRef<number>(0);

  const rateOrderDebounced = useDebouncedCallback(async () => {
    if (isNil(rateOrderInput)) {
      return;
    }

    // No harm in also calling this in here in case we forget
    // to call it where we call this debounced function
    abortController.current.abort();
    abortController.current = new AbortController();

    const currentRequestId = requestId.current;
    requestId.current += 1;

    const res = await rateOrderContents({
      variables: {
        rateOrderInput,
      },
      context: {
        fetchOptions: {
          signal: abortController.current.signal,
        },
      },
    });

    // If this isn't the latest request, ignore the response
    if (currentRequestId !== requestId.current - 1) {
      return;
    }

    if (isNil(res.data)) {
      // TODO(Luke): Handle error
      return;
    }
    updateOrderAfterRating(res.data.rateOrderContents);
  }, DEBOUNCE_WAIT_TIME_MS);

  useEffect(() => {
    abortController.current.abort();
    // This just cancels the upcoming debounced call,
    // not any requests that are already in flight
    rateOrderDebounced.cancel();
    void rateOrderDebounced();
  }, [rateOrderInput, rateOrderDebounced]);
};
