import { type LineHaulManifest } from '../utils';
import { Divider, Stack } from '@mui/material';
import { type Dayjs } from 'dayjs';
import ManifestDetailsHeader from './manifest-details-header';
import ManifestDetailsBody from './manifest-details-body';
import ManifestOrdersTable from './manifest-orders-table';

type ManifestDetailsProps = {
  readonly manifest: LineHaulManifest;
  readonly departDate: Dayjs;
  readonly isRefetching: boolean;
};

const ManifestDetails = ({
  manifest,
  departDate,
  isRefetching,
}: ManifestDetailsProps) => {
  return (
    <Stack height="100%">
      <ManifestDetailsHeader
        manifest={manifest}
        departDate={departDate}
        isRefetching={isRefetching}
      />
      <Divider />
      <ManifestDetailsBody manifest={manifest} />
      <ManifestOrdersTable manifest={manifest} />
    </Stack>
  );
};

export default ManifestDetails;
