import {
  Button,
  Menu,
  MenuItem,
  type PopoverOrigin,
  type SxProps,
  type Theme,
} from '@mui/material';
import { isNil } from 'lodash';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { Fragment, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { stopPropagation } from '../../../common/utils/events';
import {
  LineHaulManifestGroupsDocument,
  LineHaulManifestsGroupBy,
  useAddOrdersToManifestV2Mutation,
  useLineHaulManifestGroupsQuery,
} from '../../../generated/graphql';
import useLineHaulDispatchStore from '../store/line-haul-dispatch-store';
import { useGroupedManifests } from './manifests-section/hooks/use-grouped-manifests';
import { exhaustive } from 'shared/switch';

const popoverAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const popoverTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

type AddToLineHaulManifestButtonProps = {
  readonly sx?: SxProps<Theme>;
  readonly selectedOrderUuids: string[];
};

export const AddToLineHaulManifestButton = (
  props: AddToLineHaulManifestButtonProps,
) => {
  const [
    selectedManifestUuids,
    deselectAllManifestUuids,
    openedManifest,
    setShouldRefreshGrid,
    setSnackbarSuccessMessage,
    setSnackbarErrorMessage,
  ] = useLineHaulDispatchStore(
    (state) => [
      state.selectedManifestUuids,
      state.deselectAllManifestUuids,
      state.openedManifest,
      state.setShouldRefreshGrid,
      state.setSnackbarSuccessMessage,
      state.setSnackbarErrorMessage,
    ],
    shallow,
  );
  const filters = useLineHaulDispatchStore((state) => state.filters);
  const appliedSearchText = useLineHaulDispatchStore(
    (state) => state.appliedSearchText,
  );
  const { data, loading: lineHaulManifestsLoading } =
    useLineHaulManifestGroupsQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        input: {
          groupBy: LineHaulManifestsGroupBy.Lane,
          filters,
          searchText: appliedSearchText,
        },
      },
      onError: (error) => {
        setSnackbarErrorMessage(error.message);
      },
    });

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(
    null,
  );
  const [addOrdersToManifest] = useAddOrdersToManifestV2Mutation({
    onError: (error) => {
      setSnackbarErrorMessage(error.message);
    },
    onCompleted: (data) => {
      switch (data.addOrdersToManifestV2.__typename) {
        case 'AddOrdersToManifestV2SuccessOutput': {
          const manifest = data.addOrdersToManifestV2.lineHaulManifest;
          if (!isNil(manifest)) {
            setSnackbarSuccessMessage(
              `Orders added to ${manifest.referenceNumber}`,
            );
          }
          deselectAllManifestUuids();
          setShouldRefreshGrid(true);

          break;
        }
        case 'AddOrdersToManifestV2PreviousManifestErrorOutput': {
          setSnackbarErrorMessage(
            `Orders cannot be added to this manifest because they are on a previous manifest with a different end terminal: ${data.addOrdersToManifestV2.orderUuids.join(', ')}`,
          );
          break;
        }
        case 'MutationErrorOutput': {
          setSnackbarErrorMessage(data.addOrdersToManifestV2.message);
          break;
        }
        default: {
          exhaustive(data.addOrdersToManifestV2);
        }
      }
    },
    refetchQueries: [LineHaulManifestGroupsDocument],
  });
  const manifests = data?.lineHaulManifestsGroups.groups.flatMap(
    (g) => g.manifests,
  );
  const groupedManifests = useGroupedManifests(manifests ?? []);

  const singleManifestToAddTo = isNil(openedManifest)
    ? selectedManifestUuids.length === 1
      ? manifests?.find((m) => m.uuid === selectedManifestUuids[0])
      : null
    : openedManifest;

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isNil(singleManifestToAddTo)) {
      setAnchorElement(event.currentTarget);
    } else {
      handleAddOrdersToManifest(singleManifestToAddTo.uuid);
    }
  };
  const handlePopoverClose = () => {
    setAnchorElement(null);
  };
  const handleAddOrdersToManifest = (manifestUuid: string) => {
    void addOrdersToManifest({
      variables: {
        addOrdersToManifestV2Input: {
          manifestUuid,
          orderUuids: props.selectedOrderUuids,
        },
      },
    });
    handlePopoverClose();
  };

  return (
    <>
      <Button
        sx={props.sx}
        variant="contained"
        endIcon={
          // When there are selected orders and either a manifest is opened or there is exactly one manifest selected,
          // display a button that automatically adds the orders to the manifest without showing a dropdown menu of manifests.
          props.selectedOrderUuids.length === 0 ||
          isNil(singleManifestToAddTo) ? (
            <ChevronDownIcon />
          ) : undefined
        }
        disabled={
          props.selectedOrderUuids.length === 0 ||
          lineHaulManifestsLoading ||
          isNil(manifests) ||
          manifests.length === 0
        }
        id="add-to-line-haul-manifest-button"
        onClick={handleButtonClick}
      >
        {props.selectedOrderUuids.length === 0 || isNil(singleManifestToAddTo)
          ? 'Add to manifest'
          : `Add to ${singleManifestToAddTo.referenceNumber}`}
      </Button>
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        anchorOrigin={popoverAnchorOrigin}
        transformOrigin={popoverTransformOrigin}
        MenuListProps={{
          'aria-labelledby': 'add-to-line-haul-manifest-button',
        }}
        onClose={handlePopoverClose}
      >
        {groupedManifests.map(([pairKey, manifests]) => (
          <Fragment key={pairKey}>
            <MenuItem
              key={pairKey}
              disabled
              sx={{
                fontSize: '14px',
                fontWeight: 500,
              }}
              onClick={stopPropagation}
            >
              {pairKey}
            </MenuItem>
            {manifests.map((manifest) => (
              <MenuItem
                key={manifest.uuid}
                onClick={() => {
                  handleAddOrdersToManifest(manifest.uuid);
                }}
              >
                {manifest.referenceNumber}
              </MenuItem>
            ))}
          </Fragment>
        ))}
      </Menu>
    </>
  );
};
