import {
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { isNil } from 'lodash';
import { OrderDialog } from '../../orders/components/order-dialog';
import { SettlementBillLineItemsTableMode } from '../enums';
import { CHECKBOX_CELL_WIDTH } from './common';
import { SettlementBillLineItemsRow } from './settlement-bill-line-items-row';
import { type SettlementBillLineItem } from '../types';
import { useState } from 'react';

type SettlementBillLineItemsTableProps = {
  readonly mode: SettlementBillLineItemsTableMode;
  readonly settlementBillLineItems: SettlementBillLineItem[];
  readonly refresh: () => Promise<void>;

  // These are only used if the mode is LINE_ITEMS_TABLE
  readonly onSelectAllCheckboxChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  readonly onCheckboxChange?: (settlementBillLineItemId: string) => void;
  readonly selectedSettlementBillLineItemIds?: Set<string>;
};

export const SettlementBillLineItemsTable = ({
  mode,
  settlementBillLineItems,
  refresh,
  onSelectAllCheckboxChange,
  onCheckboxChange,
  selectedSettlementBillLineItemIds,
}: SettlementBillLineItemsTableProps) => {
  // This is the number of columns in the table and should be updated if the number of columns changes
  const COLUMNS_COUNT =
    mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE ? 10 : 8;

  const [openedOrderUuid, setOpenedOrderUuid] = useState<string | null>(null);

  const isAllSelected =
    selectedSettlementBillLineItemIds?.size ===
      settlementBillLineItems.length && settlementBillLineItems.length > 0;

  const isIndeterminate =
    !isNil(selectedSettlementBillLineItemIds) &&
    selectedSettlementBillLineItemIds.size > 0 &&
    selectedSettlementBillLineItemIds.size < settlementBillLineItems.length;

  return (
    <Stack direction="column" height="100%" spacing={1}>
      <TableContainer sx={{ overflowY: 'auto', flex: 1 }}>
        <Table
          stickyHeader
          aria-label="stop-driver-maps-table"
          size="small"
          sx={{
            '& .MuiTableCell-sizeSmall': {
              padding: '5px 5px',
            },
          }}
        >
          <colgroup>
            <col style={{ width: '1%' }} />
            {Array.from({ length: COLUMNS_COUNT - 1 }).map((_, index) => (
              <col
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={{ width: `${99 / COLUMNS_COUNT}%` }}
              />
            ))}
          </colgroup>
          <TableHead>
            <TableRow>
              {mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE ? (
                <TableCell sx={{ width: CHECKBOX_CELL_WIDTH }}>
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={isIndeterminate}
                    onChange={onSelectAllCheckboxChange}
                  />
                </TableCell>
              ) : (
                <TableCell />
              )}
              <TableCell>
                {/* TODO: add sort functionality when flat view is implemented BKO-1486 */}
                Completed at
              </TableCell>
              {mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE && (
                <TableCell>
                  <TableSortLabel>Driver/Agent</TableSortLabel>
                </TableCell>
              )}
              {mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE && (
                <TableCell>Type</TableCell>
              )}
              <TableCell>Customer</TableCell>
              <TableCell>Stop type</TableCell>
              <TableCell>HAWB</TableCell>
              <TableCell>Order #</TableCell>
              <TableCell>Ref #s</TableCell>
              <TableCell>Total</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {settlementBillLineItems.map((settlementBillLineItem) => (
              <SettlementBillLineItemsRow
                key={settlementBillLineItem.id}
                settlementBillLineItem={settlementBillLineItem}
                isChecked={selectedSettlementBillLineItemIds?.has(
                  settlementBillLineItem.id,
                )}
                setOpenedOrderUuid={setOpenedOrderUuid}
                mode={mode}
                onCheckboxChange={onCheckboxChange}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <OrderDialog
        open={!isNil(openedOrderUuid)}
        orderUuid={openedOrderUuid ?? null}
        onClose={async () => {
          setOpenedOrderUuid(null);
          await refresh();
        }}
      />
    </Stack>
  );
};

export default SettlementBillLineItemsTable;
