import { isNotNilOrEmptyString } from 'shared/string';
import { type DashboardDrawerItem } from './types';
import { type DashboardPage } from './types';

export const getAppBarTitle = ({
  pathname,
  selectedDrawerItem,
  currentOrganizationName,
}: {
  pathname: string;
  selectedDrawerItem?: DashboardDrawerItem<DashboardPage>;
  currentOrganizationName?: string;
}) => {
  if (isNotNilOrEmptyString(currentOrganizationName)) {
    return `Edit ${currentOrganizationName}`;
  }
  return selectedDrawerItem?.tabs.find((tab) => pathname === tab.url)?.name;
};
