import { RemoveCircleOutline } from '@mui/icons-material';
import { Box, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import { isNil } from 'lodash';
import type React from 'react';
import { type ReactNode } from 'react';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { stopPropagation } from '../utils/events';
import { type Option } from './types';
import {
  type FilterConfig,
  type FilterEditorComponents,
  type FilterEditorProps,
  type FilterTypeToOperatorMap,
  type NullableFilterCondition,
} from './types-v2';

type EditFilterGroupConditionProps<
  TFilterField extends string,
  TFilterType extends string,
  TFilterTypeToOperatorMap extends FilterTypeToOperatorMap<TFilterType>,
  TFilterFieldToTypeMap extends Record<TFilterField, TFilterType>,
> = {
  readonly condition: NullableFilterCondition<
    TFilterField,
    TFilterType,
    TFilterTypeToOperatorMap,
    TFilterFieldToTypeMap
  >;
  readonly leftContent: ReactNode;
  readonly filterConfig: FilterConfig<
    TFilterField,
    TFilterType,
    TFilterTypeToOperatorMap,
    TFilterFieldToTypeMap
  >;
  /** When supportsNesting is false, we hide fields that already have a filter applied */
  readonly hiddenFieldOptions: TFilterField[];
  readonly onEditFilterCondition: (
    condition: NullableFilterCondition<
      TFilterField,
      TFilterType,
      TFilterTypeToOperatorMap,
      TFilterFieldToTypeMap
    >,
  ) => void;
  readonly onDeleteFilterCondition: () => void;
};

const isOptionEqualToValue = <TValue,>(
  option: Option<TValue | null>,
  value: Option<TValue | null>,
) => option.value === value.value;

const NULL_OPTION: Option<null> = { value: null, label: '' };

const EditFilterGroupCondition = <
  TFilterField extends string,
  TFilterType extends string,
  TFilterTypeToOperatorMap extends FilterTypeToOperatorMap<TFilterType>,
  TFilterFieldToTypeMap extends Record<TFilterField, TFilterType>,
>({
  condition,
  leftContent,
  filterConfig,
  hiddenFieldOptions,
  onEditFilterCondition,
  onDeleteFilterCondition,
}: EditFilterGroupConditionProps<
  TFilterField,
  TFilterType,
  TFilterTypeToOperatorMap,
  TFilterFieldToTypeMap
>) => {
  const {
    filterFieldOptions,
    filterOperatorOptions,
    filterFieldToTypeMap,
    filterEditorComponents,
  } = filterConfig;

  const filterType = isNil(condition.field)
    ? null
    : filterFieldToTypeMap[condition.field];

  // Local type aliases for convenience / readability
  type FilterType = NonNullable<typeof filterType>;
  type Operator = keyof TFilterTypeToOperatorMap[FilterType];
  type Value = NonNullable<typeof condition.value>;

  const operatorOptions: Array<Option<Operator | null>> = isNil(filterType)
    ? []
    : filterOperatorOptions[filterType];

  type FilterTypeEditorComponents = FilterEditorComponents<
    TFilterType,
    TFilterTypeToOperatorMap
  >[FilterType];
  const filterTypeComponents: FilterTypeEditorComponents | null = isNil(
    filterType,
  )
    ? null
    : filterEditorComponents[filterType];
  const ValueEditorComponent: React.ComponentType<
    FilterEditorProps<Value>
  > | null =
    isNil(filterTypeComponents) || isNil(condition.operator)
      ? null
      : filterTypeComponents[condition.operator];

  return (
    <>
      <Box>{leftContent}</Box>
      <Stack
        direction="row"
        marginLeft="-1px"
        alignItems="start"
        // Tabbing while focused on an input element causes the popover to close unless we stop propagation.
        onKeyDown={stopPropagation}
      >
        <AutocompleteFuzzy<Option<TFilterField | null>>
          sx={{ backgroundColor: 'white', flex: 1 }}
          value={
            filterFieldOptions.find(
              (option) => option.value === condition.field,
            ) ?? NULL_OPTION
          }
          options={filterFieldOptions.filter(
            (option) => !hiddenFieldOptions.includes(option.value),
          )}
          matchSortOptions={{ keys: ['label'] }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              placeholder="Select property..."
              InputProps={{
                ...params.InputProps,
                sx: { borderRadius: 0 },
              }}
            />
          )}
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={(_, option) => {
            onEditFilterCondition({
              ...condition,
              field: option?.value ?? null,
              operator: null,
              value: null,
            });
          }}
        />
        <AutocompleteFuzzy<Option<Operator | null>>
          sx={{ backgroundColor: 'white', flex: 1 }}
          value={
            operatorOptions.find(
              (option) => option.value === condition.operator,
            ) ?? NULL_OPTION
          }
          options={operatorOptions}
          matchSortOptions={{ keys: ['label'] }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              placeholder="Select operator..."
              InputProps={{
                ...params.InputProps,
                sx: { borderRadius: 0, marginLeft: '-1px' },
              }}
            />
          )}
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={(_, option) => {
            onEditFilterCondition({
              ...condition,
              operator: option?.value ?? null,
              value: null,
            });
          }}
        />
        <Box flex={1}>
          {!isNil(ValueEditorComponent) && (
            <ValueEditorComponent
              value={condition.value}
              onChange={(value) => {
                onEditFilterCondition({ ...condition, value });
              }}
            />
          )}
        </Box>
      </Stack>
      <Tooltip title="Remove filter" placement="left">
        <IconButton
          size="small"
          sx={{ marginLeft: 1 }}
          onClick={onDeleteFilterCondition}
        >
          <RemoveCircleOutline />
        </IconButton>
      </Tooltip>
    </>
  );
};

export { EditFilterGroupCondition };
