import { ReceiptOutlined as OrdersIcon } from '@mui/icons-material';
import { isEmpty, isNil } from 'lodash';
import { useMemo } from 'react';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import { useWmsContactsQuery } from '../../../generated/graphql';
import {
  CustomerPortalPage,
  type DashboardDrawerItem,
  type DashboardTabs,
} from '../../../layouts/dashboard/types';

const ICON_SIZE = 16;

const useCustomerPortalDrawerItems = () => {
  const { data: wmsContacts } = useWmsContactsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const hasInventory = !isEmpty(wmsContacts?.wmsContacts ?? []);

  const ffCustomerPortalOrderEntry = useFeatureFlag(
    FeatureFlag.FF_CUSTOMER_PORTAL_ORDER_ENTRY,
  );

  const drawerItems = useMemo(() => {
    const CUSTOMER_PORTAL_TABS: DashboardTabs[] = [
      { url: '/customer-portal/orders', name: 'Orders' },
      {
        url: '/customer-portal/orders/booking-requests',
        name: 'Booking requests',
        visibilityCondition: ffCustomerPortalOrderEntry,
      },
      {
        url: '/customer-portal/inventory',
        name: 'Inventory',
        visibilityCondition: hasInventory,
      },
    ];

    let items: Array<DashboardDrawerItem<CustomerPortalPage>> = [
      {
        page: CustomerPortalPage.CUSTOMER_PORTAL,
        name: 'Your Freight Portal',
        url: '/customer-portal',
        icon: <OrdersIcon sx={{ height: ICON_SIZE, width: ICON_SIZE }} />,
        tabs: CUSTOMER_PORTAL_TABS,
        visibilityCondition: true,
      },
    ];

    items = items.filter(
      (item) => isNil(item.visibilityCondition) || item.visibilityCondition,
    );

    for (let i = 0; i < items.length; i += 1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      items[i] = {
        ...items[i],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tabs: items[i].tabs.filter(
          (item) => isNil(item.visibilityCondition) || item.visibilityCondition,
        ),
      };
    }
    return items;
  }, [hasInventory, ffCustomerPortalOrderEntry]);

  return {
    drawerItems,
  };
};

export default useCustomerPortalDrawerItems;
