import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TableCell,
  TableRow,
  TextField,
  Typography,
  useTheme,
  Stack,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { safeAdd } from 'shared/math';
import { exhaustive } from 'shared/switch';
import { calculateTotalVolume, calculateTotalWeight } from 'shared/weight';
import { LINE_HAUL_FREIGHT_CHARGE_TOTAL_TEST_ID } from '../../../../../../../../../constants';
import { isNilOrEmptyString } from '../../../../../../../../common/utils/utils';
import {
  FreightBillingMethod,
  TariffType,
  useTariffLazyQuery,
  useTariffQuery,
} from '../../../../../../../../generated/graphql';
import { useOrderFormEditAccess } from '../../../../contexts/order-form-edit-access-context';
import { type OrderFormValues } from '../../../../forms/types';
import { VALID_FREIGHT_CHARGE_BILLING_METHODS_FOR_DISCOUNTS } from '../../constants';
import {
  formatTariffRateString,
  getTariffGroupNameCopy,
  type Tariff,
} from '../../utils';

const HIDE_FROM_BILLING = 'HIDE_FROM_BILLING';

const QuantityField = React.memo(
  ({
    billingMethod,
    tariff,
  }: {
    readonly billingMethod: FreightBillingMethod | undefined;
    readonly tariff: Tariff | undefined;
  }) => {
    const { control } = useFormContext<OrderFormValues>();

    const packages = useWatch({ control, name: 'packages' });
    const dimFactor = useWatch({ control, name: 'dimFactor' });

    const totalWeight = calculateTotalWeight(
      packages ?? [],
      dimFactor ?? undefined,
      tariff?.useActualWeight ?? false,
    );
    const totalPieces = packages?.reduce(
      (prev, curr) => safeAdd(prev, curr.quantity ?? 0),
      0,
    );
    const totalVolume = calculateTotalVolume({ packages: packages ?? [] });

    switch (billingMethod) {
      case FreightBillingMethod.Tariff: {
        if (tariff?.tariffType === TariffType.PerHundredPounds) {
          return <Typography>{totalWeight} lbs</Typography>;
        }
        if (tariff?.tariffType === TariffType.PerPiece) {
          return <Typography>{totalPieces} pieces</Typography>;
        }
        if (tariff?.tariffType === TariffType.PerCubicFoot) {
          return <Typography>{totalVolume} cubic feet</Typography>;
        }
        return <Typography>1</Typography>;
      }
      case FreightBillingMethod.Weight: {
        return <Typography>{totalWeight} lbs</Typography>;
      }
      case FreightBillingMethod.FlatRate: {
        return <Typography>1</Typography>;
      }
      case FreightBillingMethod.PerPiece: {
        return <Typography>{totalPieces} pieces</Typography>;
      }
      case FreightBillingMethod.PerMile:
      case null:
      case undefined: {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
      }
      default: {
        return exhaustive(billingMethod);
      }
    }
  },
);

const LineHaulChargeRateField = ({
  billingMethod,
  tariff,
  loading,
  disabled,
}: {
  readonly billingMethod: FreightBillingMethod | undefined;
  readonly tariff: Tariff | undefined;
  readonly loading: boolean;
  readonly disabled?: boolean;
}) => {
  const { control, setValue } = useFormContext();
  //   const stopKey = `stops.${idx}`;
  const freightChargeKey = `lineHaulShipment.freightCharge`;
  const rate: number | undefined = useWatch({
    control,
    name: `${freightChargeKey}.rate`,
  });
  const [rateInput, setRateInput] = useState(rate?.toString() ?? '');
  const total: number | null | undefined = useWatch({
    control,
    name: `${freightChargeKey}.totalCharge`,
  });

  const tariffGroupName = tariff?.tariffGroup?.name;
  const tariffGroupScope = tariff?.tariffGroup?.tariffGroupScope;
  const tariffGroupType = tariff?.tariffGroup?.tariffGroupType;

  const tariffGroupNameCopy = getTariffGroupNameCopy({
    tariffGroupScope,
    tariffGroupName,
    tariffGroupType,
  });

  useEffect(() => {
    setRateInput(rate?.toString() ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingMethod]);

  const RateInput = (
    <TextField
      size="small"
      value={rateInput}
      label="Rate"
      disabled={disabled}
      onChange={(e) => {
        setRateInput(e.target.value);
        const parsedFloat = Number.parseFloat(e.target.value);
        if (!Number.isNaN(parsedFloat)) {
          setValue(`${freightChargeKey}.rate`, parsedFloat);
        }
      }}
    />
  );

  switch (billingMethod) {
    case FreightBillingMethod.Tariff: {
      return loading ? (
        <Skeleton />
      ) : (
        <>
          <Typography>
            {formatTariffRateString({ tariff, rate, total })}
          </Typography>
          {!isNilOrEmptyString(tariffGroupNameCopy) && (
            <Typography variant="caption">{tariffGroupNameCopy}</Typography>
          )}
        </>
      );
    }
    case FreightBillingMethod.Weight: {
      return RateInput;
    }
    case FreightBillingMethod.FlatRate: {
      return RateInput;
    }
    case FreightBillingMethod.PerMile: {
      return RateInput;
    }
    case FreightBillingMethod.PerPiece: {
      return RateInput;
    }
    case null:
    case undefined: {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
    default: {
      return exhaustive(billingMethod);
    }
  }
};

const LineHaulFreightChargeRow = () => {
  const theme = useTheme();

  const [getTariffByUuid] = useTariffLazyQuery();
  const { control, setValue } = useFormContext<OrderFormValues>();
  const freightChargeKey = `lineHaulShipment.freightCharge`;
  const billingMethod = useWatch({
    control,
    name: `${freightChargeKey}.billingMethod`,
  });
  const discountRate = useWatch({
    control,
    name: `${freightChargeKey}.discountRate`,
  });
  const tariffUuid = useWatch({
    control,
    name: `${freightChargeKey}.tariffUuid`,
  });
  const total = useWatch({ control, name: `${freightChargeKey}.totalCharge` });

  const { disabledIfFinalizedOrLater } = useOrderFormEditAccess();

  const { data: tariffData } = useTariffQuery(
    isNilOrEmptyString(tariffUuid)
      ? { skip: true }
      : { variables: { uuid: tariffUuid }, fetchPolicy: 'cache-and-network' },
  );

  const tariff = tariffData?.tariff;

  return (
    <TableRow>
      <TableCell>
        <FormControl>
          <InputLabel>Freight</InputLabel>
          <Select
            size="small"
            value={billingMethod ?? ''}
            label="Freight"
            disabled={disabledIfFinalizedOrLater}
            onChange={(e) => {
              const parsedValue = e.target.value as FreightBillingMethod;
              if (e.target.value === HIDE_FROM_BILLING) {
                setValue(
                  `${freightChargeKey}.billingMethod`,
                  FreightBillingMethod.FlatRate,
                );
                setValue(`${freightChargeKey}.discountRate`, null);
              } else {
                setValue(`${freightChargeKey}.billingMethod`, parsedValue);
                if (
                  !VALID_FREIGHT_CHARGE_BILLING_METHODS_FOR_DISCOUNTS.includes(
                    parsedValue,
                  )
                ) {
                  setValue(`${freightChargeKey}.discountRate`, null);
                }
              }
            }}
          >
            {[FreightBillingMethod.Tariff, FreightBillingMethod.FlatRate].map(
              (method) => (
                <MenuItem key={method} value={method}>
                  {sentenceCase(method)}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <LineHaulChargeRateField
          billingMethod={billingMethod}
          tariff={tariff}
          loading={false} // TODO: Fix this.
          disabled={disabledIfFinalizedOrLater}
        />
      </TableCell>
      <TableCell>
        <QuantityField billingMethod={billingMethod} tariff={tariff} />
      </TableCell>
      <TableCell>
        <Stack alignItems="flex-end" justifyContent="flex-end">
          <Typography data-testid={LINE_HAUL_FREIGHT_CHARGE_TOTAL_TEST_ID}>
            {isNil(total) ? '-' : currency(total).format()}
          </Typography>
          {!isNil(discountRate) && (
            <Typography
              sx={{ fontSize: '16px', color: theme.palette.grey[500] }}
            >
              {`Discount Rate: ${discountRate}`}
            </Typography>
          )}
        </Stack>
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

export default React.memo(LineHaulFreightChargeRow);
