import { isNil } from 'lodash';
import { QuoteSource } from '../../../../../generated/graphql';
import {
  type CustomerPortalAddressValues,
  type StopTypeWithoutNone,
} from '../../customer-portal-order-form/forms/types';
import { getStopLocationInfo } from '../../customer-portal-order-form/forms/utils';
import {
  type CustomerPortalQuoteFormValues,
  type CustomerPortalQuoteRateInfo,
} from '../types';

const getQuoteStopInformation = (
  stopInformation: CustomerPortalQuoteFormValues['inboundInformation'],
  stopType: StopTypeWithoutNone | null,
  totalAmountCents: number | undefined,
):
  | {
      address: CustomerPortalAddressValues;
      serviceDate: string | undefined;
      specialInstructions: string | undefined;
      totalAmountCents: number | undefined;
    }
  | {
      terminalId: string;
      serviceDate: string | undefined;
      specialInstructions: string | undefined;
      totalAmountCents: number | undefined;
    }
  | undefined => {
  const locationInfo = getStopLocationInfo(stopInformation, stopType);
  if (isNil(locationInfo)) {
    return undefined;
  }
  return {
    ...locationInfo,
    serviceDate: stopInformation?.serviceDate?.format('YYYY-MM-DD'),
    specialInstructions: stopInformation?.specialInstructions ?? undefined,
    totalAmountCents,
  };
};

/**
 * Transforms CustomerPortalOrderFormValues into the request format expected by the quotes API
 */
export const getCreateQuoteV1Request = ({
  quoteValues,
  clientId,
  customerId,
  quoteRateInfo,
  inboundStopType,
  outboundStopType,
}: {
  quoteValues: CustomerPortalQuoteFormValues;
  clientId: string;
  customerId: string;
  quoteRateInfo: CustomerPortalQuoteRateInfo;
  inboundStopType: StopTypeWithoutNone | null;
  outboundStopType: StopTypeWithoutNone | null;
}) => {
  const { serviceId, packages, inboundInformation, outboundInformation } =
    quoteValues;

  const baseRequest: Record<string, unknown> = {
    serviceId,
    packages,
    customerId,
    clientId,
    source: QuoteSource.CustomerPortal,
  };

  // If one of the stops is undefined, we can't include it in the request
  // otherwise Zod will complain about the unexpected field.
  if (!isNil(inboundInformation)) {
    const stopInformation = getQuoteStopInformation(
      inboundInformation,
      inboundStopType,
      quoteRateInfo.inboundTotalAmountCents,
    );
    if (!isNil(stopInformation)) {
      baseRequest.inboundInformation = stopInformation;
    }
  }

  if (!isNil(outboundInformation)) {
    const stopInformation = getQuoteStopInformation(
      outboundInformation,
      outboundStopType,
      quoteRateInfo.outboundTotalAmountCents,
    );
    if (!isNil(stopInformation)) {
      baseRequest.outboundInformation = stopInformation;
    }
  }

  return baseRequest;
};
