import { AccountCircle } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import apolloClient from '../apollo-client';
import CenteredCircularProgress from '../common/components/centered-circular-progress';
import useMe from '../common/react-hooks/use-me';
import styles from '../common/styles/Home.module.css';
import { getPageToOpenOnFirstLoad } from '../common/utils/pages';
import {
  type ClientChallengeEntity,
  LoginStatus,
  useLoginMutation,
  useMeAsThirdPartyUserQuery,
  useWhiteLabelCompanyQuery,
} from '../generated/graphql';
import PalletRouterLink from '../pallet-ui/links/router-link/pallet-router-link';
import PalletFrame from '../layouts/dashboard/pallet-frame';
import { FeatureFlag } from '../common/feature-flags';
import useFeatureFlag from '../common/react-hooks/use-feature-flag';
import { isNilOrEmptyString } from '../common/utils/utils';
import PalletLogo from '../layouts/dashboard/icons/pallet-logo.svg?react';
import PalletLink from '../pallet-ui/links/link/pallet-link';
import { styled, useTheme } from '@mui/material/styles';
import PalletButton from '../pallet-ui/button/pallet-button';

const StyledTextField = styled(TextField)(({ disabled, theme }) => ({
  input: {
    color: `${theme.palette.concreteGrey[10]} !important`,
    backgroundColor: `${theme.palette.airfreightBlue[90]} !important`,
    borderRadius: 2,
    border: `1px solid ${theme.palette.greyWhiteAlpha[40]}`,
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'white',
      borderWidth: disabled === true ? 0 : 1,
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      borderWidth: disabled === true ? 0 : 1,
    },
  },
}));

const StyledLoginButton = styled(PalletButton)(({ disabled, theme }) => ({
  marginTop: theme.spacing(1),
  backgroundColor:
    disabled === true ? '#FDFDFD14' : theme.palette.airfreightBlue[60],
  border:
    disabled === true
      ? `1px solid ${theme.palette.airfreightBlue[50]}`
      : `1px solid ${theme.palette.airfreightBlue[60]}`,
  '&:hover': {
    backgroundColor:
      disabled === true ? '#FDFDFD14' : theme.palette.airfreightBlue[80],
  },
}));

const StyledCancelButton = styled(PalletButton)(({ theme }) => ({
  marginTop: theme.spacing(1),
  backgroundColor: theme.palette.greyWhiteAlpha[20],
  border: `1px solid ${theme.palette.airfreightBlue[50]}`,
  '&:hover': {
    backgroundColor: '#FDFDFD14',
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  color: theme.palette.concreteGrey[10],
  backgroundColor: theme.palette.airfreightBlue[90],
  borderRadius: 1,
  border: `1px solid ${theme.palette.greyWhiteAlpha[40]}`,
  '&:hover': {
    borderColor: 'white',
  },
  '&.Mui-focused': {
    borderColor: 'white',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSelect-icon': {
    color: theme.palette.concreteGrey[10],
  },
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  '& .MuiMenuItem-root': {
    backgroundColor: theme.palette.airfreightBlue[90],
  },
}));

const StyledClientLogo = styled('img')(({ theme }) => ({
  aspectRatio: 1,
  height: '24px',
  backgroundColor: 'white',
  borderRadius: '4px',
  objectFit: 'contain',
}));

const Home = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { data: thirdPartyUser, loading: thirdPartyUserLoading } =
    useMeAsThirdPartyUserQuery();
  const [login, { loading: loginLoading }] = useLoginMutation();
  const { user, purchasedProductOfferings, loading } = useMe();

  const [searchParams] = useSearchParams();
  const clientUuidSearchParam = searchParams.get('client');
  const ffWhiteLabelCustomerPortal = useFeatureFlag(
    FeatureFlag.FF_WHITE_LABEL_CUSTOMER_PORTAL,
  );

  const ffBlueLogin = useFeatureFlag(FeatureFlag.FF_USE_BLUE_LOGIN);

  const { data: companyData } = useWhiteLabelCompanyQuery(
    isNilOrEmptyString(clientUuidSearchParam) || !ffWhiteLabelCustomerPortal
      ? { skip: true }
      : {
          variables: {
            uuid: clientUuidSearchParam,
          },
        },
  );
  const [selectedClientChallengeUuid, setSelectedClientChallengeUuid] =
    useState<string | null>(null);
  const [clientChallengeList, setClientChallengeList] = useState<
    ClientChallengeEntity[]
  >([]);
  const loginClientUuid = isNilOrEmptyString(clientUuidSearchParam)
    ? selectedClientChallengeUuid
    : clientUuidSearchParam;

  const theme = useTheme();

  const meAsThirdPartyUser = thirdPartyUser?.meAsThirdPartyUser;
  useEffect(() => {
    if (!isNil(user)) {
      const pageToOpen = getPageToOpenOnFirstLoad(
        purchasedProductOfferings ?? [],
      );
      navigate(pageToOpen);
    } else if (!isNil(meAsThirdPartyUser)) {
      navigate('/customer-portal');
    }
  }, [user, meAsThirdPartyUser, purchasedProductOfferings, navigate]);

  const surrender = () => {
    setSelectedClientChallengeUuid(null);
    setClientChallengeList([]);
    setError('');
    setEmail('');
    setPassword('');
  };

  const handleLogin = async () => {
    if (email !== '' && password !== '') {
      const loginInput = {
        email,
        password,
        clientUuid: loginClientUuid ?? undefined,
      };

      const loginResult = await login({
        variables: {
          loginInputData: loginInput,
        },
      });

      await apolloClient.resetStore();

      if (
        loginResult.data?.login?.status === LoginStatus.ClientChallenge &&
        !isNil(loginResult.data?.login?.clientChallenge)
      ) {
        setClientChallengeList(loginResult.data?.login?.clientChallenge ?? []);
        setSelectedClientChallengeUuid(
          loginResult.data?.login?.clientChallenge?.[0]?.uuid ?? null,
        );
        return;
      }

      if (loginResult.data?.login?.status !== LoginStatus.Success) {
        setError(loginResult.data?.login?.status as string);
        return;
      }

      if (loginResult.data?.login?.status === LoginStatus.Success) {
        if (
          loginResult.data?.login?.kind === 'user' &&
          !isNil(
            loginResult.data?.login?.fullAccessUser?.company
              ?.purchasedProductOfferings,
          )
        ) {
          const pageToOpen = getPageToOpenOnFirstLoad(
            loginResult.data?.login?.fullAccessUser?.company
              ?.purchasedProductOfferings,
          );
          navigate(pageToOpen);
        } else if (loginResult.data?.login?.kind === 'third-party') {
          navigate('/customer-portal');
        }
      }
    }
  };

  const isInClientChallenge = clientChallengeList.length > 0;

  if (loading || thirdPartyUserLoading) {
    return <CenteredCircularProgress style={{ marginTop: '24px' }} />;
  }

  if (ffBlueLogin) {
    const isLoginDisabled =
      loginLoading || isNilOrEmptyString(email) || isNilOrEmptyString(password);
    return (
      <PalletFrame>
        <Stack
          bgcolor={theme.palette.airfreightBlue[90]}
          flex={1}
          justifyContent="center"
          alignItems="center"
          gap={8}
        >
          <Stack width="295px">
            {isNilOrEmptyString(clientUuidSearchParam) ||
            !ffWhiteLabelCustomerPortal ? (
              <PalletLogo color="white" width="100%" />
            ) : isNil(companyData?.companyByUuid?.companyLandscapeLogoUrl) ? (
              <Typography
                color="white"
                fontSize={24}
                fontWeight={600}
                textAlign="center"
              >
                {companyData?.companyByUuid?.name} Customer Portal
              </Typography>
            ) : (
              <img
                src={companyData?.companyByUuid?.companyLandscapeLogoUrl}
                alt="Company logo"
                style={{
                  backgroundColor: 'white',
                  borderRadius: '4px',
                  objectFit: 'contain',
                }}
              />
            )}
          </Stack>
          <Stack
            bgcolor={theme.palette.greyWhiteAlpha[20]}
            borderRadius={2}
            border={1}
            borderColor={theme.palette.greyWhiteAlpha[40]}
            width="400px"
          >
            <Stack
              flex={1}
              flexDirection="column"
              justifyContent="center"
              alignItems="stretch"
              px={4}
              py={3}
            >
              <Box
                display="grid"
                gridTemplateColumns="1fr 3fr"
                alignItems="center"
                gap={1}
                width="100%"
              >
                <Typography
                  color={theme.palette.greyWhiteAlpha[60]}
                  fontSize={14}
                >
                  Email
                </Typography>
                <StyledTextField
                  inputProps={{
                    'aria-label': 'Email',
                  }}
                  data-cy="email"
                  id="email"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  value={email}
                  size="small"
                  disabled={isInClientChallenge}
                />
                <Typography
                  color={theme.palette.greyWhiteAlpha[60]}
                  fontSize={14}
                >
                  Password
                </Typography>
                <StyledTextField
                  inputProps={{
                    'aria-label': 'Password',
                  }}
                  data-cy="password"
                  id="password"
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  type="password"
                  value={password}
                  size="small"
                  disabled={isInClientChallenge}
                />
              </Box>
              {isInClientChallenge && (
                <Stack mt={3}>
                  <Stack>
                    <Typography color={theme.palette.concreteGrey[10]}>
                      Select a client
                    </Typography>
                  </Stack>
                  <StyledSelect
                    value={selectedClientChallengeUuid ?? null}
                    onChange={(event) => {
                      setSelectedClientChallengeUuid(
                        event.target.value as string,
                      );
                    }}
                    size="small"
                    renderValue={(value) => {
                      const selectedClient = clientChallengeList.find(
                        (client) => client.uuid === value,
                      );
                      return (
                        <Stack direction="row" alignItems="center" gap={1}>
                          {isNil(selectedClient?.squareLogoUrl) ? (
                            <Box
                              sx={{
                                width: 24,
                                height: 24,
                                backgroundColor:
                                  theme.palette.airfreightBlue[60],
                              }}
                            />
                          ) : (
                            <StyledClientLogo
                              src={selectedClient.squareLogoUrl}
                              alt={`${selectedClient.name} logo`}
                            />
                          )}
                          <Typography>
                            {selectedClient?.name?.slice(0, 31)}
                          </Typography>
                        </Stack>
                      );
                    }}
                  >
                    {clientChallengeList.map((client) => (
                      <MenuItem
                        key={client.uuid}
                        value={client.uuid}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          '&.Mui-selected': {
                            backgroundColor: `${theme.palette.concreteGrey[40]} !important`,
                            '&:hover': {
                              backgroundColor: `${theme.palette.concreteGrey[40]} !important`,
                            },
                          },
                          '&.Mui-focused': {
                            backgroundColor: `${theme.palette.concreteGrey[30]} !important`,
                          },
                          '&:hover': {
                            backgroundColor: `${theme.palette.concreteGrey[30]} !important`,
                          },
                        }}
                      >
                        {isNil(client.squareLogoUrl) ? (
                          <Box
                            sx={{
                              width: 24,
                              height: 24,
                              backgroundColor: theme.palette.airfreightBlue[60],
                            }}
                          />
                        ) : (
                          <StyledClientLogo
                            src={client.squareLogoUrl}
                            alt={`${client.name} logo`}
                          />
                        )}
                        <Typography noWrap sx={{ flex: 1 }}>
                          {client.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </Stack>
              )}
              <StyledLoginButton
                data-cy="login"
                loading={loginLoading}
                fullWidth
                variant="contained"
                onClick={handleLogin}
                disabled={isLoginDisabled}
              >
                <Typography
                  color={
                    isLoginDisabled ? theme.palette.airfreightBlue[50] : 'white'
                  }
                >
                  {isInClientChallenge ? 'Continue' : 'Sign in'}
                </Typography>
              </StyledLoginButton>
              {isInClientChallenge && (
                <StyledCancelButton
                  loading={loginLoading}
                  fullWidth
                  variant="contained"
                  onClick={surrender}
                >
                  <Typography color={theme.palette.airfreightBlue[50]}>
                    Cancel
                  </Typography>
                </StyledCancelButton>
              )}
              {!isNil(error) && (
                <Typography color="error" data-cy="error">
                  {sentenceCase(error)}
                </Typography>
              )}
              <Stack mt={1} justifyContent="center" alignItems="center">
                <PalletLink
                  href="/customer-portal/create"
                  color={theme.palette.airfreightBlue[50]}
                >
                  <Typography>Or, activate your account</Typography>
                </PalletLink>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </PalletFrame>
    );
  }

  return (
    <div className={styles.container}>
      <Box display="flex" justifyContent="end">
        <PalletRouterLink to="/customer-portal/create">
          <Button
            startIcon={<AccountCircle />}
            variant="outlined"
            color="primary"
            sx={{ mt: 4 }}
          >
            Create a customer portal account
          </Button>
        </PalletRouterLink>
      </Box>
      <main className={styles.main}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TextField
            inputProps={{
              'aria-label': 'Email',
            }}
            data-cy="email"
            id="email"
            label="Email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            sx={{ mb: 1 }}
            value={email}
            variant="outlined"
          />
          <TextField
            inputProps={{
              'aria-label': 'Password',
            }}
            data-cy="password"
            id="password"
            label="Password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            sx={{ mb: 1 }}
            type="password"
            value={password}
            variant="outlined"
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button
              data-cy="login"
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              disabled={!email || !password}
              startIcon={loginLoading && <CircularProgress size={20} />}
              sx={{ width: '100%' }}
              variant="contained"
              type="submit"
            >
              Login
            </Button>
          </Box>
          <Typography color="red" data-cy="error">
            {isNil(error) ? '' : sentenceCase(error)}
          </Typography>
        </form>
      </main>
    </div>
  );
};

export default Home;
