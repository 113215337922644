import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  MenuItem,
  Menu,
  MenuList,
  Fade,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import {
  useRemoveLineItemsFromSettlementBillMutation,
  useSettlementBillLineItemWithCostsLazyQuery,
  DriverSettlementBillByUuidV2Document,
  DriverSettlementBillsDocument,
} from '../../../generated/graphql';
import { SettlementBillLineItemsTableMode } from '../enums';
import { type SettlementBillLineItem } from '../types';
import { CHECKBOX_CELL_WIDTH, CompactRow, SettlementTableCell } from './common';
import SettlementBillLineItemCostsSummary from './settlement-bill-line-item-costs-summary';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import pluralize from 'pluralize';

type SettlementBillLineItemRowProps = {
  readonly settlementBillLineItem: SettlementBillLineItem;
  readonly isChecked?: boolean;
  readonly onCheckboxChange?: (settlementBillLineItemId: string) => void;
  readonly setOpenedOrderUuid: Dispatch<SetStateAction<string | null>>;
  readonly mode: SettlementBillLineItemsTableMode;
};

export const SettlementBillLineItemsRow = ({
  settlementBillLineItem,
  isChecked,
  onCheckboxChange,
  setOpenedOrderUuid,
  mode,
}: SettlementBillLineItemRowProps) => {
  const {
    setErrorMessage,
    setShowErrorMessage,
    setSuccessMessage,
    setShowSuccessMessage,
  } = useGlobalStore();

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [
    getLineItemWithCosts,
    { data: lineItemWithCostsData, loading: lineItemWithCostsLoading },
  ] = useSettlementBillLineItemWithCostsLazyQuery();
  const [
    removeLineItemsFromSettlementBill,
    { loading: removeLineItemsFromSettlementBillLoading },
  ] = useRemoveLineItemsFromSettlementBillMutation({
    refetchQueries: [
      DriverSettlementBillsDocument,
      DriverSettlementBillByUuidV2Document,
    ],
    onCompleted: async (data) => {
      if (
        data?.removeLineItemsFromSettlementBill.__typename ===
        'MutationErrorOutput'
      ) {
        setErrorMessage(data.removeLineItemsFromSettlementBill.message);
        setShowErrorMessage(true);
      } else {
        const { settlementBillLineItemsCount } =
          data.removeLineItemsFromSettlementBill;
        setSuccessMessage(
          `Success removing ${settlementBillLineItemsCount} line ${pluralize(
            'item',
            settlementBillLineItemsCount,
          )} from settlement bill`,
        );
        setShowSuccessMessage(true);
      }
    },
    onError: () => {
      setErrorMessage('Error removing line items from settlement bill');
      setShowErrorMessage(true);
    },
  });

  const { shipment, driver, driverSettlementBill } = settlementBillLineItem;
  const order = shipment?.order;
  const stop = shipment?.legs[0]?.endStop;

  const removeFromSettlementBill = async () => {
    if (!isNil(driverSettlementBill)) {
      await removeLineItemsFromSettlementBill({
        variables: {
          removeLineItemsFromSettlementBillInput: {
            settlementBillLineItemIds: [settlementBillLineItem.id],
            settlementBillUuid: driverSettlementBill.uuid,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (isExpanded) {
      void getLineItemWithCosts({
        variables: {
          id: settlementBillLineItem.id,
        },
      });
    }
  }, [isExpanded, settlementBillLineItem.id, getLineItemWithCosts]);

  const formattedCompletedAt = isNil(stop?.completedAt)
    ? 'Incomplete'
    : // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      dayjs(stop.completedAt).format('MM/DD/YY');

  const refNumbers = order?.refNumbers.join(', ');

  const settlementBillFinalized = !isNil(driverSettlementBill?.finalizedDate);

  return (
    <>
      <CompactRow
        sx={{
          backgroundColor:
            isExpanded || isHovered ? 'rgba(0, 0, 0, 0.02)' : 'transparent',
        }}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <SettlementTableCell sx={{ width: CHECKBOX_CELL_WIDTH }}>
          {mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE ? (
            <Checkbox
              checked={isChecked}
              onClick={(e) => {
                e.stopPropagation();
                onCheckboxChange?.(settlementBillLineItem.id);
              }}
            />
          ) : (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(!isExpanded);
              }}
            >
              {isExpanded ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </IconButton>
          )}
        </SettlementTableCell>
        <SettlementTableCell>{formattedCompletedAt}</SettlementTableCell>
        {mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE && (
          <SettlementTableCell>{driver?.user.name}</SettlementTableCell>
        )}
        {mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE && (
          <SettlementTableCell>
            {sentenceCase(driver?.driverType ?? '-')}
          </SettlementTableCell>
        )}
        <SettlementTableCell>
          <Tooltip
            title={order?.billingPartyContact?.displayName}
            placement="top"
          >
            <span>{order?.billingPartyContact?.displayName}</span>
          </Tooltip>
        </SettlementTableCell>
        <SettlementTableCell sx={{ maxWidth: '25px' }}>
          {sentenceCase(stop?.stopType ?? '-')}
        </SettlementTableCell>
        <SettlementTableCell>
          <Tooltip
            title={order?.standardOrderFields?.shipperBillOfLadingNumber}
            placement="top"
          >
            <span>{order?.standardOrderFields?.shipperBillOfLadingNumber}</span>
          </Tooltip>
        </SettlementTableCell>
        <SettlementTableCell>{order?.name}</SettlementTableCell>
        <SettlementTableCell>
          {' '}
          <Tooltip title={refNumbers} placement="top">
            <span>{refNumbers}</span>
          </Tooltip>
        </SettlementTableCell>
        <SettlementTableCell>
          {currency(settlementBillLineItem.totalAmountUsdDollars ?? 0).format()}
        </SettlementTableCell>
        <TableCell
          align="right"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Fade in={isHovered}>
            <Stack direction="row" sx={{ float: 'right' }} alignItems="center">
              {!isNil(order?.uuid) && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenedOrderUuid(order.uuid);
                  }}
                >
                  Open
                </Button>
              )}
              {mode === SettlementBillLineItemsTableMode.SETTLEMENT_BILL &&
                !settlementBillFinalized &&
                !isNil(driverSettlementBill) && (
                  <>
                    <IconButton
                      size="small"
                      id="more-menu-button"
                      aria-controls={
                        isNil(menuAnchorEl) ? undefined : 'more-menu'
                      }
                      aria-haspopup="true"
                      aria-expanded={isNil(menuAnchorEl) ? undefined : 'true'}
                      onClick={(e) => {
                        setMenuAnchorEl(e.currentTarget);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="more-menu"
                      anchorEl={menuAnchorEl}
                      open={Boolean(menuAnchorEl)}
                      MenuListProps={{
                        'aria-labelledby': 'more-menu-button',
                      }}
                      onClose={() => {
                        setMenuAnchorEl(null);
                      }}
                    >
                      <MenuList sx={{ p: 0 }}>
                        <MenuItem
                          disabled={removeLineItemsFromSettlementBillLoading}
                          onClick={removeFromSettlementBill}
                        >
                          <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Remove from bill</ListItemText>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </>
                )}
            </Stack>
          </Fade>
        </TableCell>
      </CompactRow>
      {mode === SettlementBillLineItemsTableMode.SETTLEMENT_BILL && (
        <TableRow>
          <SettlementTableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              border: 0,
            }}
            colSpan={8}
          >
            <Collapse unmountOnExit in={isExpanded} timeout="auto">
              <Stack spacing={1} sx={{ pl: '40px' }}>
                {lineItemWithCostsLoading && <CircularProgress size={20} />}
                {!isNil(lineItemWithCostsData?.settlementBillLineItem) && (
                  <SettlementBillLineItemCostsSummary
                    lineItem={lineItemWithCostsData.settlementBillLineItem}
                  />
                )}
              </Stack>
            </Collapse>
          </SettlementTableCell>
        </TableRow>
      )}
    </>
  );
};
