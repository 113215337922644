import { isNil } from 'lodash';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { EnvironmentVariables } from '../../../../../environment-variables';
import useGlobalStore from '../../../../../layouts/dashboard/global-store';
import { StopMethod } from '../../../../orders/components/order-form/forms/stop-type';
import { useCustomerPortalOrderFormContext } from '../contexts/customer-portal-order-form-context';
import { type CustomerPortalOrderFormValues } from '../forms/types';
import { getCreateOrderV3Request } from '../forms/utils';

/**
 * Hook for saving orders in the customer portal.
 *
 * This expects to be called with third-party user auth,
 * so it should only be used in the customer portal.
 *
 * This also expects to be called within the CustomerPortalOrderFormValues RHF context.
 */
const useSaveOrderCustomerPortal = ({
  companyUuid,
  contactUuid,
  latestQuoteUuid,
}: {
  companyUuid: string;
  contactUuid: string;
  latestQuoteUuid: string | null;
}) => {
  const { handleSubmit, clearErrors } =
    useFormContext<CustomerPortalOrderFormValues>();
  const navigate = useNavigate();
  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore(
    (state) => [
      state.setSuccessMessage,
      state.setShowSuccessMessage,
      state.setErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );

  const errorCallback = useCallback(
    (e: unknown) => {
      // We generally try to avoid console logs in production, but this is very
      // helpful for debugging issues where customers can't save orders in prod.
      // eslint-disable-next-line no-console
      console.error(
        `Error saving order: ${e instanceof Error ? e.message : JSON.stringify(e)}`,
      );
      // Log the full error to the console but only show a generic error message
      // to the user: they probably can't fix e.g. a Zod validation error.
      setErrorMessage('Error saving order');
      setShowErrorMessage(true);
    },
    [setErrorMessage, setShowErrorMessage],
  );

  const { getStopType } = useCustomerPortalOrderFormContext();
  const inboundStopType = getStopType(StopMethod.Inbound);
  const outboundStopType = getStopType(StopMethod.Outbound);

  /**
   * Submits the order to the backend once the form validation succeeds
   *
   * @param orderValues - The current form values from RHF's handleSubmit
   * Use handleSubmit's success callback to get the order values so that
   * Zod transformations (e.g. trimming strings) are applied.
   */
  const successCallback = useCallback(
    async (orderValues: CustomerPortalOrderFormValues) => {
      const createOrderV3Request = getCreateOrderV3Request({
        orderValues,
        clientId: companyUuid,
        customerId: contactUuid,
        quoteId: latestQuoteUuid,
        inboundStopType,
        outboundStopType,
      });

      try {
        const res = await fetch(
          `${EnvironmentVariables.VITE_BACKEND_URL}/v3/orders`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(createOrderV3Request),
          },
        );

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const data = await res.json();
        if (res.status !== 201 || !isNil(data.error)) {
          throw new Error(
            (data.error as string | undefined) ?? 'Error saving order',
          );
        }

        setSuccessMessage('Order saved');
        setShowSuccessMessage(true);
        navigate('/customer-portal/orders');
      } catch (error) {
        errorCallback(error);
      }
    },
    [
      companyUuid,
      contactUuid,
      inboundStopType,
      outboundStopType,
      navigate,
      errorCallback,
      setSuccessMessage,
      setShowSuccessMessage,
      latestQuoteUuid,
    ],
  );

  const saveOrder = useCallback(async () => {
    clearErrors();
    await handleSubmit(successCallback, errorCallback)();
  }, [clearErrors, handleSubmit, successCallback, errorCallback]);

  return {
    /**
     * Saves the order from the current form values and handles navigation.
     */
    saveOrder,
  };
};

export { useSaveOrderCustomerPortal };
