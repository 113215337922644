import { Fade, styled, TableCell, TableRow } from '@mui/material';
import { useState } from 'react';
import ButtonLink from '../../../../common/components/buttons/button-link';
import {
  type FormattedOrderFragment,
  OrderTableField,
} from '../../../../generated/graphql';
import { getOrderTableFieldValue } from '../../../orders/components/utils';

const TruncatedTableCell = styled(TableCell)({
  maxWidth: 200,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

type CustomerPortalBookingRequestsTableRowProps = {
  readonly order: FormattedOrderFragment;
};

const CustomerPortalBookingRequestsTableRow = ({
  order,
}: CustomerPortalBookingRequestsTableRowProps) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TruncatedTableCell>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.Name,
        })}
      </TruncatedTableCell>
      <TruncatedTableCell>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.BillOfLadingNumber,
        })}
      </TruncatedTableCell>
      <TruncatedTableCell>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.Company,
        })}
      </TruncatedTableCell>
      <TruncatedTableCell>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.CreatedAt,
        })}
      </TruncatedTableCell>
      <TruncatedTableCell>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.Charges,
        })}
      </TruncatedTableCell>
      <TableCell>
        <Fade in={isHovering}>
          <ButtonLink
            href={`/customer-portal/orders/booking-requests/${order.uuid}`}
            variant="contained"
          >
            Open
          </ButtonLink>
        </Fade>
      </TableCell>
    </TableRow>
  );
};

export default CustomerPortalBookingRequestsTableRow;
