import {
  ChevronRight,
  FeaturedPlayListOutlined,
  QrCode,
} from '@mui/icons-material';
import {
  Chip,
  Stack,
  type StepIconProps,
  StepLabel,
  Typography,
  styled,
} from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import {
  LineHaulManifestStatus,
  type StandardOrderFragmentFragment,
} from '../../../../../../../generated/graphql';
import theme from '../../../../../../../theme';
import LineHaulPartialPiecesWarning from '../../../../../../line-haul/components/common/line-haul-partial-pieces-warning';
import PalletLink from '../../../../../../../pallet-ui/links/link/pallet-link';
import { exhaustive } from 'shared/switch';
import { isNotNil } from 'shared/optional';

type QontoStepIconRootProps = {
  ownerState: {
    active?: boolean;
    completed?: boolean;
  };
};

const QontoStepIconRoot = styled('div')<QontoStepIconRootProps>(
  ({ ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(!isNil(ownerState.completed) &&
      ownerState.completed && {
        color: 'black',
      }),
    ...(!isNil(ownerState.active) &&
      ownerState.active && {
        color: 'black',
      }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);

const QontoStepIcon = ({ active, completed, className }: StepIconProps) => (
  <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
    <div className="QontoStepIcon-circle" />
  </QontoStepIconRoot>
);

type StatusChipProps = {
  terminalStatus: LineHaulManifestStatus;
};

const StatusChip = styled(Chip)<StatusChipProps>`
  width: fit-content;
  font-size: 11px;
  height: 20px;
  padding: 0 6px;
  .MuiChip-label {
    padding-left: 6px;
    padding-right: 6px;
  }
  border: none;
  ${({ terminalStatus }) => {
    switch (terminalStatus) {
      case LineHaulManifestStatus.Departed: {
        return `
          background-color: #4C8EFF26;
          color: #2A74F3;
        `;
      }
      case LineHaulManifestStatus.Arrived: {
        return `
          background-color: #CB981933;
          color: ${theme.palette.warningYellow[60]};
        `;
      }
      case LineHaulManifestStatus.Planning: {
        return `
          background-color: #888D9D40;
          color: ${theme.palette.concreteGrey[50]};
        `;
      }
      default: {
        return exhaustive(terminalStatus);
      }
    }
  }}
`;

type ScanIndicatorProps = Readonly<{
  scans: Array<{ scannedAt: string }>;
}>;

const ScanIndicator = ({ scans }: ScanIndicatorProps) => {
  const lastScan = scans.at(-1);
  if (isNil(lastScan)) {
    return null;
  }
  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <QrCode sx={{ color: 'gray', fontSize: 12 }} />
      <Typography variant="caption" color="gray" fontSize={12}>
        {dayjs(lastScan.scannedAt).format('MM/DD hh:mm A')}
      </Typography>
    </Stack>
  );
};

const LinkInChip = styled(PalletLink)`
  cursor: pointer;
  direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #0070e0;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

type ManifestLinkProps = Readonly<{
  event: StandardOrderFragmentFragment['lineHaulTimeline'][number];
}>;

const ManifestLink = ({ event }: ManifestLinkProps) => {
  const isAgentDriver = isNotNil(event.departingManifest?.driver?.agentId);
  if (isNil(event.departingManifest)) {
    if (!isNil(event.markedArrivedAt)) {
      return (
        <Stack direction="row" alignItems="center" gap={0.5}>
          <FeaturedPlayListOutlined sx={{ color: 'gray', fontSize: 12 }} />
          <Typography sx={{ fontSize: 12, whiteSpace: 'nowrap' }}>
            {dayjs(event.markedArrivedAt as string).format('MM/DD')}
          </Typography>
        </Stack>
      );
    }
    return null;
  }
  return (
    <Stack gap={0.5}>
      <Stack direction="row" alignItems="center" gap={0.5}>
        <FeaturedPlayListOutlined sx={{ color: 'gray', fontSize: 12 }} />
        <LinkInChip
          href={`/dispatch/line-haul/?manifestUuid=${event.departingManifest.uuid}`}
          target="_blank"
        >
          <Typography sx={{ fontSize: 12, whiteSpace: 'nowrap' }}>
            {dayjs(
              (event.departingManifest.startedAt ??
                event.departingManifest.departDate) as string,
            ).format('MM/DD')}
            {isAgentDriver && ' via Agent'}
          </Typography>
          <ChevronRight sx={{ cursor: 'pointer' }} fontSize="small" />
        </LinkInChip>
      </Stack>
      {!isNil(event.departingManifest?.driver) && !isAgentDriver && (
        <Typography
          component="div"
          sx={{
            fontSize: 12,
            color: theme.palette.grey[700],
            textAlign: 'left',
          }}
          title={`Driver: ${event.departingManifest?.driver?.firstName} ${event.departingManifest?.driver?.lastName}`}
        >
          Driver: {event.departingManifest?.driver?.firstName}{' '}
          {event.departingManifest?.driver?.lastName}
        </Typography>
      )}
    </Stack>
  );
};

type StatusContentProps = Readonly<{
  event: StandardOrderFragmentFragment['lineHaulTimeline'][number];
  expectedPieceCount: number;
}>;

export const StatusContent = ({
  event,
  expectedPieceCount,
}: StatusContentProps) => {
  if (event.departureScans.length > 0) {
    return (
      <Stack gap={1}>
        <StatusChip
          label="DEPARTED"
          terminalStatus={LineHaulManifestStatus.Departed}
          variant="outlined"
        />
        <Stack alignItems="flex-start" gap={1}>
          <ManifestLink event={event} />
          <ScanIndicator scans={event.departureScans} />
          <LineHaulPartialPiecesWarning
            totalScans={event.departureScans.length}
            totalPieces={expectedPieceCount}
          />
        </Stack>
      </Stack>
    );
  }
  if (event.arrivalScans.length > 0) {
    return (
      <Stack gap={1}>
        <StatusChip
          label="ARRIVED"
          terminalStatus={LineHaulManifestStatus.Arrived}
          variant="outlined"
        />
        <Stack alignItems="flex-start" gap={1}>
          <ManifestLink event={event} />
          <LineHaulPartialPiecesWarning
            totalScans={event.arrivalScans.length}
            totalPieces={expectedPieceCount}
          />
        </Stack>
      </Stack>
    );
  }
  if (
    event.departingManifest?.status === LineHaulManifestStatus.Departed ||
    event.departingManifest?.status === LineHaulManifestStatus.Arrived
  ) {
    return (
      <Stack gap={1}>
        <StatusChip
          label="DEPARTED"
          terminalStatus={LineHaulManifestStatus.Departed}
          variant="outlined"
        />
        <Stack alignItems="flex-start" gap={1}>
          <ManifestLink event={event} />
          <ScanIndicator scans={event.arrivalScans} />
          <LineHaulPartialPiecesWarning
            totalScans={event.arrivalScans.length}
            totalPieces={expectedPieceCount}
          />
        </Stack>
      </Stack>
    );
  }
  if (event.departingManifest?.status === LineHaulManifestStatus.Planning) {
    return (
      <Stack gap={1}>
        <StatusChip
          label="PLANNING"
          terminalStatus={LineHaulManifestStatus.Planning}
        />
        <ManifestLink event={event} />
      </Stack>
    );
  }
  if (!isNil(event.markedArrivedAt)) {
    return (
      <Stack gap={1}>
        <StatusChip
          label="ARRIVED"
          terminalStatus={LineHaulManifestStatus.Arrived}
          variant="outlined"
        />
        <Stack alignItems="flex-start" gap={1}>
          <ManifestLink event={event} />
          <ScanIndicator scans={event.arrivalScans} />
          <LineHaulPartialPiecesWarning
            totalScans={event.arrivalScans.length}
            totalPieces={expectedPieceCount}
          />
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack gap={1}>
      <StatusChip
        label="AWAITING"
        terminalStatus={LineHaulManifestStatus.Planning}
      />
      <ManifestLink event={event} />
    </Stack>
  );
};

type LineHaulTimelineEventProps = Readonly<{
  event: StandardOrderFragmentFragment['lineHaulTimeline'][number];
  expectedPieceCount: number;
}>;

export const LineHaulTimelineEvent = ({
  event,
  expectedPieceCount,
}: LineHaulTimelineEventProps) => (
  <StepLabel
    sx={{ padding: 0, marginTop: 0 }}
    StepIconComponent={QontoStepIcon}
  >
    <Stack sx={{ fontSize: 12 }} gap={1}>
      <Typography sx={{ textAlign: 'center', width: '100%' }}>
        {event.terminal.code}
      </Typography>
      <Stack alignItems="flex-start" width="100%">
        <StatusContent event={event} expectedPieceCount={expectedPieceCount} />
      </Stack>
    </Stack>
  </StepLabel>
);
