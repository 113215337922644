import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SkinnyHamburger from '@mui/icons-material/MoreVert';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import dayjs, { type Dayjs } from 'dayjs';
import { useState } from 'react';
import { shallow } from 'zustand/shallow';
import {
  LineHaulManifestGroupsDocument,
  useCreateLineHaulManifestMutation,
} from '../../../../../generated/graphql';
import useLineHaulDispatchStore from '../../../store/line-haul-dispatch-store';
import DeleteManifestsConfirmationModal from '../delete-manifests-confirmation-modal';
import PrintLineHaulManifestsModal from '../print-line-haul-manifests-modal';
import { type LineHaulManifest } from '../utils';
import StatusPicker from './status-picker';

type ManifestDetailsHeaderProps = {
  readonly manifest: LineHaulManifest;
  readonly departDate: Dayjs;
  readonly isRefetching: boolean;
};

const ManifestDetailsHeader = ({
  manifest,
  departDate,
  isRefetching,
}: ManifestDetailsHeaderProps) => {
  const [setOpenedManifest, deselectManifestUuid] = useLineHaulDispatchStore(
    (state) => [state.setOpenedManifest, state.deselectManifestUuid],
    shallow,
  );

  // Modal states
  const [showPrintManifestsModal, setShowPrintManifestsModal] =
    useState<boolean>(false);
  const [showDeleteManifestModal, setShowDeleteManifestModal] =
    useState<boolean>(false);

  const [disableMenuSelection, setDisableMenuSelection] = useState(false);

  // Vertical menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Add manifest
  const [createManifest, { loading: createManifestLoading }] =
    useCreateLineHaulManifestMutation();
  const handleAddManifest = async () => {
    await createManifest({
      variables: {
        createLineHaulManifestInput: {
          lineHaulManifestCreateInput: {
            startTerminalUuid: manifest.startTerminal.uuid,
            endTerminalUuid: manifest.endTerminal.uuid,
            departDate: departDate.toISOString(),
            referenceNumber: manifest.referenceNumber,
          },
        },
      },
      onCompleted: async (res) => {
        // Disable the menu selection while the refetch is happening to prevent
        // the user from adding another manifest while the refetch is happening
        setDisableMenuSelection(true);
        setAnchorEl(null);
        setOpenedManifest(res.createLineHaulManifest);
        setDisableMenuSelection(false);
      },
      refetchQueries: [LineHaulManifestGroupsDocument],
    });
  };

  // Refetch manifests after deleting a manifest
  const onManifestDeleteComplete = async () => {
    // Remove the uuid from the selected manifests
    deselectManifestUuid(manifest.uuid);
    setAnchorEl(null);
  };

  const isLoading = createManifestLoading || isRefetching;

  return (
    <>
      <PrintLineHaulManifestsModal
        open={showPrintManifestsModal}
        setOpen={setShowPrintManifestsModal}
        manifests={[manifest]}
        departDate={departDate}
      />
      <DeleteManifestsConfirmationModal
        open={showDeleteManifestModal}
        setOpen={setShowDeleteManifestModal}
        manifestUuids={[manifest.uuid]}
        onDeleteComplete={onManifestDeleteComplete}
      />
      <Stack p={1} direction="row" justifyContent="space-between" gap={1}>
        <Stack direction="row" gap={1} alignItems="center" minWidth={0}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              setOpenedManifest(undefined);
            }}
          >
            Back
          </Button>
          <Stack minWidth={0}>
            <Typography
              fontSize={18}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {manifest.referenceNumber}
            </Typography>
            <Typography
              color="grey"
              fontSize={12}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              Depart date: {dayjs(departDate).format('MM/DD')}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" gap={1} flexShrink={0}>
          {isLoading && <CircularProgress size={20} />}
          <Box sx={{ alignSelf: 'center' }}>
            <StatusPicker manifest={manifest} />
          </Box>
          <IconButton
            sx={{ height: 'fit-content', alignSelf: 'center' }}
            onClick={() => {
              setShowPrintManifestsModal(true);
            }}
          >
            <PrintRoundedIcon />
          </IconButton>
          <IconButton
            sx={{ height: 'fit-content' }}
            disabled={disableMenuSelection}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <SkinnyHamburger />
          </IconButton>
          <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
            <MenuItem onClick={handleAddManifest}>Add manifest</MenuItem>
            <Divider />
            <MenuItem
              sx={{ color: 'red' }}
              onClick={() => {
                setShowDeleteManifestModal(true);
              }}
            >
              Delete manifest
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
    </>
  );
};

export default ManifestDetailsHeader;
