import { Stack, Typography, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { useRateQuoteContents } from '../../../../quotes/components/quote-form/hooks/use-rate-quote';
import { useFormContext, useWatch } from 'react-hook-form';
import { useCustomerPortalOrderFormContext } from '../../customer-portal-order-form/contexts/customer-portal-order-form-context';
import { StopMethod } from '../../../../orders/components/order-form/forms/stop-type';
import { type CustomerPortalOrderFormValues } from '../../customer-portal-order-form/forms/types';
import { type ThirdPartyUserCompany } from '../../../types';
import { useContactQuery } from '../../../../../generated/graphql';
import { QuoteFormStatusToLabel } from '../constants';
import CustomerPortalQuoteStatusPanel from './customer-portal-quote-status-panel';

type CustomerPortalQuoteRatingPanelProps = {
  readonly contactUuid: string;
  readonly company: ThirdPartyUserCompany;
  readonly setLatestQuoteUuid: (quoteUuid: string | null) => void;
};

const CustomerPortalRatingQuoteFormPanel = ({
  contactUuid,
  company,
  setLatestQuoteUuid,
}: CustomerPortalQuoteRatingPanelProps) => {
  const theme = useTheme();

  const { data: contactData } = useContactQuery({
    variables: {
      uuid: contactUuid,
    },
  });

  const { control } = useFormContext<CustomerPortalOrderFormValues>();
  const { getStopType } = useCustomerPortalOrderFormContext();
  const inboundStopType = getStopType(StopMethod.Inbound);
  const outboundStopType = getStopType(StopMethod.Outbound);

  const serviceUuid = useWatch({ control, name: 'serviceId' });
  const packages = useWatch({ control, name: 'packages' });
  const inboundInformation = useWatch({ control, name: 'inboundInformation' });
  const outboundInformation = useWatch({
    control,
    name: 'outboundInformation',
  });

  const defaultDimFactor =
    contactData?.contact?.__typename === 'CustomerContactEntity' &&
    !isNil(contactData?.contact?.defaultDimFactor)
      ? contactData?.contact?.defaultDimFactor
      : company.configuration?.defaultDimFactor;

  const { rateQuote, price, loading, quoteStatus } = useRateQuoteContents({
    contactUuid,
    company,
    dimFactor: defaultDimFactor ?? 1,
    serviceUuid,
    packages,
    inboundInformation: inboundInformation ?? null,
    outboundInformation: outboundInformation ?? null,
    inboundStopType,
    outboundStopType,
    setLatestQuoteUuid,
  });

  return (
    <Stack width="100%">
      <Stack
        p={2}
        borderBottom={1}
        borderColor={theme.palette.concreteGrey[30]}
      >
        <Typography fontSize={12} color={theme.palette.greyBlackAlpha[60]}>
          Status
        </Typography>
        <Typography
          fontSize={18}
          fontWeight={500}
          color={theme.palette.concreteGrey[80]}
        >
          {QuoteFormStatusToLabel[quoteStatus]}
        </Typography>
      </Stack>
      {!loading && (
        <CustomerPortalQuoteStatusPanel
          price={price ?? null}
          quoteStatus={quoteStatus}
          onRate={async () => {
            await rateQuote();
          }}
        />
      )}
    </Stack>
  );
};

export default CustomerPortalRatingQuoteFormPanel;
