import { useMemo } from 'react';
import {
  type DispatchableDriversQuery,
  type DriverType,
  useDispatchableDriversQuery,
} from '../../generated/graphql';
import { isNil, isEmpty } from 'lodash';

type CompanyDriverOption = {
  isAgent: false;
  uuid: string;
  optionLabel: string;
  phoneNumber: string;
  terminalName: string | null;
  driverType: DriverType | null;
};

type AgentOption = {
  isAgent: true;
  uuid: string;
  optionLabel: string;
  agentName: string;
  driverType: DriverType | null;
};

const getCompanyDriverOptionLabel = (
  driver: DispatchableDriversQuery['dispatchableDrivers']['companyDrivers'][number],
) => {
  const { name } = driver.user;
  const { driverReferenceNumber } = driver;

  if (isNil(driverReferenceNumber) && isNil(name)) {
    return '-';
  }

  return isNil(name)
    ? (driverReferenceNumber ?? '-')
    : `${isNil(driverReferenceNumber) ? '' : `${driverReferenceNumber} - `}${name}`;
};

export type DispatchableDriverOption = CompanyDriverOption | AgentOption;
const useDispatchableDrivers = ({
  terminalUuid,
}: {
  terminalUuid: string | null;
}) => {
  const { data, loading } = useDispatchableDriversQuery({
    variables: {
      getDispatchableDriversInput: {
        terminalUuid,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const driverOptions: DispatchableDriverOption[] | null = useMemo(() => {
    if (isNil(data?.dispatchableDrivers)) {
      return null;
    }

    return [
      ...data.dispatchableDrivers.companyDrivers.map(
        (driver) =>
          ({
            isAgent: false,
            uuid: driver.uuid,
            optionLabel: getCompanyDriverOptionLabel(driver),
            phoneNumber: driver.phoneNumber,
            terminalName: driver.terminal?.name ?? null,
            driverType: driver.driverType ?? null,
          }) satisfies CompanyDriverOption,
      ),
      ...data.dispatchableDrivers.agents.map(
        (agent) =>
          ({
            isAgent: true,
            uuid: agent.defaultDriver.uuid,
            optionLabel: agent.name,
            agentName: agent.name,
            driverType: agent.defaultDriver.driverType ?? null,
          }) satisfies AgentOption,
      ),
    ];
  }, [data]);

  return {
    driverOptions,
    loading,
  };
};

export default useDispatchableDrivers;
