import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Fade,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  ListItemButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useState } from 'react';
import { DocumentsViewerModal } from '../../../../../../../common/components/documents-viewer-modal';
import useDocuments from '../../../../../../../common/react-hooks/use-documents';
import { type DocumentType } from '../../../../../../../generated/graphql';
import { DocumentPreview } from '../../../../../../end-of-day/components/document-preview';
import MultipleDocumentUploadModal, {
  type GetDocumentS3UrlResult,
  type HandleNewDocumentParams,
} from '../../../../../../end-of-day/components/multiple-document-upload-modal';
import { downloadImage } from '../../../../../../end-of-day/end-of-day-utils';
import { type DocViewerDocument } from '../../../../../../end-of-day/types/doc-viewer-document';
import { getDocumentTypeIcon } from './utils';

type DocumentsListItemProps = {
  readonly doc: DocViewerDocument;
  readonly index: number;
  readonly setSelectedDocIndex: (index: number) => void;
  readonly canModifyDocuments: boolean;
  readonly onDeleteDocument: ((uuid: string) => void) | undefined;
};

const DocumentsListItem = ({
  doc,
  index,
  setSelectedDocIndex,
  canModifyDocuments,
  onDeleteDocument,
}: DocumentsListItemProps) => {
  const theme = useTheme();
  const { getDocumentTypeCopy, loading } = useDocuments();
  const [isHovering, setIsHovering] = useState(false);

  const handleOpenModal = () => {
    setSelectedDocIndex(index);
  };

  if (loading) {
    return null;
  }

  return (
    <ListItemButton
      key={doc.uuid}
      sx={{ padding: 0 }}
      onClick={handleOpenModal}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Box color="text.secondary">
            {getDocumentTypeIcon(doc.docType, doc.fileType)}
          </Box>
          <Typography variant="body2">
            {getDocumentTypeCopy({ documentType: doc.docType })}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          {canModifyDocuments && !isNil(onDeleteDocument) && (
            <Fade in={isHovering} timeout={500}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteDocument(doc.uuid);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Fade>
          )}
          <Box
            sx={{
              width: '28px',
              height: '28px',
              overflow: 'hidden',
              borderRadius: 0.25,
              border: `1px solid ${theme.palette.borderColor.main}`,
            }}
          >
            <DocumentPreview doc={doc} pdfScale={0.05} />
          </Box>
        </Stack>
      </Stack>
    </ListItemButton>
  );
};

type DocumentsListProps = {
  readonly docs: DocViewerDocument[];
  readonly onUploadDocument?: (params: HandleNewDocumentParams) => void;
  readonly onDeleteDocument?: (uuid: string) => void;
  readonly getAwsUrl?: (
    fileName: string,
    fileType: string,
  ) => Promise<GetDocumentS3UrlResult | undefined>;
  readonly fetchShipmentDocuments?: () => void;
  readonly showDownloadAll?: boolean;
  readonly canModifyDocuments?: boolean;
  readonly uploadDisabled?: boolean;
  readonly onSave?: (
    documentType: DocumentType,
    existingDocument: DocViewerDocument,
    existingDocumentIndex: number,
  ) => void;
};

const DocumentsList = ({
  docs,
  onUploadDocument,
  onDeleteDocument,
  getAwsUrl,
  fetchShipmentDocuments,
  canModifyDocuments,
  showDownloadAll,
  uploadDisabled,
  onSave,
}: DocumentsListProps) => {
  const theme = useTheme();
  const [selectedDocIndex, setSelectedDocIndex] = useState<number | undefined>(
    undefined,
  );
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

  return (
    <Stack rowGap={2}>
      {!isNil(onUploadDocument) && !isNil(getAwsUrl) && (
        <Grid container>
          {canModifyDocuments === true && (
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
              <Button
                variant="contained"
                size="medium"
                id="upload-dropdown-button"
                aria-haspopup="true"
                disabled={uploadDisabled === true}
                onClick={() => {
                  setShowUploadModal(true);
                }}
              >
                Upload
              </Button>
              {showDownloadAll === true && (
                <Button
                  variant="contained"
                  sx={{ backgroundColor: theme.palette.redColor.main }}
                  onClick={() => {
                    for (const doc of docs) {
                      // Need to treat pre-signed GET URLs differently
                      downloadImage(doc.preSignedGetUrl, doc.fileName);
                    }
                  }}
                >
                  Download all
                </Button>
              )}
            </Box>
          )}
          {isEmpty(docs) && (
            <Grid item xs={12}>
              <Typography mt={1} color="text.secondary" variant="body2">
                No documents uploaded
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
      <Stack spacing={0.5}>
        {docs.map((doc, index) => (
          <DocumentsListItem
            key={doc.uuid}
            doc={doc}
            index={index}
            setSelectedDocIndex={setSelectedDocIndex}
            canModifyDocuments={canModifyDocuments === true}
            onDeleteDocument={onDeleteDocument}
          />
        ))}
      </Stack>
      <DocumentsViewerModal
        documents={docs}
        selectedIndex={selectedDocIndex}
        setSelectedIndex={setSelectedDocIndex}
      />
      {!isNil(onUploadDocument) && !isNil(getAwsUrl) && (
        <MultipleDocumentUploadModal
          key={showUploadModal.toString()}
          isOpen={showUploadModal}
          getAwsUrl={getAwsUrl}
          handleNewDocument={onUploadDocument}
          fetchShipmentDocuments={fetchShipmentDocuments}
          existingDocuments={docs}
          onClose={() => {
            setShowUploadModal(false);
          }}
          onDeleteDocument={onDeleteDocument}
          onSave={onSave}
        />
      )}
    </Stack>
  );
};

export default DocumentsList;
