import { Divider, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import currency from 'currency.js';
import { useState } from 'react';
import {
  type SettlementAdjustmentFragment,
  type DriverSettlementPaymentEntity,
} from '../../../generated/graphql';
import CreateOrUpdateAdjustmentModal from './create-or-update-adjustment-modal';
import SettlementAdjustmentLineItems from './settlement-adjustment-line-items';

const SettlementBillPaymentSummary = ({
  isFinalized,
  settlementBillUuid,
  settlementAdjustments,
  paymentInformation,
}: {
  readonly isFinalized: boolean;
  readonly settlementBillUuid: string;
  readonly settlementAdjustments: SettlementAdjustmentFragment[];
  readonly paymentInformation?: DriverSettlementPaymentEntity;
}) => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      padding={1}
      maxWidth="100%"
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        padding={1}
        minWidth="250px"
        maxWidth="100%"
      >
        <Stack
          direction="row"
          spacing={1}
          padding={1}
          sx={{ width: '100%' }}
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="body1" fontWeight="500">
              Eligible pay
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="body1" fontWeight="500">
              {currency(paymentInformation?.eligiblePay.value ?? 0).format()}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          padding={1}
          sx={{ width: '100%' }}
          justifyContent="space-between"
        >
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ width: '100%' }}
          >
            <SettlementAdjustmentLineItems
              isFinalized={isFinalized}
              settlementAdjustments={settlementAdjustments}
            />
            <Stack
              direction="row"
              sx={{ width: '100%' }}
              justifyContent="space-between"
              marginTop="16px"
            >
              <Stack direction="row" marginRight="16px">
                <Typography variant="body1" fontWeight="500">
                  Net adjustments
                </Typography>
              </Stack>
              <Typography variant="body1" fontWeight="500">
                {currency(
                  paymentInformation?.netAdjustments.value ?? 0,
                ).format()}
                <Divider />
              </Typography>
            </Stack>
            <Tooltip
              arrow
              title="Cannot add new items to a finalized settlement bill"
              disableHoverListener={!isFinalized}
              disableTouchListener={!isFinalized}
            >
              <span style={{ display: 'block' }}>
                <Stack
                  sx={{
                    cursor: isFinalized ? 'default' : 'pointer',
                    color: isFinalized
                      ? theme.palette.concreteGrey[40]
                      : 'inherit',
                    '&:hover': {
                      color: isFinalized
                        ? theme.palette.concreteGrey[40]
                        : theme.palette.signalOrange[50],
                      textDecoration: isFinalized ? 'none' : 'underline',
                    },
                  }}
                  onClick={isFinalized ? undefined : handleOpenModal}
                >
                  <Typography fontSize={12}>Add new</Typography>
                </Stack>
              </span>
            </Tooltip>
            <CreateOrUpdateAdjustmentModal
              modalState={{ state: 'create', settlementBillUuid }}
              isOpen={isModalOpen}
              onClose={handleCloseModal}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          padding={1}
          sx={{ width: '100%' }}
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="body1" fontWeight="500">
              Final pay
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="body1" fontWeight="500">
              {currency(paymentInformation?.finalPay.value ?? 0).format()}
              <Divider sx={{ marginBottom: 0.25 }} />
              <Divider />
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SettlementBillPaymentSummary;
