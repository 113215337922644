
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AcceptCustomerPortalOrderOutput": [
      "AcceptCustomerPortalOrderSuccessOutput",
      "MutationErrorOutput"
    ],
    "AccessorialInterface": [
      "SkidBasedAccessorialEntity",
      "SpecialAccessorialEntity",
      "StandardAccessorialEntity",
      "UnitBasedAccessorialEntity",
      "WaitTimeAccessorialEntity",
      "WeightBasedAccessorialEntity",
      "ZoneBasedAccessorialEntity"
    ],
    "AccessorialUnion": [
      "SkidBasedAccessorialEntity",
      "SpecialAccessorialEntity",
      "StandardAccessorialEntity",
      "UnitBasedAccessorialEntity",
      "WaitTimeAccessorialEntity",
      "WeightBasedAccessorialEntity",
      "ZoneBasedAccessorialEntity"
    ],
    "AccountingReportFiltersOutput": [
      "AccountingReportFilterTypesErrorOutput",
      "AccountingReportFilterTypesSuccessOutput"
    ],
    "AcknowledgeMyStopChangeOutput": [
      "AcknowledgeMyStopChangeSuccessOutput",
      "MutationErrorOutput"
    ],
    "AddLineItemsToSettlementBillsOutput": [
      "AddLineItemsToSettlementBillsSuccessOutput",
      "MutationErrorOutput"
    ],
    "AddOrdersToExistingInvoiceResult": [
      "AddOrdersToExistingInvoiceErrorOutput",
      "AddOrdersToInvoiceSuccessOutput"
    ],
    "AddOrdersToInvoicesForContactOutput": [
      "AddOrdersToInvoicesForContactErrorOutput",
      "AddOrdersToInvoicesForContactSuccessOutput"
    ],
    "AddOrdersToInvoicesOutput": [
      "AddOrdersToInvoicesSuccessOutput",
      "MutationErrorOutput"
    ],
    "AddOrdersToManifestV2Output": [
      "AddOrdersToManifestV2PreviousManifestErrorOutput",
      "AddOrdersToManifestV2SuccessOutput",
      "MutationErrorOutput"
    ],
    "AddOrdersToNewInvoiceResult": [
      "AddOrdersToInvoiceSuccessOutput",
      "AddOrdersToNewInvoiceErrorOutput"
    ],
    "ArchiveOrganizationOutput": [
      "ArchiveOrganizationSuccessOutput",
      "MutationErrorOutput"
    ],
    "ArchiveTariffChainOutput": [
      "ArchiveTariffChainSuccessOutput",
      "MutationErrorOutput"
    ],
    "ArriveAtStopsOutput": [
      "ArriveAtStopsSuccessOutput",
      "MutationErrorOutput"
    ],
    "ChargesUnion": [
      "CustomChargeEntity",
      "FreightChargeEntity"
    ],
    "ChooseAppointmentOptionOutput": [
      "ChooseAppointmentOptionSuccessOutput",
      "MutationErrorOutput"
    ],
    "CompleteStopOutput": [
      "CompleteStopEntity",
      "CompleteStopInvalidInputException"
    ],
    "ContactEntity": [
      "CustomerContactEntity",
      "ThirdPartyContactEntity"
    ],
    "ContactStationCreateOutput": [
      "ContactStationCreateSuccessOutput",
      "MutationErrorOutput"
    ],
    "ContactStationUpdateOutput": [
      "ContactStationUpdateSuccessOutput",
      "MutationErrorOutput"
    ],
    "CreateGeneralLedgerCodeOutput": [
      "CreateGeneralLedgerCodeSuccessOutput",
      "MutationErrorOutput"
    ],
    "CreateLineHaulManifestsOutput": [
      "CreateLineHaulManifestsSuccessOutput",
      "MutationErrorOutput"
    ],
    "CreateOrUpdateCsvOrdersOutput": [
      "CreateOrUpdateCsvOrdersSuccessOutput",
      "MutationErrorOutput"
    ],
    "CreateOrUpdateSettlementAdjustmentOutput": [
      "CreateOrUpdateSettlementAdjustmentSuccessOutput",
      "MutationErrorOutput"
    ],
    "CreateOrderTableReportOutput": [
      "MutationErrorOutput",
      "OrderTableReportDownloadOutput",
      "OrderTableReportEmailToUserOutput"
    ],
    "CreateOrganizationOutput": [
      "CreateOrganizationSuccessOutput",
      "MutationErrorOutput"
    ],
    "CreateRouteFromRouteTemplateOutput": [
      "CreateRouteFromRouteTemplateSuccessOutput",
      "MutationErrorOutput"
    ],
    "CreateSchedulingRegionOutput": [
      "CreateSchedulingRegionSuccessOutput",
      "MutationErrorOutput"
    ],
    "CreateSchedulingZoneOutput": [
      "CreateSchedulingZoneSuccessOutput",
      "MutationErrorOutput"
    ],
    "CreateServiceRegionsOutput": [
      "CreateServiceRegionsSuccessOutput",
      "MutationErrorOutput"
    ],
    "CreateTariffChainOutput": [
      "CreateTariffChainSuccessOutput",
      "MutationErrorOutput"
    ],
    "CreateTerminalLanesOutput": [
      "CreateTerminalLanesSuccessOutput",
      "MutationErrorOutput"
    ],
    "CreateTerminalV2Output": [
      "CreateTerminalSuccessOutput",
      "MutationErrorOutput"
    ],
    "DeleteContactCommentOutput": [
      "DeleteContactCommentSuccessOutput",
      "MutationErrorOutput"
    ],
    "DeleteGeneralLedgerCodeOutput": [
      "DeleteGeneralLedgerCodeSuccessOutput",
      "MutationErrorOutput"
    ],
    "DeleteOrderCommentOutput": [
      "DeleteOrderCommentSuccessOutput",
      "MutationErrorOutput"
    ],
    "DeleteStopAttemptOutput": [
      "DeleteStopAttemptSuccessOutput",
      "MutationErrorOutput"
    ],
    "DeleteTerminalLanesOutput": [
      "DeleteTerminalLanesSuccessOutput",
      "MutationErrorOutput"
    ],
    "DispatchViewCreateOutput": [
      "DispatchViewCreateSuccessOutput",
      "MutationErrorOutput"
    ],
    "DispatchViewUpdateOutput": [
      "DispatchViewUpdateSuccessOutput",
      "MutationErrorOutput"
    ],
    "FinalizeOrdersOutput": [
      "FinalizeOrdersSuccessOutput",
      "MutationErrorOutput"
    ],
    "MakeAppointmentConfirmationDecisionOutput": [
      "AppointmentConfirmationInfoOutput",
      "MutationErrorOutput"
    ],
    "MarkOrderAsRefusedOutput": [
      "MarkOrderAsRefusedSuccessOutput",
      "MutationErrorOutput"
    ],
    "MoveShipmentsBetweenInvoicesOutput": [
      "MoveShipmentsBetweenInvoicesIncompleteOperationOutput",
      "MoveShipmentsBetweenInvoicesSuccessOutput",
      "MutationErrorOutput"
    ],
    "OrderEntity": [
      "StandardOrderEntity"
    ],
    "ParseCsvOrdersOutput": [
      "MutationErrorOutput",
      "ParseCsvOrdersSuccessOutput"
    ],
    "PreviewParseCsvOrdersOutput": [
      "PreviewParseCsvOrdersSuccessOutput",
      "QueryErrorOutput"
    ],
    "PreviewProratedConsolidationV2Output": [
      "PreviewProratedConsolidationV2SuccessOutput",
      "QueryErrorOutput"
    ],
    "ProrateOrdersOutput": [
      "MutationErrorOutput",
      "ProrateOrdersSuccessOutput"
    ],
    "QueueAppointmentSchedulingProposalsOutput": [
      "MutationErrorOutput",
      "QueueAppointmentSchedulingProposalsSuccessOutput"
    ],
    "RateOrderOutput": [
      "RateOrderErrorOutput",
      "RateOrderSuccessOutput"
    ],
    "RejectCustomerPortalOrderOutput": [
      "MutationErrorOutput",
      "RejectCustomerPortalOrderSuccessOutput"
    ],
    "RemoveLineItemsFromSettlementBillOutput": [
      "MutationErrorOutput",
      "RemoveLineItemsFromSettlementBillSuccessOutput"
    ],
    "RemoveOrdersFromManifestV2Output": [
      "MutationErrorOutput",
      "RemoveOrdersFromManifestV2SuccessOutput"
    ],
    "RestoreOrganizationOutput": [
      "MutationErrorOutput",
      "RestoreOrganizationSuccessOutput"
    ],
    "RestoreTariffChainOutput": [
      "MutationErrorOutput",
      "RestoreTariffChainSuccessOutput"
    ],
    "SendAccountingReportsOutput": [
      "MutationErrorOutput",
      "SendAccountingReportsSuccessOutput"
    ],
    "ShipmentEntity": [
      "StandardShipmentEntity"
    ],
    "StopEntity": [
      "StandardStopEntity"
    ],
    "StopsListElement": [
      "CachedStopEntity",
      "RecurringRunHeaderEntity"
    ],
    "TariffZoneGroupArchiveOutput": [
      "MutationErrorOutput",
      "TariffZoneGroupArchiveSuccessOutput"
    ],
    "TariffZoneGroupCreateOutput": [
      "MutationErrorOutput",
      "TariffZoneGroupCreateSuccessOutput"
    ],
    "TariffZoneGroupRestoreOutput": [
      "MutationErrorOutput",
      "TariffZoneGroupRestoreSuccessOutput"
    ],
    "TariffZoneGroupUpdateOutput": [
      "MutationErrorOutput",
      "TariffZoneGroupUpdateSuccessOutput"
    ],
    "UpdateContactCommentOutput": [
      "MutationErrorOutput",
      "UpdateContactCommentSuccessOutput"
    ],
    "UpdateContactEmailsOutput": [
      "MutationErrorOutput",
      "UpdateContactEmailsSuccessOutput"
    ],
    "UpdateDefaultTariffChainsForCompany": [
      "MutationErrorOutput",
      "UpdateDefaultTariffChainsForCompanySuccessOutput"
    ],
    "UpdateDispatchTableColorsOutput": [
      "MutationErrorOutput",
      "UpdateDispatchTableColorsSuccessOutput"
    ],
    "UpdateGeneralLedgerCodeOutput": [
      "MutationErrorOutput",
      "UpdateGeneralLedgerCodeSuccessOutput"
    ],
    "UpdateGeneralLedgerConfigurationOutput": [
      "MutationErrorOutput",
      "UpdateGeneralLedgerConfigurationSuccessOutput"
    ],
    "UpdateManifestsStatusOutput": [
      "MutationErrorOutput",
      "UpdateManifestsStatusSuccessOutput"
    ],
    "UpdateOrderEventNotificationSubscriptionsOutput": [
      "MutationErrorOutput",
      "UpdateOrderEventNotificationSubscriptionsSuccessOutput"
    ],
    "UpdateOrganizationOutput": [
      "MutationErrorOutput",
      "UpdateOrganizationSuccessOutput"
    ],
    "UpdateSchedulingRegionOutput": [
      "MutationErrorOutput",
      "UpdateSchedulingRegionSuccessOutput"
    ],
    "UpdateSchedulingZoneOutput": [
      "MutationErrorOutput",
      "UpdateSchedulingZoneSuccessOutput"
    ],
    "UpdateServiceRegionOutput": [
      "MutationErrorOutput",
      "UpdateServiceRegionSuccessOutput"
    ],
    "UpdateTariffChainOutput": [
      "MutationErrorOutput",
      "UpdateTariffChainSuccessOutput"
    ],
    "UpdateTerminalV2Output": [
      "MutationErrorOutput",
      "UpdateTerminalSuccessOutput"
    ]
  }
};
      export default result;
    