import { Button, TableCell, TableRow, useTheme } from '@mui/material';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useDrivers from '../../../../../../../../common/react-hooks/use-drivers';
import AddDriverForDriverSettlementModal from '../../../../../standard/components/driver-settlement/add-driver-for-driver-settlement-modal';
import { StyledTableRow } from '../../../../components/common/styled-table-row';
import { useOrderFormEditAccess } from '../../../../contexts/order-form-edit-access-context';
import {
  type IndependentSettlementBillLineItemValues,
  type OrderFormValues,
} from '../../../../forms/types';
import { useShouldShowStopChargesSection } from '../../../../hooks/use-expected-order-components';
import { useOrderFormSettlementActions } from '../../../../hooks/use-order-form-settlement-actions';
import { StopSettlementTableRow } from './stop-settlement-table-row';

const StopSettlementTable = ({ idx }: { readonly idx: number }) => {
  const { showSection: showStopChargesSection } =
    useShouldShowStopChargesSection({
      stopIdx: idx,
    });

  const {
    onAddIndependentSettlementBillLineItem,
    onRemoveIndependentSettlementBillLineItem,
  } = useOrderFormSettlementActions({ stopIdx: idx });

  const theme = useTheme();
  const { getDriverName } = useDrivers();
  const { control, setValue, getValues } = useFormContext<OrderFormValues>();

  const [showAddDriverModal, setShowAddDriverModal] = useState<boolean>(false);

  const stopType = useWatch({
    control,
    name: `stops.${idx}.stopType`,
  });
  const settlementBillLineItems: IndependentSettlementBillLineItemValues[] =
    useWatch({
      control,
      name: `stops.${idx}.settlementBillLineItems`,
    }) ?? [];

  const { disabledIfNoAccess } = useOrderFormEditAccess();

  if (!showStopChargesSection) {
    return null;
  }

  const onAddSettlementBillLineItem = (driverUuid: string) => {
    const newSettlementBillLineItems = onAddIndependentSettlementBillLineItem({
      driverUuid,
    });
    setShowAddDriverModal(false);
  };

  return (
    <>
      <AddDriverForDriverSettlementModal
        hideDriversIneligibleForSettlement
        isOpen={showAddDriverModal}
        setIsOpen={setShowAddDriverModal}
        disabledDriverUuids={settlementBillLineItems.map((li) => li.driverUuid)}
        onConfirm={onAddSettlementBillLineItem}
      />
      <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
        <TableCell colSpan={5}>{stopType?.toString().toUpperCase()}</TableCell>
      </TableRow>
      <StyledTableRow>
        <TableCell />
        <TableCell sx={{ fontWeight: 500 }}>Driver</TableCell>
        <TableCell sx={{ fontWeight: 500 }}>Rating mode</TableCell>
        <TableCell sx={{ fontWeight: 500, textAlign: 'right' }}>
          Total
        </TableCell>
        <TableCell />
      </StyledTableRow>
      {settlementBillLineItems?.map((lineItem, lineItemIdx) => (
        <StopSettlementTableRow
          key={lineItem.id}
          lineItem={lineItem}
          lineItemIdx={lineItemIdx}
          stopIdx={idx}
          disabledIfNoAccess={disabledIfNoAccess}
          driverName={getDriverName(lineItem.driverUuid)}
          onDelete={() =>
            onRemoveIndependentSettlementBillLineItem({
              driverUuid: lineItem.driverUuid,
            })
          }
        />
      ))}
      <StyledTableRow>
        <TableCell colSpan={5}>
          <Button
            onClick={() => {
              setShowAddDriverModal(true);
            }}
          >
            + Add driver
          </Button>
        </TableCell>
      </StyledTableRow>
    </>
  );
};

export default StopSettlementTable;
