import { isNil } from 'lodash';
import { z } from 'zod';
import {
  DocumentType,
  HazmatClass,
  PackageType,
} from '../../../../../generated/graphql';
import {
  dayjsDate,
  zNonEmptyString,
} from '../../../../orders/components/order-form/forms/zod-utils';
import { CustomerPortalInformationLocationType } from './enums';

// We don't use strict() on any of these schemas: if we accidentally have an
// unexpected field, there's likely no way that the user can fix it themselves.
// The backend should prevent any unexpected fields / bad states from being saved.

export const ADDRESS_SCHEMA = z.object({
  name: zNonEmptyString({ required_error: 'Missing address name' }),
  line1: zNonEmptyString({ required_error: 'Missing address line 1' }),
  line2: z.string().optional(),
  city: zNonEmptyString({ required_error: 'Missing city' }),
  state: zNonEmptyString({ required_error: 'Missing state' }),
  zipcode: zNonEmptyString({ required_error: 'Missing zipcode' }),
  // The UI doesn't expose a way to edit the country, so we default to ''
  // to prevent unfixable frontend validation errors.
  country: z.string().trim().default(''),
});

const COMMON_INFORMATION_SCHEMA = z.object({
  serviceDate: dayjsDate('Missing service date').optional(),
  deadlineDate: dayjsDate('Missing deadline date').optional(),
  specialInstructions: z.string().trim().optional(),
});

const INFORMATION_WITH_ADDRESS_SCHEMA = COMMON_INFORMATION_SCHEMA.merge(
  z.object({
    locationType: z.literal(CustomerPortalInformationLocationType.Address),
    address: ADDRESS_SCHEMA,
  }),
);

const INFORMATION_WITH_TERMINAL_ID_SCHEMA = COMMON_INFORMATION_SCHEMA.merge(
  z.object({
    locationType: z.literal(CustomerPortalInformationLocationType.Terminal),
    terminalId: z
      .string({ required_error: 'Missing terminal' })
      .uuid('Invalid terminal'),
  }),
);

export const INBOUND_INFORMATION_SCHEMA = z.discriminatedUnion('locationType', [
  INFORMATION_WITH_ADDRESS_SCHEMA,
  INFORMATION_WITH_TERMINAL_ID_SCHEMA,
]);

export const OUTBOUND_INFORMATION_SCHEMA = z.discriminatedUnion(
  'locationType',
  [INFORMATION_WITH_ADDRESS_SCHEMA, INFORMATION_WITH_TERMINAL_ID_SCHEMA],
);

export const PACKAGE_SCHEMA = z.object({
  description: z.string().optional(),
  quantity: z
    .number({ required_error: 'Missing quantity' })
    .int()
    .min(0, 'Quantity must not be negative'),
  weight: z
    .number({ required_error: 'Missing weight' })
    .min(0, 'Weight must not be negative'),
  length: z
    .number({ required_error: 'Missing length' })
    .min(0, 'Length must not be negative'),
  width: z
    .number({ required_error: 'Missing width' })
    .min(0, 'Width must not be negative'),
  height: z
    .number({ required_error: 'Missing height' })
    .min(0, 'Height must not be negative'),
  packageType: z.nativeEnum(PackageType).optional(),
});

const CREATE_DOCUMENT_SCHEMA = z.object({
  s3Url: z
    .string()
    .url()
    .describe('A pre-signed GET URL for the uploaded file'),
  // TODO: Constrain this to only be the document types that are allowed for customer portal (for now).
  type: z.nativeEnum(DocumentType, {
    required_error: 'Missing document type',
  }),
  fileName: z.string().describe('The original name of the file'),
  fileType: z.string().describe('The MIME type of the file'),
});

const IN_BOND_INFORMATION_SCHEMA = z.object({
  itTeNumber: z.string().trim().optional(),
});

const HAZMAT_INFORMATION_SCHEMA = z.object({
  hazmatClass: z.nativeEnum(HazmatClass, {
    invalid_type_error: 'Missing hazmat class',
    required_error: 'Missing hazmat class',
  }),
  hazmatDescription: z.string().trim().optional(),
});

// clientId is not included in this schema because it's completely derived
// from contactUuid
// Integration tests for form submission are in use-load-customer-portal-order-form.spec.ts
export const CUSTOMER_PORTAL_ORDER_SCHEMA = z
  .object({
    hawb: z
      .string()
      .trim()
      // If the user enters then clears the field, we don't want to save the empty string
      .transform((val) => (val === '' ? undefined : val))
      .optional(),
    mawb: z
      .string()
      .trim()
      .transform((val) => (val === '' ? undefined : val))
      .optional(),
    serviceId: z
      .string({ required_error: 'Missing service level' })
      .uuid('Missing service level'),
    packages: z.array(PACKAGE_SCHEMA).default([]),
    documents: z.array(CREATE_DOCUMENT_SCHEMA).default([]),
    inboundInformation: INBOUND_INFORMATION_SCHEMA.optional(),
    outboundInformation: OUTBOUND_INFORMATION_SCHEMA.optional(),
    inBondInformation: IN_BOND_INFORMATION_SCHEMA.optional(),
    hazmatInformation: HAZMAT_INFORMATION_SCHEMA.optional(),
  })
  .refine((data) => {
    if (isNil(data.inboundInformation) && isNil(data.outboundInformation)) {
      return false;
    }
    return true;
  }, 'At least one inbound or outbound stop must be provided');
