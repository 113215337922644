import { Button, Stack, useTheme } from '@mui/material';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import AgentTenderedJobStatusBanner from '../../../../agents/agent-tendered-job-status-banner';
import { type LineHaulManifest } from '../utils';
import LoadSummary from './load-summary';
import ManifestDriver from './manifest-driver';
import ManifestVehicle from './manifest-vehicle';
import { AgentTenderedJobStatus } from '../../../../agents/utils';
import { getAgentTenderedJobStatus } from '../../../../agents/utils';
import SendJobButton from '../../../../agents/send-job-button';
import { isNotNil } from 'shared/optional';
import { useState } from 'react';

type ManifestDetailsBodyProps = {
  readonly manifest: LineHaulManifest;
};

const AGENT_STATUSES_TO_SHOW_IN_BANNER = new Set([
  AgentTenderedJobStatus.AWAITING_CONFIRMATION,
  AgentTenderedJobStatus.CONFIRMED,
  AgentTenderedJobStatus.COMPLETED,
]);

const ManifestDetailsBody = ({ manifest }: ManifestDetailsBodyProps) => {
  const ffShowAgentStatusBanner = useFeatureFlag(
    FeatureFlag.FF_AGENTS_CONFIGURATION_AND_TENDERING,
  );

  // TO-DO: remove temporary state to show the confirmation banner and replace with backend data
  const [sentToAgentAt, setSentToAgentAt] = useState<Date | null>(
    manifest.sentToAgentAt as Date | null,
  );

  const agentTenderedJobStatus = getAgentTenderedJobStatus({
    agentName: manifest.driver?.agent?.name,
    sentToAgentAt,
    confirmedByAgentAt: manifest.confirmedByAgentAt as Date | null,
    completedAt: manifest.completedAt as Date | null,
    ffShowAgentStatusBanner,
  });
  return (
    <>
      {ffShowAgentStatusBanner &&
        isNotNil(manifest.driver?.agent) &&
        AGENT_STATUSES_TO_SHOW_IN_BANNER.has(agentTenderedJobStatus) && (
          <AgentTenderedJobStatusBanner
            status={agentTenderedJobStatus}
            agentName={manifest.driver.agent.name}
          />
        )}
      <Stack p={1} gap={2}>
        <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap">
          <ManifestDriver manifest={manifest} />
          <ManifestVehicle manifest={manifest} />
          {ffShowAgentStatusBanner &&
            agentTenderedJobStatus ===
              AgentTenderedJobStatus.ASSIGNED_NOT_SENT && (
              // TO-DO: Add send job functionality
              // This currently just sets the sentToAgentAt date to the current date to show the confirmation banner
              <SendJobButton
                onClick={() => {
                  setSentToAgentAt(new Date());
                }}
              />
            )}
        </Stack>
        <LoadSummary manifest={manifest} />
      </Stack>
    </>
  );
};

export default ManifestDetailsBody;
