import dayjs, { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { exhaustive } from 'shared/switch';
import DateFilterEditor from '../../../../../common/filters/editors/date-filter-editor';
import {
  type FilterCondition,
  type FilterConfig,
  type FilterGroup,
  type NullableFilterCondition,
} from '../../../../../common/filters/types-v2';
import {
  LineHaulManifestStatus,
  type FindLineHaulManifestsV2FiltersInput,
  type LineHaulManifestFilters,
} from '../../../../../generated/graphql';
import useLineHaulDispatchStore from '../../../store/line-haul-dispatch-store';
import { isFilterCondition } from '../../../../../common/filters/utils-v2';
import { isFilterGroup } from '../../../../../common/filters/utils-v2';
import FilterPills from '../../../../../common/filters/filter-pills';
import { useMemo } from 'react';
import useTerminals from '../../../../../common/react-hooks/use-terminals';
import SingleSelectFilterEditorWithOptions from '../../../../../common/filters/editors/single-select-filter-editor';
import useDrivers from '../../../../../common/react-hooks/use-drivers';

export type ManifestFilterField =
  | 'departDate'
  | 'startTerminalUuid'
  | 'endTerminalUuid'
  | 'driverUuid'
  | 'status';

export type ManifestFilterOperatorMap = {
  departDate: {
    eq: Dayjs;
  };
  startTerminalUuid: {
    eq: string;
  };
  endTerminalUuid: {
    eq: string;
  };
  driverUuid: {
    eq: string;
  };
  status: {
    eq: LineHaulManifestStatus;
  };
};

export type ManifestFilterType = keyof ManifestFilterOperatorMap;

export const MANIFEST_FILTER_FIELD_TO_TYPE_MAP = {
  departDate: 'departDate',
  startTerminalUuid: 'startTerminalUuid',
  endTerminalUuid: 'endTerminalUuid',
  driverUuid: 'driverUuid',
  status: 'status',
} as const;
export type ManifestFilterFieldMap = typeof MANIFEST_FILTER_FIELD_TO_TYPE_MAP;

export type ManifestFilters = FilterGroup<
  ManifestFilterField,
  ManifestFilterType,
  ManifestFilterOperatorMap,
  ManifestFilterFieldMap
>;

type NullableManifestFiltersCondition = NullableFilterCondition<
  ManifestFilterField,
  ManifestFilterType,
  ManifestFilterOperatorMap,
  ManifestFilterFieldMap
>;

type ManifestFiltersConfig = FilterConfig<
  ManifestFilterField,
  ManifestFilterType,
  ManifestFilterOperatorMap,
  ManifestFilterFieldMap
>;

const MANIFEST_FILTER_OPERATOR_OPTIONS: ManifestFiltersConfig['filterOperatorOptions'] =
  {
    departDate: [
      {
        value: 'eq',
        label: 'Is',
      },
    ],
    startTerminalUuid: [
      {
        value: 'eq',
        label: 'Is',
      },
    ],
    endTerminalUuid: [
      {
        value: 'eq',
        label: 'Is',
      },
    ],
    driverUuid: [
      {
        value: 'eq',
        label: 'Is',
      },
    ],
    status: [
      {
        value: 'eq',
        label: 'Is',
      },
    ],
  };

const defaultEmptyFilterCondition: ManifestFiltersConfig['defaultEmptyFilterCondition'] =
  {
    field: null,
    operator: null,
    value: null,
  };

const MANIFEST_FILTER_FIELD_LABELS = {
  departDate: 'Depart date',
  startTerminalUuid: 'Start terminal',
  endTerminalUuid: 'End terminal',
  driverUuid: 'Driver',
  status: 'Status',
} as const;

const MANIFEST_FIELD_OPERATOR_LABELS = {
  eq: 'Is',
} as const;

const MANIFEST_FILTER_FIELD_OPTIONS: ManifestFiltersConfig['filterFieldOptions'] =
  Object.entries(MANIFEST_FILTER_FIELD_LABELS).map(([field, label]) => ({
    label,
    value: field as ManifestFilterField,
  }));

const lineHaulManifestFiltersInputToManifestFilters = (
  lineHaulManifestFilters: LineHaulManifestFilters,
): ManifestFilters => {
  const conditions: Array<
    FilterCondition<
      ManifestFilterField,
      ManifestFilterType,
      ManifestFilterOperatorMap,
      ManifestFilterFieldMap
    >
  > = [];

  if (!isNil(lineHaulManifestFilters.and)) {
    for (const filter of lineHaulManifestFilters.and) {
      if (!isNil(filter.fields?.departDate?.eq)) {
        conditions.push({
          field: 'departDate',
          operator: 'eq',
          value: dayjs(filter.fields.departDate.eq as string),
        });
      }
      if (!isNil(filter.fields?.startTerminalUuidFilter?.eq)) {
        conditions.push({
          field: 'startTerminalUuid',
          operator: 'eq',
          value: filter.fields.startTerminalUuidFilter.eq,
        });
      }
      if (!isNil(filter.fields?.endTerminalUuidFilter?.eq)) {
        conditions.push({
          field: 'endTerminalUuid',
          operator: 'eq',
          value: filter.fields.endTerminalUuidFilter.eq,
        });
      }
      if (!isNil(filter.fields?.driverUuidFilter?.eq)) {
        conditions.push({
          field: 'driverUuid',
          operator: 'eq',
          value: filter.fields.driverUuidFilter.eq,
        });
      }
      if (!isNil(filter.fields?.statusFilter?.eq)) {
        conditions.push({
          field: 'status',
          operator: 'eq',
          value: filter.fields.statusFilter.eq,
        });
      }
    }
  }

  return {
    operator: 'AND',
    conditions: [
      {
        operator: 'AND',
        conditions,
      },
    ],
  };
};

const manifestFiltersToLineHaulManifestsV2FiltersInput = (
  manifestFilters: ManifestFilters,
): FindLineHaulManifestsV2FiltersInput => {
  const topLevelFilterGroup = manifestFilters.conditions[0];
  if (isNil(topLevelFilterGroup) || !isFilterGroup(topLevelFilterGroup)) {
    return {
      and: [],
    };
  }

  const conditions = topLevelFilterGroup.conditions.filter((f) =>
    isFilterCondition(f),
  );

  const findLineHaulManifestsV2FiltersInput: FindLineHaulManifestsV2FiltersInput & {
    and: LineHaulManifestFilters[];
  } = {
    and: [],
  };

  for (const condition of conditions) {
    const { field } = condition;
    switch (field) {
      case 'departDate': {
        findLineHaulManifestsV2FiltersInput.and.push({
          fields: {
            departDate: {
              eq: condition.value,
            },
          },
        });
        break;
      }
      case 'startTerminalUuid': {
        findLineHaulManifestsV2FiltersInput.and.push({
          fields: {
            startTerminalUuidFilter: {
              eq: condition.value,
            },
          },
        });
        break;
      }
      case 'endTerminalUuid': {
        findLineHaulManifestsV2FiltersInput.and.push({
          fields: {
            endTerminalUuidFilter: {
              eq: condition.value,
            },
          },
        });
        break;
      }
      case 'driverUuid': {
        findLineHaulManifestsV2FiltersInput.and.push({
          fields: {
            driverUuidFilter: {
              eq: condition.value,
            },
          },
        });
        break;
      }
      case 'status': {
        findLineHaulManifestsV2FiltersInput.and.push({
          fields: {
            statusFilter: { eq: condition.value },
          },
        });
        break;
      }
      default: {
        exhaustive(field);
      }
    }
  }
  return findLineHaulManifestsV2FiltersInput;
};

const hasDepartDateFilter = (
  filters: FindLineHaulManifestsV2FiltersInput,
): boolean => {
  return filters.and?.some((filter) => filter.fields?.departDate?.eq) ?? false;
};

type ManifestLaneGroupsFiltersProps = {
  readonly filters: FindLineHaulManifestsV2FiltersInput;
  readonly onFilterConditionChange: (
    filters: FindLineHaulManifestsV2FiltersInput,
  ) => void;
};

const ManifestLaneGroupsFilters = ({
  filters,
  onFilterConditionChange,
}: ManifestLaneGroupsFiltersProps) => {
  const terminals = useTerminals({});
  const drivers = useDrivers();
  const setFilters = useLineHaulDispatchStore((state) => state.setFilters);
  const setSnackbarErrorMessage = useLineHaulDispatchStore(
    (state) => state.setSnackbarErrorMessage,
  );

  const manifestFilters =
    lineHaulManifestFiltersInputToManifestFilters(filters);

  const setFiltersCallback = (filters: ManifestFilters) => {
    const findLineHaulManifestsV2FiltersInput =
      manifestFiltersToLineHaulManifestsV2FiltersInput(filters);

    if (hasDepartDateFilter(findLineHaulManifestsV2FiltersInput)) {
      setFilters(findLineHaulManifestsV2FiltersInput);
      onFilterConditionChange(findLineHaulManifestsV2FiltersInput);
    } else {
      setSnackbarErrorMessage('Depart date filter is required');
    }
  };

  const terminalOptions = useMemo(() => {
    return terminals.terminals.map((t) => ({
      label: t.name,
      value: t.uuid,
    }));
  }, [terminals]);

  const driverOptions = useMemo(() => {
    return drivers.drivers.map((d) => ({
      label: `${d.firstName} ${d.lastName}`,
      value: d.uuid,
    }));
  }, [drivers]);

  const statusOptions = useMemo(() => {
    return Object.values(LineHaulManifestStatus).map((s) => ({
      label: s,
      value: s,
    }));
  }, []);

  const filterEditorComponents: ManifestFiltersConfig['filterEditorComponents'] =
    {
      departDate: {
        eq: DateFilterEditor,
      },
      startTerminalUuid: {
        eq: SingleSelectFilterEditorWithOptions<string>(terminalOptions),
      },
      endTerminalUuid: {
        eq: SingleSelectFilterEditorWithOptions<string>(terminalOptions),
      },
      driverUuid: {
        eq: SingleSelectFilterEditorWithOptions<string>(driverOptions),
      },
      status: {
        eq: SingleSelectFilterEditorWithOptions<LineHaulManifestStatus>(
          statusOptions,
        ),
      },
    };

  const renderManifestFilterValue = (
    filterCondition: NullableManifestFiltersCondition,
  ): string | null => {
    const { field, operator, value } = filterCondition;
    if (isNil(field) || isNil(operator) || isNil(value)) {
      return null;
    }

    switch (field) {
      case 'departDate': {
        return value.format('MM/DD/YYYY');
      }
      case 'startTerminalUuid': {
        return terminalOptions.find((t) => t.value === value)?.label ?? null;
      }
      case 'endTerminalUuid': {
        return terminalOptions.find((t) => t.value === value)?.label ?? null;
      }
      case 'driverUuid': {
        return driverOptions.find((d) => d.value === value)?.label ?? null;
      }
      case 'status': {
        return statusOptions.find((s) => s.value === value)?.label ?? null;
      }
      default: {
        return exhaustive(field);
      }
    }
  };

  const renderFilterParts: ManifestFiltersConfig['renderFilterParts'] = (
    filterCondition,
  ) => {
    const { field, operator, value } = filterCondition;
    if (isNil(field) || isNil(operator) || isNil(value)) {
      return null;
    }

    const renderedField = MANIFEST_FILTER_FIELD_LABELS[field];
    const renderedOperator = MANIFEST_FIELD_OPERATOR_LABELS[operator];
    const renderedValue = renderManifestFilterValue(filterCondition);

    if (isNil(renderedValue)) {
      return null;
    }

    return {
      field: renderedField,
      operator: renderedOperator,
      value: renderedValue,
    };
  };

  const config: ManifestFiltersConfig = {
    entityName: 'manifest',
    supportsNesting: false,
    filterFieldOptions: MANIFEST_FILTER_FIELD_OPTIONS,
    filterOperatorOptions: MANIFEST_FILTER_OPERATOR_OPTIONS,
    filterFieldToTypeMap: MANIFEST_FILTER_FIELD_TO_TYPE_MAP,
    filterEditorComponents,
    defaultEmptyFilterCondition,
    renderFilterParts,
  };

  return (
    <FilterPills<
      ManifestFilterField,
      ManifestFilterType,
      ManifestFilterOperatorMap,
      ManifestFilterFieldMap
    >
      wrap
      filters={manifestFilters}
      setFilters={setFiltersCallback}
      filterConfig={config}
    />
  );
};

export default ManifestLaneGroupsFilters;
