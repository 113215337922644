import CheckIcon from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { IconButton, Tooltip } from '@mui/material';
import {
  type ColDef,
  type ICellRendererParams,
  type ValueGetterParams,
} from 'ag-grid-community';
import { isEmpty, isNil, values } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { exhaustive } from 'shared/switch';
import { ServiceLevelAutocomplete } from '../../../../../common/components/service-level-autocomplete';
import StopTypeChipNew from '../../../../../common/components/stop-type-chip-new';
import StopTypesForOrdersTableChipsNew from '../../../../../common/components/stop-types-for-orders-table-chips-new';
import {
  type FilterViewPage,
  type FormattedOrderFragment,
  OrderTableField,
  type StopType,
  useRateOrderByUuidLazyQuery,
  useUpdateStandardOrderMutation,
} from '../../../../../generated/graphql';
import useGlobalStore from '../../../../../layouts/dashboard/global-store';
import OpenOrderButton from '../../../../ag-grid/open-order-button';
import { useNewTableFunctionsFeatureFlag } from '../../../../ag-grid/use-new-table-functions-feature-flag';
import { singleLineTruncatedCellProps } from '../../../../ag-grid/utils';
import { PaperworkCompleteCellRenderer } from '../../../../invoices/components/billing-review/paperwork-complete-cell-renderer';
import { useOrdersTableFilterColumns } from '../../../hooks/use-orders-table-filter-columns';
import { PackagesCellComponent } from '../../ag-grid-cell-components/packages-cell-component';
import TagsCellNew from '../../ag-grid-cell-components/tags-cell-component-new';
import { ExcludeFromHiding } from '../../enums/order-filters';
import Paperwork from '../../paperwork';
import { ChargesComponent } from '../../standard/components/charges-component';
import PhotosComponentNew from '../../standard/components/photos-component-new';
import { getOrderTableFieldValue } from '../../utils';
import LineHaulManifestsCell from '../../ag-grid-cell-components/line-haul-manifests-cell/line-haul-manifests-cell-component';

type UseOrdersTableColumnsProps = {
  pageType: FilterViewPage;
  isEditable?: boolean;
};

export const useOrdersTableColumns = ({
  pageType,
  isEditable = false,
}: UseOrdersTableColumnsProps): Array<ColDef<FormattedOrderFragment>> => {
  const [updateStandardOrder] = useUpdateStandardOrderMutation();
  const { ffEnableNewTableFunctions } =
    useNewTableFunctionsFeatureFlag(pageType);

  const mapOrderTableFieldToOrderTableColumnDefinition = useCallback(
    ({
      orderTableField,
    }: {
      orderTableField: OrderTableField;
    }): ColDef<FormattedOrderFragment> => {
      switch (orderTableField) {
        case OrderTableField.InboundAddressName: {
          return {
            field: OrderTableField.InboundAddressName,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundAddressName,
              });
            },
          };
        }
        case OrderTableField.OutboundAddressName: {
          return {
            field: OrderTableField.OutboundAddressName,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundAddressName,
              });
            },
          };
        }
        case OrderTableField.InboundAppointment: {
          return {
            field: OrderTableField.InboundAppointment,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundAppointment,
              });
            },
          };
        }
        case OrderTableField.InboundAppointmentDate: {
          return {
            field: OrderTableField.InboundAppointmentDate,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundAppointmentDate,
              });
            },
          };
        }
        case OrderTableField.InboundAppointmentTime: {
          return {
            field: OrderTableField.InboundAppointmentTime,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundAppointmentTime,
              });
            },
          };
        }
        case OrderTableField.OutboundAppointment: {
          return {
            field: OrderTableField.OutboundAppointment,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundAppointment,
              });
            },
          };
        }
        case OrderTableField.OutboundAppointmentDate: {
          return {
            field: OrderTableField.OutboundAppointmentDate,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundAppointmentDate,
              });
            },
          };
        }
        case OrderTableField.OutboundAppointmentTime: {
          return {
            field: OrderTableField.OutboundAppointmentTime,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundAppointmentTime,
              });
            },
          };
        }
        case OrderTableField.InboundAppointmentConfirmed: {
          return {
            field: OrderTableField.InboundAppointmentConfirmed,
            cellRenderer: (
              params: ICellRendererParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              const isSpecial = getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundAppointmentConfirmed,
              });
              return isEmpty(isSpecial) ? null : <CheckIcon />;
            },
          };
        }
        case OrderTableField.OutboundAppointmentConfirmed: {
          return {
            field: OrderTableField.OutboundAppointmentConfirmed,
            cellRenderer: (
              params: ICellRendererParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              const isSpecial = getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundAppointmentConfirmed,
              });
              return isEmpty(isSpecial) ? null : <CheckIcon />;
            },
          };
        }
        case OrderTableField.InboundAddressType: {
          return {
            field: OrderTableField.InboundAddressType,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundAddressType,
              });
            },
          };
        }
        case OrderTableField.OutboundAddressType: {
          return {
            field: OrderTableField.OutboundAddressType,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundAddressType,
              });
            },
          };
        }
        case OrderTableField.InboundContactName: {
          return {
            field: OrderTableField.InboundContactName,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundContactName,
              });
            },
          };
        }
        case OrderTableField.OutboundContactName: {
          return {
            field: OrderTableField.OutboundContactName,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundContactName,
              });
            },
          };
        }
        case OrderTableField.Name: {
          return {
            field: OrderTableField.Name,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.Name,
              });
            },
          };
        }
        case OrderTableField.DriverName: {
          return {
            field: OrderTableField.DriverName,
            width: 130,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.DriverName,
              });
            },
          };
        }
        case OrderTableField.DriverSettlement: {
          return {
            field: OrderTableField.DriverSettlement,
            width: 130,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.DriverSettlement,
              });
            },
          };
        }
        case OrderTableField.InboundDriverName: {
          return {
            field: OrderTableField.InboundDriverName,
            width: 130,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundDriverName,
              });
            },
          };
        }
        case OrderTableField.OutboundDriverName: {
          return {
            field: OrderTableField.OutboundDriverName,
            width: 130,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundDriverName,
              });
            },
          };
        }
        case OrderTableField.Source: {
          return {
            field: OrderTableField.Source,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.Source,
              });
            },
          };
        }
        case OrderTableField.OrderType: {
          return {
            field: OrderTableField.OrderType,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OrderType,
              });
            },
          };
        }
        case OrderTableField.BillOfLadingNumber: {
          return {
            field: OrderTableField.BillOfLadingNumber,
            resizable: true,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.BillOfLadingNumber,
              });
            },
          };
        }
        case OrderTableField.Status: {
          return {
            field: OrderTableField.Status,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.Status,
              });
            },
          };
        }
        case OrderTableField.BillingReviewStatus: {
          return {
            field: OrderTableField.BillingReviewStatus,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.BillingReviewStatus,
              });
            },
            ...singleLineTruncatedCellProps,
          };
        }
        case OrderTableField.ShipmentType: {
          return {
            field: OrderTableField.ShipmentType,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.ShipmentType,
              });
            },
          };
        }
        case OrderTableField.NextAddress: {
          return {
            field: OrderTableField.NextAddress,
            width: 150,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.NextAddress,
              });
            },
          };
        }
        case OrderTableField.PrimaryAddress: {
          return {
            field: OrderTableField.PrimaryAddress,
            width: 200,
            sortable: true,
            valueGetter: (params: ValueGetterParams) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.PrimaryAddress,
              });
            },
          };
        }
        case OrderTableField.InboundCityName: {
          return {
            field: OrderTableField.InboundCityName,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundCityName,
              });
            },
          };
        }
        case OrderTableField.OutboundCityName: {
          return {
            field: OrderTableField.OutboundCityName,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundCityName,
              });
            },
          };
        }
        case OrderTableField.CityName: {
          return {
            field: OrderTableField.CityName,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.CityName,
              });
            },
          };
        }
        case OrderTableField.NextConsignee: {
          return {
            field: OrderTableField.NextConsignee,
            width: 130,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.NextConsignee,
              });
            },
          };
        }
        case OrderTableField.PrimaryConsignee: {
          return {
            field: OrderTableField.PrimaryConsignee,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.PrimaryConsignee,
              });
            },
          };
        }
        case OrderTableField.Zipcode: {
          return {
            field: OrderTableField.Zipcode,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.Zipcode,
              });
            },
          };
        }
        case OrderTableField.ContactInformation: {
          return {
            field: OrderTableField.ContactInformation,
            width: 120,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.ContactInformation,
              });
            },
          };
        }
        case OrderTableField.BusinessDivision: {
          return {
            field: OrderTableField.BusinessDivision,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.BusinessDivision,
              });
            },
          };
        }
        case OrderTableField.ReceivedAt: {
          return {
            field: OrderTableField.ReceivedAt,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.ReceivedAt,
              });
            },
          };
        }
        case OrderTableField.NextDeadline: {
          return {
            field: OrderTableField.NextDeadline,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.NextDeadline,
              });
            },
          };
        }
        case OrderTableField.RoutingLocation: {
          return {
            field: OrderTableField.RoutingLocation,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.RoutingLocation,
              });
            },
          };
        }
        case OrderTableField.CustomerName: {
          return {
            field: OrderTableField.CustomerName,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.CustomerName,
              });
            },
          };
        }
        case OrderTableField.Tags: {
          return {
            field: OrderTableField.Tags,
            maxWidth: 100,

            cellRenderer: (
              params: ICellRendererParams<FormattedOrderFragment>,
            ) => <TagsCellNew order={params.data} />,
          };
        }
        case OrderTableField.CompletedAt: {
          return {
            field: OrderTableField.CompletedAt,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.CompletedAt,
              });
            },
          };
        }
        case OrderTableField.CreatedAt: {
          return {
            field: OrderTableField.CreatedAt,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.CreatedAt,
              });
            },
          };
        }
        case OrderTableField.Company: {
          return {
            field: OrderTableField.Company,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.Company,
              });
            },
          };
        }
        case OrderTableField.InboundCompletedDate: {
          return {
            field: OrderTableField.InboundCompletedDate,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundCompletedDate,
              });
            },
          };
        }
        case OrderTableField.OutboundCompletedDate: {
          return {
            field: OrderTableField.OutboundCompletedDate,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundCompletedDate,
              });
            },
          };
        }
        case OrderTableField.Charges: {
          return {
            field: OrderTableField.Charges,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.Charges,
              });
            },
          };
        }
        case OrderTableField.InboundCharges: {
          return {
            field: OrderTableField.InboundCharges,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundCharges,
              });
            },
          };
        }
        case OrderTableField.OutboundCharges: {
          return {
            field: OrderTableField.OutboundCharges,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundCharges,
              });
            },
          };
        }
        case OrderTableField.LineHaulCharges: {
          return {
            field: OrderTableField.LineHaulCharges,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.LineHaulCharges,
              });
            },
          };
        }
        case OrderTableField.AdditionalCharges: {
          return {
            field: OrderTableField.AdditionalCharges,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.AdditionalCharges,
              });
            },
          };
        }
        case OrderTableField.RouteName: {
          return {
            field: OrderTableField.RouteName,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.RouteName,
              });
            },
          };
        }
        case OrderTableField.PodName: {
          return {
            field: OrderTableField.PodName,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.PodName,
              });
            },
          };
        }
        case OrderTableField.PrimaryDeadline: {
          return {
            field: OrderTableField.PrimaryDeadline,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.PrimaryDeadline,
              });
            },
          };
        }
        case OrderTableField.Special: {
          return {
            field: OrderTableField.Special,

            cellRenderer: (
              params: ICellRendererParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              const isSpecial = getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.Special,
              });
              return isEmpty(isSpecial) ? null : <CheckIcon />;
            },
          };
        }
        case OrderTableField.Weight: {
          return {
            field: OrderTableField.Weight,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.Weight,
              });
            },
          };
        }
        case OrderTableField.DimWeight: {
          return {
            field: OrderTableField.DimWeight,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.DimWeight,
              });
            },
          };
        }
        case OrderTableField.Mawb: {
          return {
            field: OrderTableField.Mawb,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.Mawb,
              });
            },
          };
        }
        case OrderTableField.PieceCount: {
          return {
            field: OrderTableField.PieceCount,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.PieceCount,
              });
            },
          };
        }
        case OrderTableField.InBond: {
          return {
            field: OrderTableField.InBond,
            sortable: true,
            cellRenderer: (
              params: ICellRendererParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              const inBond = getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InBond,
              });
              return isEmpty(inBond) ? <Close /> : <CheckIcon />;
            },
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InBond,
              });
            },
          };
        }
        case OrderTableField.InboundAddress: {
          return {
            field: OrderTableField.InboundAddress,
            width: 200,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundAddress,
              });
            },
          };
        }
        case OrderTableField.OutboundAddress: {
          return {
            field: OrderTableField.OutboundAddress,
            width: 200,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundAddress,
              });
            },
          };
        }
        case OrderTableField.PrimaryServiceLevel: {
          return {
            field: OrderTableField.PrimaryServiceLevel,
            sortable: true,
            cellRenderer: (
              params: ICellRendererParams<FormattedOrderFragment>,
            ) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const setOrderUuidToRefetch = useGlobalStore(
                (state) => state.setOrderUuidToRefetch,
              );
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const [rateOrderByUuid] = useRateOrderByUuidLazyQuery();
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const [serviceUuid, setServiceUuid] = useState<
                string | null | undefined
              >(params.data?.serviceField?.service?.uuid);
              if (isEditable) {
                return (
                  <ServiceLevelAutocomplete
                    isMinimized
                    disableClearable
                    contactUuid={undefined}
                    contact={undefined}
                    width={150}
                    selectedServiceUuid={serviceUuid ?? null}
                    onChange={async (service) => {
                      const orderUuid = params.data?.uuid;
                      if (!isNil(orderUuid) && !isNil(service)) {
                        setServiceUuid(service.uuid);
                        await updateStandardOrder({
                          variables: {
                            updateStandardOrderInput: {
                              orderUpdateInput: {
                                uuid: orderUuid,
                                serviceUuid: service.uuid,
                              },
                            },
                          },
                        });
                        await rateOrderByUuid({
                          variables: {
                            uuid: orderUuid,
                            persist: true,
                          },
                        });
                        setOrderUuidToRefetch(orderUuid);
                      }
                    }}
                  />
                );
              }
              const order = params.data;
              return order?.serviceField?.service?.name ?? '-';
            },
          };
        }
        case OrderTableField.PrimaryAppointment: {
          return {
            field: OrderTableField.PrimaryAppointment,
            width: 150,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.PrimaryAppointment,
              });
            },
          };
        }
        case OrderTableField.NextAppointment: {
          return {
            field: OrderTableField.NextAppointment,
            width: 150,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.NextAppointment,
              });
            },
          };
        }
        case OrderTableField.PrimaryServiceDate: {
          return {
            field: OrderTableField.PrimaryServiceDate,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.PrimaryServiceDate,
              });
            },
          };
        }
        case OrderTableField.InboundServiceDate: {
          return {
            field: OrderTableField.InboundServiceDate,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundServiceDate,
              });
            },
          };
        }
        case OrderTableField.OutboundServiceDate: {
          return {
            field: OrderTableField.OutboundServiceDate,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundServiceDate,
              });
            },
          };
        }
        case OrderTableField.InboundMethod: {
          return {
            field: OrderTableField.InboundMethod,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundMethod,
              });
            },
          };
        }
        case OrderTableField.ItTeNumber: {
          return {
            field: OrderTableField.ItTeNumber,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.ItTeNumber,
              });
            },
          };
        }
        case OrderTableField.UnNumber: {
          return {
            field: OrderTableField.UnNumber,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.UnNumber,
              });
            },
          };
        }
        case OrderTableField.OutboundMethod: {
          return {
            field: OrderTableField.OutboundMethod,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundMethod,
              });
            },
          };
        }
        case OrderTableField.OriginTerminal: {
          return {
            field: OrderTableField.OriginTerminal,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OriginTerminal,
              });
            },
          };
        }
        case OrderTableField.DestinationTerminal: {
          return {
            field: OrderTableField.DestinationTerminal,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.DestinationTerminal,
              });
            },
          };
        }
        case OrderTableField.Notes: {
          return {
            cellClass: 'ag-orders-table-notes-cell',
            field: OrderTableField.Notes,
            maxWidth: 100,

            cellRenderer: (
              params: ICellRendererParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              const notes = getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.Notes,
              });
              if (isEmpty(notes)) {
                return null;
              }
              return (
                <Tooltip
                  title={<div style={{ whiteSpace: 'pre-line' }}>{notes}</div>}
                >
                  <IconButton color="info" size="small" sx={{ padding: '0' }}>
                    <TextSnippetIcon />
                  </IconButton>
                </Tooltip>
              );
            },
          };
        }
        case OrderTableField.HasDriverPod: {
          return {
            field: OrderTableField.HasDriverPod,

            cellRenderer: (
              params: ICellRendererParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              const hasDriverUploadedDocument = getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.HasDriverPod,
              });
              return isEmpty(hasDriverUploadedDocument) ? null : (
                <CheckIcon color="success" />
              );
            },
          };
        }
        case OrderTableField.PaperworkComplete: {
          return {
            field: OrderTableField.PaperworkComplete,
            width: 60,
            cellStyle: { 'white-space': 'pre', justifyContent: 'end' },
            cellRenderer: PaperworkCompleteCellRenderer,
          };
        }
        case OrderTableField.InboundPaperwork: {
          return {
            field: OrderTableField.InboundPaperwork,
            cellStyle: { display: 'flex', alignItems: 'center' },
            cellRenderer: (
              params: ICellRendererParams<FormattedOrderFragment>,
            ) => (
              <Paperwork
                isSmall
                paperwork={params.data?.paperworkField?.inboundPaperwork}
              />
            ),
          };
        }
        case OrderTableField.OutboundPaperwork: {
          return {
            field: OrderTableField.OutboundPaperwork,
            cellStyle: { display: 'flex', alignItems: 'center' },
            cellRenderer: (
              params: ICellRendererParams<FormattedOrderFragment>,
            ) => (
              <Paperwork
                isSmall
                paperwork={params.data?.paperworkField?.outboundPaperwork}
              />
            ),
          };
        }
        case OrderTableField.Photos: {
          return {
            field: OrderTableField.Photos,

            cellRenderer: (
              params: ICellRendererParams<FormattedOrderFragment>,
            ) => <PhotosComponentNew order={params.data} />,
          };
        }
        case OrderTableField.PrimaryAddressType: {
          return {
            field: OrderTableField.PrimaryAddressType,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.PrimaryAddressType,
              });
            },
          };
        }
        case OrderTableField.Dims: {
          return {
            field: OrderTableField.Dims,
            cellRenderer: (
              params: ICellRendererParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              const packages = order?.packagesField?.orderTablePackages;
              if (isEditable && !isNil(packages) && !isNil(order)) {
                return (
                  <PackagesCellComponent
                    orderUuid={order.uuid}
                    packages={packages}
                  />
                );
              }

              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.Dims,
              });
            },
          };
        }
        case OrderTableField.ContactName: {
          return {
            field: OrderTableField.ContactName,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.ContactName,
              });
            },
          };
        }
        case OrderTableField.PrimaryContactName: {
          return {
            field: OrderTableField.PrimaryContactName,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.PrimaryContactName,
              });
            },
          };
        }
        case OrderTableField.SecondaryRefNumber: {
          return {
            field: OrderTableField.SecondaryRefNumber,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.SecondaryRefNumber,
              });
            },
          };
        }
        case OrderTableField.TotalChargesWithItemized: {
          return {
            headerName: 'Total charges (itemized)',
            field: OrderTableField.TotalChargesWithItemized,
            width: 90,

            cellRenderer: ({
              data,
            }: ICellRendererParams<FormattedOrderFragment>) => {
              if (isNil(data)) return '-';
              return <ChargesComponent order={data} />;
            },
          };
        }
        case OrderTableField.TotalSkids: {
          return {
            field: OrderTableField.TotalSkids,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.TotalSkids,
              });
            },
          };
        }
        case OrderTableField.ExternalNotes: {
          return {
            field: OrderTableField.ExternalNotes,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.ExternalNotes,
              });
            },
          };
        }
        case OrderTableField.Attempted: {
          return {
            field: OrderTableField.Attempted,
            cellRenderer: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              const attempted = getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.Attempted,
              });
              return isEmpty(attempted) ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <></>
              ) : (
                <CheckIcon color="success" />
              );
            },
          };
        }
        case OrderTableField.OnHold: {
          return {
            field: OrderTableField.OnHold,
            cellRenderer: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              const onHold = getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OnHold,
              });
              // eslint-disable-next-line react/jsx-no-useless-fragment
              return isEmpty(onHold) ? <></> : <CheckIcon color="success" />;
            },
          };
        }
        case OrderTableField.OrderConsignee: {
          return {
            field: OrderTableField.OrderConsignee,
            width: 160,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OrderConsignee,
              });
            },
            ...singleLineTruncatedCellProps,
          };
        }
        case OrderTableField.OrderServiceDate: {
          return {
            field: OrderTableField.OrderServiceDate,
            maxWidth: 120,
            sortable: true,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OrderServiceDate,
              });
            },
            ...singleLineTruncatedCellProps,
          };
        }
        case OrderTableField.StopTypes: {
          return {
            cellStyle: {
              display: 'flex',
              alignItems: 'center',
            },
            field: OrderTableField.StopTypes,
            width: 120,
            cellRenderer: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => <StopTypesForOrdersTableChipsNew order={params.data} />,
          };
        }
        case OrderTableField.InboundStopType: {
          return {
            field: OrderTableField.InboundStopType,
            maxWidth: 120,
            cellStyle: {
              display: 'flex',
              alignItems: 'center',
            },
            cellRenderer: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const stopType = getOrderTableFieldValue({
                order: params.data,
                orderTableField: OrderTableField.InboundStopType,
              });
              return isEmpty(stopType) ? (
                '-'
              ) : (
                <StopTypeChipNew stopType={stopType as StopType} />
              );
            },
          };
        }
        case OrderTableField.OutboundStopType: {
          return {
            field: OrderTableField.OutboundStopType,
            maxWidth: 120,
            cellStyle: {
              display: 'flex',
              alignItems: 'center',
            },
            cellRenderer: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const stopType = getOrderTableFieldValue({
                order: params.data,
                orderTableField: OrderTableField.OutboundStopType,
              });
              return isEmpty(stopType) ? (
                '-'
              ) : (
                <StopTypeChipNew stopType={stopType as StopType} />
              );
            },
          };
        }
        case OrderTableField.IsLinehaul: {
          return {
            field: OrderTableField.IsLinehaul,

            cellRenderer: (
              params: ICellRendererParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              const isLineHaul = getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.IsLinehaul,
              });

              return isEmpty(isLineHaul) ? null : <CheckIcon />;
            },
          };
        }
        case OrderTableField.InboundRouting: {
          return {
            field: OrderTableField.InboundRouting,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundRouting,
              });
            },
          };
        }
        case OrderTableField.OutboundRouting: {
          return {
            field: OrderTableField.OutboundRouting,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundRouting,
              });
            },
          };
        }
        case OrderTableField.InboundRouteName: {
          return {
            field: OrderTableField.InboundRouteName,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundRouteName,
              });
            },
          };
        }
        case OrderTableField.OutboundRouteName: {
          return {
            field: OrderTableField.OutboundRouteName,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundRouteName,
              });
            },
          };
        }
        case OrderTableField.IsReweighed: {
          return {
            field: OrderTableField.IsReweighed,
            sortable: ffEnableNewTableFunctions,

            cellRenderer: (
              params: ICellRendererParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              const isLineHaul = getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.IsReweighed,
              });
              return isEmpty(isLineHaul) ? null : <CheckIcon />;
            },
          };
        }
        case OrderTableField.DestinationDetails: {
          return {
            field: OrderTableField.DestinationDetails,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.DestinationDetails,
              });
            },
          };
        }
        case OrderTableField.InboundZipcode: {
          return {
            field: OrderTableField.InboundZipcode,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundZipcode,
              });
            },
          };
        }
        case OrderTableField.OutboundZipcode: {
          return {
            field: OrderTableField.OutboundZipcode,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundZipcode,
              });
            },
          };
        }
        case OrderTableField.HoldReason: {
          return {
            field: OrderTableField.HoldReason,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.HoldReason,
              });
            },
          };
        }
        case OrderTableField.OsdReason: {
          return {
            field: OrderTableField.OsdReason,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OsdReason,
              });
            },
          };
        }
        case OrderTableField.InboundRouteDate: {
          return {
            field: OrderTableField.InboundRouteDate,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundRouteDate,
              });
            },
          };
        }
        case OrderTableField.OutboundRouteDate: {
          return {
            field: OrderTableField.OutboundRouteDate,
            sortable: ffEnableNewTableFunctions,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundRouteDate,
              });
            },
          };
        }
        case OrderTableField.InboundOnTimePerformanceStatus: {
          return {
            field: OrderTableField.InboundOnTimePerformanceStatus,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundOnTimePerformanceStatus,
              });
            },
          };
        }
        case OrderTableField.OutboundOnTimePerformanceStatus: {
          return {
            field: OrderTableField.OutboundOnTimePerformanceStatus,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField:
                  OrderTableField.OutboundOnTimePerformanceStatus,
              });
            },
          };
        }
        case OrderTableField.InboundStopWaitTime: {
          return {
            field: OrderTableField.InboundStopWaitTime,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundStopWaitTime,
              });
            },
          };
        }
        case OrderTableField.OutboundStopWaitTime: {
          return {
            field: OrderTableField.OutboundStopWaitTime,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundStopWaitTime,
              });
            },
          };
        }
        case OrderTableField.InboundStopTotalTime: {
          return {
            field: OrderTableField.InboundStopTotalTime,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.InboundStopTotalTime,
              });
            },
          };
        }
        case OrderTableField.OutboundStopTotalTime: {
          return {
            field: OrderTableField.OutboundStopTotalTime,
            valueGetter: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              const order = params.data;
              return getOrderTableFieldValue({
                order,
                orderTableField: OrderTableField.OutboundStopTotalTime,
              });
            },
          };
        }
        case OrderTableField.LineHaulManifests: {
          return {
            field: OrderTableField.LineHaulManifests,
            cellRenderer: (
              params: ValueGetterParams<FormattedOrderFragment>,
            ) => {
              if (isNil(params.data?.lineHaulManifestsField)) {
                return null;
              }
              const {
                currentTerminal,
                lineHaulManifests,
                startTerminal,
                endTerminal,
              } = params.data.lineHaulManifestsField;
              return (
                <LineHaulManifestsCell
                  currentTerminal={currentTerminal}
                  lineHaulManifests={lineHaulManifests}
                  startTerminal={startTerminal}
                  endTerminal={endTerminal}
                />
              );
            },
          };
        }
        default: {
          return exhaustive(orderTableField);
        }
      }
    },
    [ffEnableNewTableFunctions, isEditable, updateStandardOrder],
  );

  const orderTableFields: Array<ColDef<FormattedOrderFragment>> =
    useMemo(() => {
      const fields: Array<ColDef<FormattedOrderFragment>> = [
        {
          width: 34,
          minWidth: 34,
          checkboxSelection: true,
          field: ExcludeFromHiding.EMPTY_SPACE,
          headerName: '',
          headerCheckboxSelection: false,
          cellClass: 'ag-checkbox-cell',
          headerClass: 'ag-checkbox-cell',
          resizable: false,
        },
      ];
      for (const orderTableField of values(OrderTableField)) {
        const columnDef = mapOrderTableFieldToOrderTableColumnDefinition({
          orderTableField,
        });
        fields.push({
          ...columnDef,
          headerName: columnDef.headerName ?? '',
          minWidth: undefined,
        });
      }

      fields.push({
        field: ExcludeFromHiding.BUTTON,
        headerName: '',
        width: 120,
        maxWidth: 120,
        cellRenderer: (params: ICellRendererParams) => (
          <OpenOrderButton
            openInCurrentPage
            addPageEntryToBrowserHistory
            params={params}
          />
        ),
        pinned: 'right',
      });

      return fields;
    }, [mapOrderTableFieldToOrderTableColumnDefinition]);

  const ordersTableFilterColumns: Array<ColDef<FormattedOrderFragment>> =
    useOrdersTableFilterColumns();

  const fields: Array<ColDef<FormattedOrderFragment>> = useMemo(() => {
    return [...orderTableFields, ...ordersTableFilterColumns];
  }, [orderTableFields, ordersTableFilterColumns]);

  return fields;
};
