import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import currency from 'currency.js';
import { useState } from 'react';
import {
  DriverSettlementBillByUuidV2Document,
  DriverSettlementBillsV2Document,
  SettlementAdjustmentType,
  useRemoveSettlementAdjustmentMutation,
  type SettlementAdjustmentFragment,
} from '../../../generated/graphql';
import CreateOrUpdateAdjustmentModal from './create-or-update-adjustment-modal';

const SettlementAdjustmentLineItems = ({
  isFinalized,
  settlementAdjustments,
}: {
  readonly settlementAdjustments: SettlementAdjustmentFragment[];
  readonly isFinalized: boolean;
}) => {
  const theme = useTheme();
  const DEDUCTION_COLOR = theme.palette.errorRed[70];
  const ADJUSTMENT_COLOR = theme.palette.successGreen[70];

  const [removeSettlementAdjustment] = useRemoveSettlementAdjustmentMutation();

  const deleteAdjustment = async (uuid: string) => {
    await removeSettlementAdjustment({
      variables: {
        uuid,
      },
      refetchQueries: [
        DriverSettlementBillsV2Document,
        DriverSettlementBillByUuidV2Document,
      ],
    });
  };

  const [editingId, setEditingId] = useState<string | null>(null);

  const handleOpenModal = (uuid: string) => {
    setEditingId(uuid);
  };

  const handleCloseModal = () => {
    setEditingId(null);
  };

  return (
    <Stack
      direction="column"
      spacing={1}
      paddingLeft="6px"
      sx={{ width: '100%', maxWidth: '5000px', overflow: 'hidden' }}
    >
      {settlementAdjustments?.map((adjustment) => (
        <Stack
          key={adjustment.uuid}
          direction="row"
          justifyContent="space-between"
          sx={{
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.03)',
            },
          }}
        >
          <Stack direction="row" spacing={1} maxWidth="425px">
            <Tooltip title={adjustment.name}>
              <Typography
                fontSize={14}
                color={
                  adjustment.adjustmentType ===
                  SettlementAdjustmentType.Deduction
                    ? DEDUCTION_COLOR
                    : ADJUSTMENT_COLOR
                }
                sx={{
                  cursor: 'default',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  flex: '0 1 1',
                  minWidth: 0,
                }}
              >
                {adjustment.name}
              </Typography>
            </Tooltip>
            <Stack direction="row">
              <Tooltip
                arrow
                title="Cannot edit items on a finalized settlement bill"
                disableHoverListener={!isFinalized}
                disableTouchListener={!isFinalized}
              >
                <span style={{ display: 'block' }}>
                  <IconButton
                    size="small"
                    disabled={isFinalized}
                    onClick={() => {
                      handleOpenModal(adjustment.uuid);
                    }}
                  >
                    <EditIcon sx={{ fontSize: '14px' }} />
                  </IconButton>
                </span>
              </Tooltip>
              <CreateOrUpdateAdjustmentModal
                modalState={{ state: 'update', adjustment }}
                isOpen={editingId === adjustment.uuid}
                onClose={handleCloseModal}
              />
              <Tooltip
                arrow
                title="Cannot delete items on a finalized settlement bill"
                disableHoverListener={!isFinalized}
                disableTouchListener={!isFinalized}
              >
                <span style={{ display: 'block' }}>
                  <IconButton
                    size="small"
                    disabled={isFinalized}
                    onClick={async () => deleteAdjustment(adjustment.uuid)}
                  >
                    <DeleteIcon sx={{ fontSize: '14px' }} />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          </Stack>
          <Stack>
            <Typography
              fontSize={14}
              color={
                adjustment.adjustmentType === SettlementAdjustmentType.Deduction
                  ? DEDUCTION_COLOR
                  : ADJUSTMENT_COLOR
              }
            >
              {currency(adjustment.totalDollars.value).format()}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default SettlementAdjustmentLineItems;
