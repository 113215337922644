import { Alert, Box, Button, Snackbar, Stack } from '@mui/material';
import { isEmpty, isNil, union } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { shallow } from 'zustand/shallow';
import { INVOICE_ORDER_TABS_TEST_ID_PREFIX } from '../../../../../constants';
import CenteredCircularProgress from '../../../../common/components/centered-circular-progress';
import { DatePickerFilterType } from '../../../../common/components/date-dropdown-picker';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import { useTableFields } from '../../../../common/react-hooks/use-table-fields';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  FilterViewPage,
  type FormattedOrderFragment,
  OrderTableField,
  PermissionResource,
  useFinalizeOrdersMutation,
} from '../../../../generated/graphql';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import PalletButton from '../../../../pallet-ui/button/pallet-button';
import { OrdersTableWithFiltersAgGrid } from '../../../ag-grid/orders/orders-table-with-filters-ag-grid';
import { useOrdersTableColumns } from '../../../orders/components/order-form/hooks/use-orders-table-columns';
import {
  BILLING_REVIEW_DEFAULT_FILTER_TAB_CONFIGS,
  BILLING_REVIEW_ORDER_TABLE_DEFAULT_HEADERS,
} from '../../billing-review-constants';
import useBillingReviewStore from '../../billing-review-store';
import { InvoiceOrderTabs } from '../../types/types';
import { getReviewModalLabel, isInvoiceOrderTabReviewType } from '../../utils';
import AddOrdersToInvoicesModal from './add-orders-to-invoices/add-orders-to-invoices-modal';
import PostFinalizedModalV2, {
  type SelectedOrders,
} from './post-finalized-modal-v2';
import ReviewOutstandingOrdersModal from './review-outstanding-orders-modal';

const ORDER_PAGE_SIZE = 25;

const BillingReview = () => {
  const [shouldRefreshOrdersTableData, setShouldRefreshOrdersTableData] =
    useGlobalStore(
      (state) => [
        state.shouldRefreshOrdersTableData,
        state.setShouldRefreshOrdersTableData,
      ],
      shallow,
    );

  const disableOrdersPageCount = useFeatureFlag(
    FeatureFlag.FF_DISABLE_ORDERS_PAGE_COUNT,
  );

  const enableNewSendOrdersToBillingFlow = useFeatureFlag(
    FeatureFlag.FF_ENABLE_NEW_SEND_ORDERS_TO_BILLING_FLOW,
  );

  const confirm = useConfirm();

  const {
    billingReviewOrderTableFields,
    loading: billingReviewOrderTableFieldsLoading,
  } = useTableFields();
  const { userUuid } = useMe();
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteBillingReview } = getPermissionsFlags(
    userPermissions,
    PermissionResource.BillingReview,
  );
  const { canWrite: canFinalizeCharges } = getPermissionsFlags(
    userPermissions,
    PermissionResource.FinalizeChargesOrders,
  );
  const { canWrite: canSendFinalizedToBilling } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SendFinalizedToBilling,
  );

  const orderTableHeadersWithCharges = useMemo(() => {
    return isEmpty(billingReviewOrderTableFields)
      ? BILLING_REVIEW_ORDER_TABLE_DEFAULT_HEADERS
      : union(billingReviewOrderTableFields, [
          OrderTableField.TotalChargesWithItemized,
        ]);
  }, [billingReviewOrderTableFields]);

  const columnDefinitions = useOrdersTableColumns({
    pageType: FilterViewPage.Invoices,
    isEditable: true,
  });

  const [setOpenedOrderUuid] = useGlobalStore(
    (state) => [state.setCurrentOrderUuid],
    shallow,
  );

  const [serviceUuid, setServiceUuid] = useState<string | undefined>();
  const [currentTab, setCurrentTab] = useState<InvoiceOrderTabs>(
    InvoiceOrderTabs.Unfinalized,
  );
  const [showSendFinalizedModal, setShowSendFinalizedModal] =
    useState<boolean>(false);
  const [selectedOrderUuids, setSelectedOrderUuids] = useState<string[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<SelectedOrders | null>(
    null,
  );
  const [numOrdersFinalized, setNumOrdersFinalized] = useState<number>(0);
  const [numFailedFinalizeOrders, setNumFailedFinalizeOrders] =
    useState<number>(0);
  const [
    showFinalizeOrdersSuccessMessage,
    setShowFinalizeOrdersSuccessMessage,
  ] = useState<boolean>(false);
  const [showFinalizeOrdersErrorMessage, setShowFinalizeOrdersErrorMessage] =
    useState<boolean>(false);

  const [
    outstandingOrderReviewModalIsOpen,
    setOutstandingOrderReviewModalIsOpen,
  ] = useState(false);

  const [finalizeOrders, { loading: finalizeOrdersLoading }] =
    useFinalizeOrdersMutation();

  const [setSelectedOriginTerminalUuid, setSelectedDestinationTerminalUuid] =
    useBillingReviewStore(
      (state) => [
        state.setSelectedOriginTerminalUuid,
        state.setSelectedDestinationTerminalUuid,
      ],
      shallow,
    );

  const handleSelectAllOrders = (uuids: string[]) => {
    setSelectedOrderUuids(uuids);
    setSelectedOrders({ retrievalMethod: 'queryAll' });
  };

  const handleFinalizeOrders = async () => {
    await confirm({
      title: `Are you sure you want to finalize ${selectedOrderUuids.length} orders?`,
      description: '',
      cancellationText: `Cancel`,
      confirmationText: `Confirm`,
      allowClose: false,
    });

    const res = await finalizeOrders({
      variables: {
        finalizeOrdersInput: {
          uuids: selectedOrderUuids,
        },
      },
    });

    const finalizeOrdersResponse = res?.data?.finalizeOrders;

    if (finalizeOrdersResponse?.__typename === 'FinalizeOrdersSuccessOutput') {
      setNumOrdersFinalized(finalizeOrdersResponse.finalizedOrderUuids.length);
      setNumFailedFinalizeOrders(
        finalizeOrdersResponse.failedOrderUuids.length,
      );
      setShowFinalizeOrdersSuccessMessage(true);
      setShouldRefreshOrdersTableData(true);
    } else {
      setShowFinalizeOrdersErrorMessage(true);
    }
  };

  const handleSelectOrder = (orders: FormattedOrderFragment[]) => {
    setSelectedOrderUuids(orders.map((order) => order.uuid));
    // The new orders query relies on the selectedOrderUuids alone so these two pieces of state can be set to a default value.
    setSelectedOrders({ retrievalMethod: 'selection', orders: [] });
  };

  if (billingReviewOrderTableFieldsLoading) {
    return <CenteredCircularProgress />;
  }

  return (
    <Box height="100%">
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showFinalizeOrdersSuccessMessage}
        onClose={() => {
          setShowFinalizeOrdersSuccessMessage(false);
          setNumOrdersFinalized(0);
        }}
      >
        <Alert severity="success">
          Successfully finalized {numOrdersFinalized} /{' '}
          {numOrdersFinalized + numFailedFinalizeOrders} orders
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showFinalizeOrdersErrorMessage}
        onClose={() => {
          setShowFinalizeOrdersErrorMessage(false);
          setNumOrdersFinalized(0);
        }}
      >
        <Alert severity="error">Error finalizing orders</Alert>
      </Snackbar>
      <OrdersTableWithFiltersAgGrid<InvoiceOrderTabs>
        rowSelectionEnabled
        shouldShowGenerateReportButtons
        shouldShowOriginTerminalFilter
        shouldShowDestinationTerminalFilter
        shouldShowDatePicker
        shouldShowCustomerFilterMultiselect
        shouldRememberFilters
        isRowsPerPageSelectionEnabled
        showSelectAllOrders
        enableHeaderCheckboxSelection
        shouldAllowSavedViews={false}
        defaultDatePickerFilterType={DatePickerFilterType.AllSelect}
        columnDefinitions={columnDefinitions}
        showTotalCount={!disableOrdersPageCount}
        userOrderTableFields={orderTableHeadersWithCharges}
        shouldRefresh={shouldRefreshOrdersTableData}
        setShouldRefresh={setShouldRefreshOrdersTableData}
        handleSelectOrders={handleSelectOrder}
        handleSelectAllOrderUuids={handleSelectAllOrders}
        handleSelectOriginTerminal={(uuid: string | undefined) => {
          setSelectedOriginTerminalUuid(uuid);
        }}
        handleSelectDestinationTerminal={(uuid: string | undefined) => {
          setSelectedDestinationTerminalUuid(uuid);
        }}
        handleTabChange={(tab: InvoiceOrderTabs) => {
          setCurrentTab(tab);
        }}
        handleFilterChange={(filters) => {
          if (!isNil(filters) && !isNil(filters['Service Level'])) {
            setServiceUuid(filters['Service Level']?.value);
          }
        }}
        pageSize={ORDER_PAGE_SIZE}
        pageType={FilterViewPage.Invoices}
        userUuid={userUuid}
        topRightComponent={
          <Stack direction="row" spacing={2}>
            {[InvoiceOrderTabs.Unfinalized].includes(currentTab) && (
              <PalletButton
                variant="contained"
                disabled={
                  !canSendFinalizedToBilling || selectedOrderUuids.length === 0
                }
                loading={finalizeOrdersLoading}
                onClick={handleFinalizeOrders}
              >
                {selectedOrderUuids.length === 0
                  ? 'Finalize orders'
                  : `Finalize ${selectedOrderUuids.length} ${pluralize(
                      'order',
                      selectedOrderUuids.length,
                    )}`}
              </PalletButton>
            )}
            {[
              InvoiceOrderTabs.Cancelled,
              InvoiceOrderTabs.Finalized,
              InvoiceOrderTabs.FinalizedNoCharge,
              InvoiceOrderTabs.BillingIssue,
            ].includes(currentTab) && (
              <Button
                variant="contained"
                disabled={selectedOrderUuids.length === 0}
                onClick={() => {
                  setShowSendFinalizedModal(true);
                }}
              >
                {selectedOrderUuids.length > 0
                  ? `Send Orders (${selectedOrderUuids.length})`
                  : 'Send Orders'}
              </Button>
            )}
            {isInvoiceOrderTabReviewType(currentTab) && (
              <Button
                data-test-id={`${INVOICE_ORDER_TABS_TEST_ID_PREFIX}-${currentTab}`}
                variant="contained"
                disabled={!canWriteBillingReview || !canFinalizeCharges}
                onClick={() => {
                  setOutstandingOrderReviewModalIsOpen(true);
                  setOpenedOrderUuid(undefined);
                }}
              >
                {selectedOrderUuids.length === 0
                  ? getReviewModalLabel(currentTab)
                  : `Review Orders (${selectedOrderUuids.length})`}
              </Button>
            )}
          </Stack>
        }
        defaultFilterTabsConfigs={BILLING_REVIEW_DEFAULT_FILTER_TAB_CONFIGS}
        originTerminalFilterCacheId="BILLING_REVIEW_ORIGIN_TERMINAL"
        destinationTerminalFilterCacheId="BILLING_REVIEW_DESTINATION_TERMINAL"
        enableNumberOfOrdersQuery={false}
      />
      {showSendFinalizedModal &&
        !isNil(selectedOrders) &&
        (enableNewSendOrdersToBillingFlow ? (
          <AddOrdersToInvoicesModal
            isOpen={showSendFinalizedModal}
            setIsOpen={setShowSendFinalizedModal}
            selectedOrderUuids={selectedOrderUuids}
            onClose={() => {
              setShouldRefreshOrdersTableData(true);
            }}
          />
        ) : (
          <PostFinalizedModalV2
            isOpen={showSendFinalizedModal}
            setIsOpen={setShowSendFinalizedModal}
            selectedOrderUuids={selectedOrderUuids}
            serviceUuid={serviceUuid}
            onClose={() => {
              setShouldRefreshOrdersTableData(true);
            }}
          />
        ))}
      <ReviewOutstandingOrdersModal
        initialOrderUuids={selectedOrderUuids}
        isOpen={outstandingOrderReviewModalIsOpen}
        onClose={() => {
          setShouldRefreshOrdersTableData(true);
          setOutstandingOrderReviewModalIsOpen(false);
        }}
      />
    </Box>
  );
};

export default BillingReview;
