import { NetworkStatus } from '@apollo/client';
import SkinnyHamburger from '@mui/icons-material/MoreVert';
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { isNil } from 'lodash';
import { type PropsWithChildren, useMemo, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { ErrorsAlert } from '../../../../common/components/errors-alert';
import { useErrors } from '../../../../common/react-hooks/use-errors';
import {
  type FindLineHaulManifestsV2FiltersInput,
  LineHaulManifestsGroupBy,
  useLineHaulManifestGroupsQuery,
} from '../../../../generated/graphql';
import useLineHaulDispatchStore from '../../store/line-haul-dispatch-store';
import CreateManifestsButton from './create-manifests-button/create-manifests-button';
import ManifestsBulkUpdate from './manifest-bulk-update';
import ManifestDetails from './manifest-details/manifest-details';
import ManifestLaneGroups from './manifest-lane-groups/manifest-lane-groups';
import ManifestLaneGroupsFilters from './manifest-lane-groups/manifest-lane-groups-filters';
import dayjs from 'dayjs';
import { type LineHaulManifest } from './utils';

type LayoutProps = PropsWithChildren<{
  readonly manifestIds?: string[];
  readonly isRefetching: boolean;
  readonly errors: string[];
  readonly clearErrors: () => void;
  readonly filters: FindLineHaulManifestsV2FiltersInput;
  readonly searchText: string;
  readonly setSearchText: (searchText: string) => void;
  readonly onSearchSubmit: () => void;
  readonly selectedManifests?: LineHaulManifest[];
}>;
const Layout = ({
  children,
  manifestIds,
  isRefetching,
  errors,
  clearErrors,
  filters,
  searchText,
  setSearchText,
  onSearchSubmit,
  selectedManifests,
}: LayoutProps) => {
  const [
    departDate,
    selectedManifestUuids,
    selectManifestUuids,
    deselectAllManifestUuids,
  ] = useLineHaulDispatchStore(
    (state) => [
      state.departDate,
      state.selectedManifestUuids,
      state.selectManifestUuids,
      state.deselectAllManifestUuids,
    ],
    shallow,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const areAllManifestsSelected =
    !isNil(manifestIds) &&
    manifestIds.length > 0 &&
    selectedManifestUuids.length === manifestIds.length;

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAll = () => {
    if (!isNil(manifestIds)) {
      if (areAllManifestsSelected) {
        deselectAllManifestUuids();
      } else {
        selectManifestUuids(manifestIds);
      }
    }
    handleClose();
  };

  const onFiltersChange = (filters: FindLineHaulManifestsV2FiltersInput) => {
    const departDateFilter = filters.and?.find((f) => f.fields?.departDate?.eq);
    const departDateFilterValue = dayjs(
      departDateFilter?.fields?.departDate?.eq as string,
    );
    if (!departDate.isSame(departDateFilterValue, 'day')) {
      deselectAllManifestUuids();
    }
  };

  return (
    <Stack gap={1} p={1} pb={0} position="relative" height="100%">
      <ErrorsAlert errors={errors} onClear={clearErrors} />
      <ManifestsBulkUpdate manifests={selectedManifests ?? []} />
      <Stack direction="row" width="100%" alignItems="center">
        <Stack direction="row" alignItems="center" gap={1} width="100%">
          <TextField
            label="Search manifests..."
            value={searchText}
            size="small"
            sx={{ flex: 1 }}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSearchSubmit();
              }
            }}
            onBlur={onSearchSubmit}
          />
          <CircularProgress
            size={20}
            sx={{ visibility: isRefetching ? 'visible' : 'hidden' }}
          />
          <CreateManifestsButton initialDate={departDate} />
          <IconButton onClick={handleMenuClick}>
            <SkinnyHamburger />
          </IconButton>
        </Stack>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem onClick={handleSelectAll}>
            {areAllManifestsSelected
              ? 'Unselect all manifests'
              : 'Select all manifests'}
          </MenuItem>
        </Menu>
      </Stack>
      <ManifestLaneGroupsFilters
        filters={filters}
        onFilterConditionChange={onFiltersChange}
      />
      <Box overflow="auto" flex="1">
        {children}
      </Box>
    </Stack>
  );
};

const LineHaulManifestsSection = () => {
  const [searchText, setSearchText] = useState('');
  const appliedSearchText = useLineHaulDispatchStore(
    (state) => state.appliedSearchText,
  );
  const setAppliedSearchText = useLineHaulDispatchStore(
    (state) => state.setAppliedSearchText,
  );
  const departDate = useLineHaulDispatchStore((state) => state.departDate);
  const [openedManifest, setOpenedManifest] = useLineHaulDispatchStore(
    (state) => [state.openedManifest, state.setOpenedManifest],
    shallow,
  );
  const filters = useLineHaulDispatchStore((state) => state.filters);

  const { errors, onError, clearErrors } = useErrors();
  const { data, networkStatus } = useLineHaulManifestGroupsQuery({
    fetchPolicy: 'cache-and-network',
    onError,
    variables: {
      input: {
        groupBy: LineHaulManifestsGroupBy.Lane,
        filters,
        searchText: appliedSearchText.trim(),
      },
    },
    onCompleted: (data) => {
      if (!isNil(openedManifest)) {
        // Refresh the opened manifest.
        setOpenedManifest(
          data.lineHaulManifestsGroups.groups
            .flatMap((g) => g.manifests)
            .find((m) => m.uuid === openedManifest.uuid),
        );
      }
    },
  });

  const isRefetching = networkStatus === NetworkStatus.refetch;
  const isLoading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables;

  const selectedManifestUuids = useLineHaulDispatchStore(
    (state) => state.selectedManifestUuids,
  );
  const selectedManifests = useMemo(() => {
    if (isNil(data?.lineHaulManifestsGroups.groups)) return [];
    return data.lineHaulManifestsGroups.groups
      .flatMap((g) => g.manifests)
      .filter((m) => selectedManifestUuids.includes(m.uuid));
  }, [data?.lineHaulManifestsGroups.groups, selectedManifestUuids]);

  const manifestIds = useMemo(() => {
    if (isNil(data?.lineHaulManifestsGroups.groups)) return [];
    return data.lineHaulManifestsGroups.groups
      .flatMap((g) => g.manifests)
      .map((m) => m.uuid);
  }, [data?.lineHaulManifestsGroups.groups]);

  const sharedLayoutProps: LayoutProps = {
    isRefetching,
    errors,
    clearErrors,
    filters,
    searchText,
    setSearchText,
    onSearchSubmit: () => {
      setAppliedSearchText(searchText);
    },
  };

  if (isLoading && isNil(data)) {
    return (
      <Layout {...sharedLayoutProps}>
        <CircularProgress />
      </Layout>
    );
  }

  if (data?.lineHaulManifestsGroups.groups.length === 0) {
    return (
      <Layout {...sharedLayoutProps}>
        <div>No manifests found</div>
      </Layout>
    );
  }

  if (!isNil(openedManifest)) {
    return (
      <ManifestDetails
        manifest={openedManifest}
        departDate={departDate}
        isRefetching={isRefetching}
      />
    );
  }

  return (
    <Layout
      {...sharedLayoutProps}
      manifestIds={manifestIds}
      selectedManifests={selectedManifests}
    >
      <ManifestLaneGroups
        groups={data?.lineHaulManifestsGroups.groups ?? []}
        departDate={departDate}
      />
    </Layout>
  );
};

export default LineHaulManifestsSection;
