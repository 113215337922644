import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isNotNilOrEmptyString } from 'shared/string';
import { useDebounce } from 'use-debounce';
import useSelectedTerminalUuid from '../../../../common/react-hooks/use-selected-terminal-uuid';
import OrderPage from '../order-form/order-page';
import IngestedEmailsFilters from './ingested-email-filters';
import useIngestedEmailsStore from './ingested-emails-store';
import ReviewEmailOrders from './review-email-orders/review-email-orders';
import ReviewEmailOrdersSidebar from './review-email-orders/review-email-orders-sidebar';

export enum InboundMessagesTabs {
  EDI_MESSAGE_HISTORY,
  REVIEW_ORDERS,
}

const IngestedEmailsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const pageOrderUuid = searchParams.get('orderUuid');

  const [
    selectedOrderUuid,
    setSelectedOrderUuid,
    searchText,
    customerOption,
    originTerminalOption,
    destinationTerminalOption,
    rowsPerPage,
    refresh,
  ] = useIngestedEmailsStore((state) => [
    state.selectedOrderUuid,
    state.setSelectedOrderUuid,
    state.searchText,
    state.customerOption,
    state.originTerminalOption,
    state.destinationTerminalOption,
    state.ingestedEmailsTableState.rowsPerPage,
    state.refresh,
  ]);

  useEffect(() => {
    if (isNotNilOrEmptyString(pageOrderUuid)) {
      setSelectedOrderUuid(pageOrderUuid);
      setSearchParams((sp) => {
        const newParams = new URLSearchParams(sp);
        newParams.set('orderUuid', pageOrderUuid);
        return newParams;
      });
    }
  }, [pageOrderUuid, setSearchParams, setSelectedOrderUuid]);

  const [debouncedSearchText] = useDebounce(searchText, 300);

  const { selectedTerminalUuid } = useSelectedTerminalUuid();

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSearchText,
    rowsPerPage,
    customerOption?.value,
    originTerminalOption?.value,
    destinationTerminalOption?.value,
    selectedTerminalUuid,
  ]);

  const hasOrderSelected = !isNil(selectedOrderUuid);

  if (!hasOrderSelected) {
    return (
      <Box
        sx={{
          backgroundColor: 'white',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: 1,
            borderColor: theme.palette.borderColor.main,
            position: 'relative',
            zIndex: 1000,
            boxSizing: 'border-box',
            p: 1,
            pl: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h6">Email Orders</Typography>
          </Box>
          <IngestedEmailsFilters />
        </Box>
        <ReviewEmailOrders />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        flexShrink: 0,
        position: 'relative',
        overflow: 'auto',
        height: '100%',
        maxHeight: '100%',
      }}
    >
      <ReviewEmailOrdersSidebar />
      <Stack
        sx={{
          height: '100%',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            width: '100%',
            borderBottom: `1px solid ${theme.palette.borderColor.main}`,
            p: 1,
            flexShrink: 0,
          }}
        >
          <Button
            size="small"
            variant="text"
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              setSelectedOrderUuid(undefined);
              setSearchParams((sp) => {
                const newParams = new URLSearchParams(sp);
                newParams.delete('orderUuid');
                return newParams;
              });
            }}
          >
            Back
          </Button>
          <IngestedEmailsFilters />
        </Stack>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
          }}
        >
          <OrderPage
            key={selectedOrderUuid}
            inInboundEmailReview
            orderUuid={selectedOrderUuid}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default IngestedEmailsPage;
