import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Divider,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import {
  DriverSettlementBillByUuidV2Document,
  DriverSettlementBillsV2Document,
  useDriverSettlementBillByUuidV2Query,
  useUpdateDriverSettlementBillMutation,
} from '../../../generated/graphql';
import { SettlementBillLineItemsTableMode } from '../enums';
import { SettlementBillLineItemsTable } from './settlement-bill-line-items-table';
import { ConfirmationButton } from '../../../common/components/buttons/confirmation-button';
import SettlementBillPaymentSummary from './settlement-bill-payment-summary';
import PalletButton from '../../../pallet-ui/button/pallet-button';
import SettlementBillsDownloadModal from './settlement-bills-download-modal';

type UpdateInput = {
  newName?: string;
  newSettlementDate?: Dayjs | null;
  finalize?: true;
};

const SettlementBill = ({
  settlementUuid,
  onClose,
}: {
  readonly settlementUuid: string;
  readonly onClose: () => void;
}) => {
  const [name, setName] = useState<string | undefined>();
  const [startDate, setStartDate] = useState<Dayjs | null | undefined>();
  const [endDate, setEndDate] = useState<Dayjs | null | undefined>();
  const [settlementDate, setSettlementDate] = useState<
    Dayjs | null | undefined
  >();
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const {
    data: settlementData,
    loading: dataLoading,
    refetch,
  } = useDriverSettlementBillByUuidV2Query({
    variables: {
      uuid: settlementUuid,
    },
    onCompleted: (data) => {
      const settlementBill = data?.driverSettlementBill;
      setName(settlementBill?.name ?? undefined);
      setStartDate(
        isNil(settlementBill?.billStartDate)
          ? null
          : dayjs(settlementBill?.billStartDate as string),
      );
      setEndDate(
        isNil(settlementBill?.billEndDate)
          ? undefined
          : dayjs(settlementBill?.billEndDate as string),
      );
      setSettlementDate(
        isNil(settlementBill?.settlementDate)
          ? undefined
          : dayjs(settlementBill?.settlementDate as string),
      );
    },
  });
  const [updateDriverSettlementBill, { loading: updateLoading }] =
    useUpdateDriverSettlementBillMutation({
      refetchQueries: [
        DriverSettlementBillsV2Document,
        DriverSettlementBillByUuidV2Document,
      ],
    });

  const loading = dataLoading || updateLoading;
  const isFinalized = !isNil(
    settlementData?.driverSettlementBill.finalizedDate,
  );
  const editingDisabled = isFinalized || loading;

  const update = async ({
    newName,
    newSettlementDate,
    finalize,
  }: UpdateInput) => {
    await updateDriverSettlementBill({
      variables: {
        updateDriverSettlementBillInput: {
          driverSettlementBillUpdateInput: {
            uuid: settlementUuid,
            name: newName,
            settlementDate: isNil(newSettlementDate)
              ? undefined
              : newSettlementDate.toDate(),
            finalizedDate: finalize === true ? new Date() : undefined,
          },
        },
      },
    });
  };

  return (
    <Stack height="100%" direction="column" spacing={1} paddingTop={1}>
      <SettlementBillsDownloadModal
        isOpen={openDownloadModal}
        settlementBillIds={[settlementUuid]}
        onClose={() => {
          setOpenDownloadModal(false);
        }}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding={1}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <FormControl>
            <TextField
              label="Name"
              value={name ?? ''}
              size="small"
              style={{ width: 300 }}
              disabled={editingDisabled}
              onChange={(e) => {
                setName(e.target.value);
              }}
              onBlur={async (e) => {
                await update({ newName: e.target.value });
              }}
            />
          </FormControl>
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled={editingDisabled}
                label="Settlement date"
                value={settlementDate}
                renderInput={(params) => (
                  <TextField
                    aria-label="date-picker"
                    sx={{ width: 130 }}
                    size="small"
                    {...params}
                  />
                )}
                onChange={async (newValue) => {
                  setSettlementDate(newValue);
                  await update({ newSettlementDate: newValue });
                }}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl>
            <TextField
              label="Pay period"
              value={`${startDate?.format('MM/DD/YY') ?? 'N/A'} - ${
                endDate?.format('MM/DD/YY') ?? 'N/A'
              }`}
              size="small"
              InputProps={{
                readOnly: true,
              }}
              style={{ width: 180 }}
              disabled={editingDisabled}
            />
          </FormControl>
          <PalletButton
            variant="contained"
            color="info"
            disabled={loading}
            onClick={() => {
              setOpenDownloadModal(true);
            }}
          >
            Download
          </PalletButton>
          {!dataLoading && !isFinalized && (
            <ConfirmationButton
              key="finalize"
              variant="contained"
              confirmationTitle="Are you sure you want to finalize this settlement bill?"
              confirmationDescription="This action cannot be undone. Once finalized, the settlement bill will be locked and no further changes can be made."
              cancellationText="No, cancel"
              confirmationText="Yes, finalize"
              disabled={loading}
              onConfirm={async () => {
                await update({ finalize: true });
              }}
            >
              Finalize
            </ConfirmationButton>
          )}
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          {!dataLoading && !isFinalized && (
            <Typography sx={{ fontSize: '12px', color: 'gray' }}>
              {updateLoading ? 'Saving...' : 'Saved'}
            </Typography>
          )}
          <IconButton
            disabled={loading}
            style={{ width: '30px', height: '30px' }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </Stack>
      <SettlementBillPaymentSummary
        isFinalized={isFinalized}
        settlementBillUuid={settlementData?.driverSettlementBill.uuid ?? ''}
        settlementAdjustments={
          settlementData?.driverSettlementBill.settlementAdjustments ?? []
        }
        paymentInformation={
          settlementData?.driverSettlementBill.paymentInformation
        }
      />
      <Divider />
      <SettlementBillLineItemsTable
        mode={SettlementBillLineItemsTableMode.SETTLEMENT_BILL}
        settlementBillLineItems={
          settlementData?.driverSettlementBill.settlementBillLineItems ?? []
        }
        refresh={async () => {
          await refetch();
        }}
      />
    </Stack>
  );
};

export default SettlementBill;
