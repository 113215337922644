import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isNilOrEmptyString } from 'shared/string';
import PalletModal from '../../../../pallet-ui/modal/pallet-modal';
import { OrderFormEditAccessProvider } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import { OrderFormEditAccess } from '../../../orders/components/order-form/forms/use-order-form-edit-access';
import useContactsForMeAsThirdPartyUser from '../../hooks/use-contacts-for-me-as-third-party-user';
import CustomerPortalCompanyField from './customer-portal-company-field';

export enum SelectEntityType {
  Order = 'ORDER',
  Quote = 'QUOTE',
}

type CustomerPortalCompanySelectModalProps = {
  readonly onClose: () => void;
};

const CustomerPortalCompanySelectModal = ({
  onClose,
}: CustomerPortalCompanySelectModalProps) => {
  const [companyUuid, setCompanyUuid] = useState<string | null>(null);
  const { contacts: contactsWithOrderEntryEnabled } =
    useContactsForMeAsThirdPartyUser({
      filterByCustomerPortalOrderEntryEnabled: true,
    });
  const firstContactForCompany = contactsWithOrderEntryEnabled.find(
    (c) => c.company.uuid === companyUuid,
  );
  const navigate = useNavigate();

  const handleCreateOrder = () => {
    if (isNilOrEmptyString(firstContactForCompany?.uuid)) {
      return;
    }
    onClose();
    navigate('/customer-portal/orders/add', {
      state: { contactUuid: firstContactForCompany?.uuid },
    });
  };

  return (
    <PalletModal
      open
      hideCloseButton
      title="Create a new order"
      pinnedElements={{
        bottomRight: (
          <Button
            variant="contained"
            disabled={isNilOrEmptyString(companyUuid)}
            onClick={handleCreateOrder}
          >
            Create order
          </Button>
        ),
      }}
      onClose={onClose}
    >
      <OrderFormEditAccessProvider value={OrderFormEditAccess.All}>
        <Stack sx={{ minWidth: '400px', gap: 1 }}>
          <Typography>Select a carrier</Typography>
          <CustomerPortalCompanyField
            companyUuid={companyUuid}
            onChange={setCompanyUuid}
          />
        </Stack>
      </OrderFormEditAccessProvider>
    </PalletModal>
  );
};

export default CustomerPortalCompanySelectModal;
