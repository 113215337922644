import type React from 'react';
import { TableRow, TableCell, Chip, Box, Typography } from '@mui/material';
import DateField from '../../../../../../../common/components/date-field';
import { isEmpty, isNil } from 'lodash';
import { isNilOrEmptyString } from '../../../../../../../common/utils/utils';
import { type UseFormSetValue } from 'react-hook-form';
import { type OrderFormFieldValues } from '../../../forms/types';

type StopCompletedRowProps = {
  readonly idx: number;
  readonly completedAt: Date | null | undefined;
  readonly setValue: UseFormSetValue<OrderFormFieldValues>;
  readonly disabled: boolean;
  readonly equipmentNames: string | null | undefined;
  readonly driverName: string | null | undefined;
};

const StopCompletedRow: React.FC<StopCompletedRowProps> = ({
  idx,
  completedAt,
  setValue,
  disabled,
  equipmentNames,
  driverName,
}) => {
  return (
    <TableRow sx={{ border: 'none' }}>
      <TableCell sx={{ border: 'none' }}>
        <DateField
          date={completedAt ?? null}
          setDate={(date) => {
            setValue(`stops.${idx}.completedAt`, date);
          }}
          editable={!disabled}
        />
      </TableCell>
      <TableCell sx={{ border: 'none' }}>
        <Chip label="Completed" />
      </TableCell>
      <TableCell sx={{ border: 'none' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {!isNilOrEmptyString(equipmentNames) && (
            <Typography variant="body2">
              Equipment: Used {equipmentNames}
            </Typography>
          )}
        </Box>
        {!isNilOrEmptyString(driverName) && (
          <Typography color="grey" variant="body2">
            Driver: {driverName}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

export default StopCompletedRow;
