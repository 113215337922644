import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { type FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  type FieldErrors,
  type SubmitErrorHandler,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { filterNotNil } from 'shared/array';
import { exhaustive } from 'shared/switch';
import { GappedStackCard } from '../../../../common/components/gapped-stack-card';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import {
  QuoteStatus,
  ShipmentType,
  useCreateQuoteMutation,
  useUpdateQuoteMutation,
} from '../../../../generated/graphql';
import { useAppDispatch } from '../../../../redux/hooks';
import { fetchAllAccessorials } from '../../../accessorials/redux/accessorials-thunks-slice';
import Charges from '../../../orders/components/order-form/components/charges';
import { OrderCustomerField } from '../../../orders/components/order-form/components/order-customer-field';
import OrderFormErrorDialog, {
  OrderFormType,
} from '../../../orders/components/order-form/components/order-form-error-dialog';
import OrderOverLimitDialog from '../../../orders/components/order-form/components/order-over-limit-dialog';
import Packages from '../../../orders/components/order-form/components/packages';
import { DemoPackages } from '../../../orders/components/order-form/components/packages/demo/demo-packages';
import PrintMenu from '../../../orders/components/order-form/components/right-sidebar/print-menu';
import Service from '../../../orders/components/order-form/components/service';
import Stops from '../../../orders/components/order-form/components/stops/stops';
import { OrderFormEditAccessProvider } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import { OrderFormEditAccess } from '../../../orders/components/order-form/forms/use-order-form-edit-access';
import {
  convertCasing,
  createLineHaulShipmentCreateInput,
  createLineHaulShipmentUpdateInput,
  createOrderChargesShipmentCreateInput,
  createOrderChargesShipmentUpdateInput,
  createPackageArrayUpdateInput,
  createPackageCreateInput,
  createShipmentArrayUpdateInput,
  createShipmentCreateInput,
} from '../../../orders/components/order-form/forms/utils';
import { useOrderFormContact } from '../../../orders/components/order-form/hooks/use-order-form-contact';
import OrderPageToolbar from '../../../orders/components/order-form/order-page-toolbar';
import { getExpectedOrderChargesShipmentStatus } from '../../../orders/components/order-form/utils';
import { type AutocompletePerson } from '../../../orders/components/standard/components/autocomplete-person';
import {
  PageMode,
  useQuotePageMode,
} from '../../../orders/hooks/use-page-mode';
import useOrderFormStore from '../../../orders/order-form-store';
import { type QuoteFormValues } from './forms/types';
import { useLoadQuoteForm } from './hooks/use-load-quote-form';
import { QuoteNumberField } from './quote-number-field';

type QuotePageFormProps = {
  readonly quoteUuid: string | null;
};

const QuotePageForm: FunctionComponent<QuotePageFormProps> = ({
  quoteUuid,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { companyConfiguration, useAllCaps } = useMe();
  const [createdQuoteUuid, setCreatedQuoteUuid] = useState<
    string | undefined
  >();
  const { handleSubmit, setValue, watch, control } =
    useFormContext<QuoteFormValues>();
  const dispatch = useAppDispatch();
  const ffDemoSterlingLineHaul = useFeatureFlag(
    FeatureFlag.FF_DEMO_STERLING_LINE_HAUL,
  );
  const ffRobustSettlementEnabled = useFeatureFlag(
    FeatureFlag.FF_ROBUST_SETTLEMENT,
  );

  const { initializeForm, loading: quoteFormLoading } = useLoadQuoteForm();
  const [forceEditMode, setForceEditMode] = useState(false);
  const pageMode = useQuotePageMode({ forceEditMode });
  const [createQuoteMutation, { loading: createQuoteLoading }] =
    useCreateQuoteMutation();
  const [updateQuoteMutation, { loading: updateQuoteLoading }] =
    useUpdateQuoteMutation();
  const isOrderPageRating = useOrderFormStore(
    (state) => state.isOrderPageRating,
  );
  const [showErrorModal, setShowErrorModal] = useState(false);

  const printButtonRef = useRef<HTMLButtonElement>(null);
  const [showPrintMenu, setShowPrintMenu] = useState(false);

  const [showFailureMessage, setShowFailureMessage] = useState<boolean>(false);
  const { contactUuid: contactUuidToUse } = useOrderFormContact();

  const [autocompletePerson, setAutocompletePerson] =
    useState<AutocompletePerson | null>(null);

  const status = useWatch({ control, name: 'status' });

  useEffect(() => {
    dispatch(fetchAllAccessorials());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onApprove = async (values: QuoteFormValues) => {
    const stops = values.stops ?? [];
    const packages = values.packages ?? [];

    if (!isNil(quoteUuid)) {
      const shipmentArrayUpdateInputs = filterNotNil(
        stops
          .filter((stop) => {
            return stop.shipmentType === ShipmentType.Regular;
          })
          .map((stop) =>
            createShipmentArrayUpdateInput({
              stopValues: stop,
              useAllCaps,
              ffRobustSettlementEnabled,
            }),
          ),
      );
      const lineHaulShipmentUpdateInput = isNil(values.lineHaulShipment)
        ? null
        : createLineHaulShipmentUpdateInput({
            lineHaulShipment: values.lineHaulShipment,
          });
      if (!isNil(lineHaulShipmentUpdateInput))
        shipmentArrayUpdateInputs.push(lineHaulShipmentUpdateInput);
      const packageArrayUpdateInputs = filterNotNil(
        packages.map((package_) =>
          createPackageArrayUpdateInput({
            packageValues: package_,
            useAllCaps,
          }),
        ),
      );
      const result = await updateQuoteMutation({
        variables: {
          updateQuoteInput: {
            quoteUpdateInput: {
              contactUuid: values.contactUuid,
              number: values.number ?? '',
              serviceUuid: values.serviceUuid,
              shipmentArrayUpdateInputs,
              packageArrayUpdateInputs,
            },
            uuid: quoteUuid,
          },
        },
      });
      if (!isNil(result.data?.updateQuote.uuid)) {
        navigate(
          `/orders/quotes/${quoteUuid}/approve?billingPartyContactUuid=${values.contactUuid}`,
        );
      }
    }
  };

  const onSubmitError: SubmitErrorHandler<QuoteFormValues> = async (
    errors: FieldErrors<QuoteFormValues>,
  ) => {
    setShowErrorModal(true);

    // eslint-disable-next-line no-console
    console.error('[onSubmitError] errors', errors);
  };

  const onSubmit =
    ({ saveAndPrint }: { saveAndPrint?: boolean }) =>
    async () => {
      const formValues = watch();
      if (isNil(formValues)) {
        return;
      }

      const stops = formValues.stops ?? [];
      const packages = formValues.packages ?? [];

      switch (pageMode) {
        case PageMode.CREATE: {
          const shipmentCreateInputs = filterNotNil(
            stops
              .filter((stop) => {
                return stop.shipmentType === ShipmentType.Regular;
              })
              .map((stop) =>
                createShipmentCreateInput({
                  stopValues: stop,
                  useAllCaps,
                  ffRobustSettlementEnabled,
                }),
              ),
          );
          const lineHaulShipmentCreateInput = isNil(formValues.lineHaulShipment)
            ? null
            : createLineHaulShipmentCreateInput({
                lineHaulShipment: formValues.lineHaulShipment,
              });
          if (!isNil(lineHaulShipmentCreateInput) && formValues.isUsingLineHaul)
            shipmentCreateInputs.push(lineHaulShipmentCreateInput);

          const orderChargesShipmentCreateInput = isNil(
            formValues.orderChargesShipment,
          )
            ? null
            : createOrderChargesShipmentCreateInput({
                orderChargesShipmentValues: formValues.orderChargesShipment,
              });
          const areOrderChargesShipmentExpected =
            getExpectedOrderChargesShipmentStatus({
              // ASSUMPTION: This is null because we assume that quotes do not have a fulfillment type.
              fulfillmentType: null,
              inboundStopType: stops[0]?.stopType,
              outboundStopType: stops[1]?.stopType,
            }).present;
          if (
            !isNil(orderChargesShipmentCreateInput) &&
            areOrderChargesShipmentExpected
          ) {
            shipmentCreateInputs.push(orderChargesShipmentCreateInput);
          }

          const packageCreateInputs = filterNotNil(
            packages.map((package_) =>
              createPackageCreateInput({
                packageValues: package_,
                useCentimeters: formValues?.useCentimeters ?? undefined,
                useKilograms: formValues?.useKilograms ?? undefined,
                useAllCaps,
              }),
            ),
          );
          const result = await createQuoteMutation({
            variables: {
              createQuoteInput: {
                quoteCreateInput: {
                  contactUuid: formValues.contactUuid,
                  number: convertCasing(formValues.number, useAllCaps) ?? '',
                  serviceUuid: formValues.serviceUuid,
                  shipmentCreateInputs,
                  packageCreateInputs,
                  lineHaulLaneUuid: formValues.isUsingLineHaul
                    ? formValues.lineHaulLaneUuid
                    : null,
                  dimFactor: formValues.dimFactor,
                  totalSkids: formValues.totalSkids,
                },
              },
            },
          });
          const newQuoteUuid = result.data?.createQuote.uuid;
          if (!isNil(newQuoteUuid)) {
            if (saveAndPrint === true) {
              setCreatedQuoteUuid(newQuoteUuid);
              setValue('uuid', newQuoteUuid);
              globalThis.history.replaceState(
                {},
                '',
                `/order-entry/quotes/${newQuoteUuid}`,
              );
              setForceEditMode(true);
              setShowPrintMenu(true);
            } else {
              navigate('/orders/quotes');
            }
          }
          break;
        }
        case PageMode.EDIT: {
          const updateQuoteUuid = isNil(createdQuoteUuid)
            ? quoteUuid
            : createdQuoteUuid;
          if (!isNil(updateQuoteUuid)) {
            const shipmentArrayUpdateInputs = filterNotNil(
              stops
                .filter((stop) => {
                  return stop.shipmentType === ShipmentType.Regular;
                })
                .map((stop) =>
                  createShipmentArrayUpdateInput({
                    stopValues: stop,
                    useAllCaps,
                    ffRobustSettlementEnabled,
                  }),
                ),
            );

            const lineHaulShipmentUpdateInput = isNil(
              formValues.lineHaulShipment,
            )
              ? null
              : createLineHaulShipmentUpdateInput({
                  lineHaulShipment: formValues.lineHaulShipment,
                });
            if (
              !isNil(lineHaulShipmentUpdateInput) &&
              formValues.isUsingLineHaul
            )
              shipmentArrayUpdateInputs.push(lineHaulShipmentUpdateInput);

            const orderChargesShipmentUpdateInput = isNil(
              formValues.orderChargesShipment,
            )
              ? null
              : createOrderChargesShipmentUpdateInput({
                  orderChargesShipmentValues: formValues.orderChargesShipment,
                });
            const areOrderChargesShipmentExpected =
              getExpectedOrderChargesShipmentStatus({
                // ASSUMPTION: This is null because we assume that quotes do not have a fulfillment type.
                fulfillmentType: null,
                inboundStopType: stops[0]?.stopType,
                outboundStopType: stops[1]?.stopType,
              }).present;
            if (
              !isNil(orderChargesShipmentUpdateInput) &&
              areOrderChargesShipmentExpected
            ) {
              shipmentArrayUpdateInputs.push(orderChargesShipmentUpdateInput);
            }

            const packageArrayUpdateInputs = filterNotNil(
              packages.map((package_) =>
                createPackageArrayUpdateInput({
                  packageValues: package_,
                  useAllCaps,
                  useCentimeters: formValues?.useCentimeters ?? undefined,
                  useKilograms: formValues?.useKilograms ?? undefined,
                }),
              ),
            );
            const result = await updateQuoteMutation({
              variables: {
                updateQuoteInput: {
                  quoteUpdateInput: {
                    contactUuid: formValues.contactUuid,
                    number: formValues.number ?? '',
                    shipmentArrayUpdateInputs,
                    packageArrayUpdateInputs,
                    dimFactor: formValues.dimFactor,
                    lineHaulLaneUuid: formValues.lineHaulLaneUuid,
                    totalSkids: formValues.totalSkids,
                    serviceUuid: formValues.serviceUuid,
                  },
                  uuid: updateQuoteUuid,
                },
              },
            });
            if (!isNil(result.data?.updateQuote.uuid)) {
              navigate('/orders/quotes');
            }
          }
          break;
        }
        default: {
          exhaustive(pageMode);
        }
      }
    };

  useEffect(() => {
    if (!isNil(companyConfiguration)) {
      initializeForm({
        quoteUuid,
        contactUuid: contactUuidToUse,
        terminalsEnabled: companyConfiguration.terminalsEnabled,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteUuid, companyConfiguration]);

  const loading =
    quoteFormLoading ||
    isOrderPageRating === true ||
    createQuoteLoading ||
    updateQuoteLoading;

  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showFailureMessage}
        onClose={() => {
          setShowFailureMessage(false);
        }}
      >
        <Alert severity="error">Quote failed to save</Alert>
      </Snackbar>
      <OrderFormErrorDialog
        handleClose={() => {
          setShowErrorModal(false);
        }}
        open={showErrorModal}
        type={OrderFormType.Quote}
      />
      <OrderOverLimitDialog isQuote contactUuid={contactUuidToUse} />
      <Stack sx={{ maxHeight: '100%', overflow: 'scroll' }}>
        <OrderPageToolbar
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 3,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
            }}
          >
            <Button
              variant="text"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
            <OrderCustomerField disabled={false} />
            <QuoteNumberField />
          </Box>
          <Stack direction="row" spacing={2} sx={{ pr: 1 }}>
            <Button
              ref={printButtonRef}
              startIcon={loading ? <CircularProgress size={20} /> : null}
              disabled={loading}
              size="small"
              variant="outlined"
              endIcon={<ArrowDropDownIcon />}
              onClick={async () => {
                if (pageMode === PageMode.CREATE) {
                  await onSubmit({ saveAndPrint: true })();
                } else {
                  setShowPrintMenu(!showPrintMenu);
                }
              }}
            >
              {pageMode === PageMode.CREATE ? 'Save + ' : ''}Print
            </Button>
            {pageMode === PageMode.EDIT && status === QuoteStatus.Pending && (
              <Button
                startIcon={loading ? <CircularProgress size={20} /> : null}
                disabled={loading}
                variant="contained"
                onClick={handleSubmit(onApprove, onSubmitError)}
              >
                Approve
              </Button>
            )}
            <Button
              startIcon={loading ? <CircularProgress size={20} /> : null}
              disabled={loading}
              variant="contained"
              onClick={onSubmit({ saveAndPrint: false })}
            >
              Save
            </Button>
          </Stack>
        </OrderPageToolbar>
        <Box bgcolor={theme.palette.grey[100]} p="12px">
          <GappedStackCard
            style={{
              maxWidth: '1280px',
              border: `1px solid ${theme.palette.borderColor.main}`,
              overflow: 'auto',
              borderRadius: '4px',
            }}
          >
            <OrderFormEditAccessProvider value={OrderFormEditAccess.All}>
              <Stack
                sx={{
                  p: 2,
                  gap: 2,
                  backgroundColor: 'white',
                }}
              >
                <Service isEditMode />
              </Stack>
              <Stops
                isQuotePage
                isEditMode
                forceEditMode={forceEditMode}
                autocompletePerson={autocompletePerson}
                setAutocompletePerson={setAutocompletePerson}
              />
              {ffDemoSterlingLineHaul ? <DemoPackages /> : <Packages />}
              <Charges />
            </OrderFormEditAccessProvider>
          </GappedStackCard>
        </Box>
        <PrintMenu
          isFromQuotesPage
          showPrintMenu={showPrintMenu}
          buttonRef={printButtonRef.current}
          isEditMode={false}
          onClose={() => {
            setShowPrintMenu(false);
          }}
        />
      </Stack>
    </>
  );
};

export default QuotePageForm;
