import { EnvironmentVariables } from '../../environment-variables';
export const DEV_APP_BAR_COLOR = '#e366b1';
export const DEV_SELECTED_NAVBAR_COLOR = '#f8d8eb';

export enum DefaultPalletPage {
  ORDERS = '/orders',
  EMAIL_INGESTION = '/email-ingestion',
}

const TRYPALLET_HOME_URL = 'https://trypallet.com';
export const TRYPALLET_PRIVACY_POLICY_URL = `${TRYPALLET_HOME_URL}/privacy-policy`;
export const TRYPALLET_ABOUT_US_URL = `${TRYPALLET_HOME_URL}/about`;
export const TRYPALLET_CONTACT_US_URL = `${TRYPALLET_HOME_URL}/demo`;
