import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import {
  Button,
  Divider,
  OutlinedInput,
  Popover,
  Stack,
  Tooltip,
  Typography,
  Box,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import AccordionDropdown from '../../../../../common/components/accordion-dropdown';
import CenteredCircularProgress from '../../../../../common/components/centered-circular-progress';
import useOrderTableFieldHeaders from '../../../../../common/react-hooks/orders/use-order-table-field-headers';
import {
  type OrderTableField,
  type FilterViewPage,
} from '../../../../../generated/graphql';
import { ALL_GROUPED_ORDER_TABLE_FIELDS } from '../../../../orders/constants';
import { useOrderTableFieldsAccordionState } from '../../hooks/use-order-table-fields-accordion-state';
import { ConfigureOrderColumnsNewPills } from './configure-order-columns-new-pills';

const CONFIGURE_ORDER_TABLE_COLUMNS_NEW_POPOVER_WIDTH = '500px';

type ConfigureOrderTableColumnsNewProps = {
  readonly anchorEl: HTMLElement | null;
  readonly initialFields: OrderTableField[];
  readonly onClose: () => void;
  readonly onSaveOrderTableColumns: (
    fields: OrderTableField[],
  ) => Promise<void>;
  readonly pageType: FilterViewPage;
};

const ConfigureOrderTableColumnsNew = ({
  anchorEl,
  initialFields,
  onClose,
  onSaveOrderTableColumns,
  pageType,
}: ConfigureOrderTableColumnsNewProps) => {
  const { orderTableFieldHeaders, orderTableFieldHeadersLoading } =
    useOrderTableFieldHeaders();

  const [selectedFields, setSelectedFields] =
    useState<OrderTableField[]>(initialFields);

  useEffect(() => {
    setSelectedFields(initialFields);
  }, [initialFields]);

  const { accordionState, setAccordionState, accordionSearchText, setFilter } =
    useOrderTableFieldsAccordionState(
      ALL_GROUPED_ORDER_TABLE_FIELDS,
      pageType,
      selectedFields,
    );

  const disableSave = isEmpty(selectedFields);
  const onApply = () => {
    if (disableSave) {
      return;
    }
    onSaveOrderTableColumns(selectedFields);
    onClose();
  };

  const toggleAccordionSelection = (columnId: OrderTableField) => {
    if (selectedFields.includes(columnId)) {
      setSelectedFields((prev) => prev.filter((field) => field !== columnId));
    } else {
      setSelectedFields((prev) => [...prev, columnId]);
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  return (
    <Popover
      open={!isNil(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      onClose={onClose}
    >
      <Stack
        width={CONFIGURE_ORDER_TABLE_COLUMNS_NEW_POPOVER_WIDTH}
        maxHeight="80vh"
        maxWidth="80vw"
      >
        <Box overflow="auto" flexGrow={1} minHeight={0}>
          <OutlinedInput
            placeholder="Search"
            value={accordionSearchText}
            startAdornment={
              <SearchRoundedIcon
                sx={{ color: (theme) => theme.palette.text.secondary }}
              />
            }
            inputProps={{
              sx: {
                padding: 1,
                fontSize: '14px',
              },
            }}
            sx={{
              paddingX: 1.5,
              paddingY: 1,
              '& fieldset': {
                border: 'none',
              },
            }}
            onChange={onInputChange}
          />
          <Divider />
          <Stack direction="column" spacing={1} paddingX={2} paddingY={1}>
            <Typography
              variant="body2"
              fontWeight={500}
              color="text.secondary"
              fontSize={12}
            >
              Visible columns
            </Typography>
            <ConfigureOrderColumnsNewPills
              fields={selectedFields}
              orderTableFieldHeaders={orderTableFieldHeaders}
              setFields={setSelectedFields}
            />
          </Stack>
          <Divider />
          {orderTableFieldHeadersLoading ? (
            <CenteredCircularProgress />
          ) : (
            <AccordionDropdown<OrderTableField>
              wrapContent
              accordionState={accordionState}
              setAccordionState={setAccordionState}
              toggleSelection={toggleAccordionSelection}
            />
          )}
        </Box>
        <Divider />
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          padding={2}
          width="100%"
        >
          <Tooltip title={disableSave && 'Select at least one column'}>
            {/* span required so the tooltip has an anchor when the button is disabled */}
            <span>
              <Button
                variant="contained"
                disabled={orderTableFieldHeadersLoading || disableSave}
                onClick={onApply}
              >
                Apply
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </Stack>
    </Popover>
  );
};

export default ConfigureOrderTableColumnsNew;
