import dayjs from 'dayjs';
import { isNil, omit } from 'lodash';
import { type DefaultValues } from 'react-hook-form';
import { exhaustive } from 'shared/switch';
import {
  ShipmentType,
  StopType,
  type StandardOrderFragmentFragment,
} from '../../../../../generated/graphql';
import {
  deriveLogicalStopType,
  isInboundStop,
  isOutboundStop,
  isPartnerCarrierStop,
  StopMethod,
} from '../../../../orders/components/order-form/forms/stop-type';
import { CustomerPortalInformationLocationType } from './enums';
import {
  type CustomerPortalAddressValues,
  type CustomerPortalOrderFormValues,
  type StopTypeWithoutNone,
} from './types';

export const CUSTOMER_PORTAL_ORDER_DEFAULT_VALUES: DefaultValues<CustomerPortalOrderFormValues> =
  { packages: [], documents: [] };

const convertShipmentToStopInformation = (
  shipment: StandardOrderFragmentFragment['shipments'][number],
): CustomerPortalOrderFormValues['inboundInformation'] | undefined => {
  const stop = shipment.legs[0]?.endStop;
  const address = stop?.address;
  const stopType = deriveLogicalStopType(
    stop?.stopType,
    stop?.inboundMethod,
    stop?.outboundMethod,
  );
  if (isNil(stop) || isNil(stopType) || stopType === StopType.None) {
    return undefined;
  }
  // TODO: can we use Temporal for this?
  const serviceDate = isNil(stop.serviceDate as string | null)
    ? undefined
    : dayjs(stop.serviceDate as string | null);
  const deadlineDate = isNil(shipment.fields?.deadlineDate as string | null)
    ? undefined
    : dayjs(shipment.fields?.deadlineDate as string | null);
  const commonFields = {
    serviceDate,
    deadlineDate,
    specialInstructions: stop?.specialInstructions ?? undefined,
  };
  if (isPartnerCarrierStop(stopType)) {
    if (isNil(stop.terminal?.uuid)) {
      return undefined;
    }
    return {
      ...commonFields,
      locationType: CustomerPortalInformationLocationType.Terminal,
      terminalId: stop.terminal?.uuid,
    };
  }
  if (isNil(address)) {
    return undefined;
  }
  return {
    ...commonFields,
    locationType: CustomerPortalInformationLocationType.Address,
    address: {
      name: address.name,
      line1: address.line1,
      line2: address.line2 ?? undefined,
      city: address.city,
      state: address.state,
      zipcode: address.zip,
      country: address.country,
    },
  };
};

/** Converts a StandardOrderFragmentFragment to CustomerPortalOrderFormValues */
export const getExistingCustomerPortalOrderDefaultValues = (
  standardOrder: StandardOrderFragmentFragment,
): DefaultValues<CustomerPortalOrderFormValues> => {
  const inboundShipment = standardOrder.shipments.find(
    (s) =>
      s.shipmentType === ShipmentType.Regular &&
      !isNil(s.legs[0]?.endStop?.stopType) &&
      isInboundStop(s.legs[0]?.endStop?.stopType),
  );
  const outboundShipment = standardOrder.shipments.find(
    (s) =>
      s.shipmentType === ShipmentType.Regular &&
      !isNil(s.legs[0]?.endStop?.stopType) &&
      isOutboundStop(s.legs[0]?.endStop?.stopType),
  );
  return {
    hawb:
      standardOrder.standardOrderFields.shipperBillOfLadingNumber ?? undefined,
    mawb:
      standardOrder.standardOrderFields.masterAirwayBillOfLadingNumber ??
      undefined,
    serviceId: standardOrder.service?.uuid ?? undefined,
    documents: standardOrder.documents.map((doc) => ({
      s3Url: doc.preSignedGetUrl,
      type: doc.type,
      fileName: doc.fileName,
      fileType: doc.fileType,
    })),
    packages: standardOrder.packages.map((pkg) => ({
      description: pkg.description,
      quantity: pkg.quantity,
      weight: pkg.weight ?? undefined,
      length: pkg.length ?? undefined,
      width: pkg.width ?? undefined,
      height: pkg.height ?? undefined,
      packageType: pkg.type ?? undefined,
    })),
    inboundInformation: isNil(inboundShipment)
      ? undefined
      : convertShipmentToStopInformation(inboundShipment),
    outboundInformation: isNil(outboundShipment)
      ? undefined
      : convertShipmentToStopInformation(outboundShipment),
    inBondInformation: isNil(standardOrder.itTeNumber)
      ? undefined
      : {
          itTeNumber: standardOrder.itTeNumber,
        },
    hazmatInformation:
      !isNil(standardOrder.hazmatClass) ||
      !isNil(standardOrder.hazmatDescription)
        ? {
            hazmatClass: standardOrder.hazmatClass ?? undefined,
            hazmatDescription: standardOrder.hazmatDescription ?? undefined,
          }
        : undefined,
  };
};

// Return the address or terminalId from the stop information,
// depending on the stop type.
export const getStopLocationInfo = (
  stopInformation: CustomerPortalOrderFormValues['inboundInformation'],
  stopType: StopTypeWithoutNone | null,
):
  | { address: CustomerPortalAddressValues }
  | { terminalId: string }
  | undefined => {
  if (isNil(stopInformation) || isNil(stopType)) {
    return undefined;
  }
  if (isPartnerCarrierStop(stopType)) {
    if (!('terminalId' in stopInformation)) {
      return;
    }
    return { terminalId: stopInformation.terminalId };
  }
  if (!('address' in stopInformation)) {
    return;
  }
  return { address: stopInformation.address };
};

const getOrderStopInformation = (
  stopInformation: CustomerPortalOrderFormValues['inboundInformation'],
  stopType: StopTypeWithoutNone | null,
):
  | ({
      serviceDate: string | undefined;
      deadlineDate: string | undefined;
      specialInstructions: string | undefined;
    } & ({ address: CustomerPortalAddressValues } | { terminalId: string }))
  | undefined => {
  const locationInfo = getStopLocationInfo(stopInformation, stopType);
  if (isNil(locationInfo)) {
    return undefined;
  }
  return {
    ...locationInfo,
    specialInstructions: stopInformation?.specialInstructions ?? undefined,
    serviceDate: stopInformation?.serviceDate?.format('YYYY-MM-DD'),
    deadlineDate: stopInformation?.deadlineDate?.format('YYYY-MM-DD'),
  };
};

/**
 * Converts a CustomerPortalOrderFormValues to a CreateOrderV3Request
 *
 * TODO: should we apply the useAllCaps company config to this payload?
 */
export const getCreateOrderV3Request = ({
  orderValues,
  clientId,
  customerId,
  quoteId,
  inboundStopType,
  outboundStopType,
}: {
  orderValues: CustomerPortalOrderFormValues;
  clientId: string;
  customerId: string;
  quoteId: string | null;
  inboundStopType: StopTypeWithoutNone | null;
  outboundStopType: StopTypeWithoutNone | null;
}) => {
  const {
    hawb,
    mawb,
    serviceId,
    packages,
    documents,
    inboundInformation,
    outboundInformation,
    inBondInformation,
    hazmatInformation,
  } = orderValues;

  const baseRequest: Record<string, unknown> = {
    hawb,
    mawb,
    serviceId,
    packages,
    documents,
    inBondInformation,
    hazmatInformation,
    customerId,
    clientId,
  };

  // If one of these fields is undefined, we can't include it in the request
  // otherwise Zod will complain about the unexpected field.

  if (!isNil(quoteId)) {
    baseRequest.quoteId = quoteId;
  }

  if (!isNil(inboundInformation)) {
    const stopInformation = getOrderStopInformation(
      inboundInformation,
      inboundStopType,
    );
    if (!isNil(stopInformation)) {
      baseRequest.inboundInformation = stopInformation;
    }
  }
  if (!isNil(outboundInformation)) {
    const stopInformation = getOrderStopInformation(
      outboundInformation,
      outboundStopType,
    );
    if (!isNil(stopInformation)) {
      baseRequest.outboundInformation = stopInformation;
    }
  }
  return baseRequest;
};

/**
 * Given a stop information and a new stop type:
 * - If the new stop type is partner carrier, clear the address and set the terminalId to undefined
 * - If the new stop type is non-partner carrier, clear the terminalId and set the address to undefined
 */
export const updateStopInformationAddressOrTerminalId = (
  information: CustomerPortalOrderFormValues['inboundInformation'],
  oldStopType: StopTypeWithoutNone | null,
  newStopType: StopTypeWithoutNone | null,
): CustomerPortalOrderFormValues['inboundInformation'] | undefined => {
  if (newStopType === oldStopType) {
    return information;
  }

  if (newStopType === null) {
    return undefined;
  }

  // Clear address or terminalId and re-initialize the stop with the other field
  const newInformation = omit(information, [
    'address',
    'terminalId',
    'locationType',
  ]);
  return isPartnerCarrierStop(newStopType)
    ? {
        ...newInformation,
        locationType: CustomerPortalInformationLocationType.Terminal,
        // There's no meaningful default value we could use for terminalId
        terminalId: undefined as unknown as string,
      }
    : {
        ...newInformation,
        locationType: CustomerPortalInformationLocationType.Address,
        // There's no meaningful default value we could use for address
        address: undefined as unknown as CustomerPortalAddressValues,
      };
};

/** Given the stop method, returns the RHF field name for inbound or outbound information */
export const getCustomerPortalOrderFormStopField = (
  stopMethod: StopMethod,
): 'inboundInformation' | 'outboundInformation' => {
  switch (stopMethod) {
    case StopMethod.Inbound: {
      return 'inboundInformation';
    }
    case StopMethod.Outbound: {
      return 'outboundInformation';
    }
    default: {
      return exhaustive(stopMethod);
    }
  }
};

/**
 * Given a stop method and the service level's default stop types,
 * returns the stop type for the given stop method.
 *
 * Since both inbound and outbound stop types are nullable on ServiceLevel,
 * we treat `None` and `null` as the same thing (and return `null`).
 */
export const getStopTypeFromServiceLevel = (
  stopMethod: StopMethod,
  inboundStopType: StopType | null,
  outboundStopType: StopType | null,
): StopTypeWithoutNone | null => {
  switch (stopMethod) {
    case StopMethod.Inbound: {
      if (inboundStopType === StopType.None) {
        return null;
      }
      return inboundStopType;
    }
    case StopMethod.Outbound: {
      if (outboundStopType === StopType.None) {
        return null;
      }
      return outboundStopType;
    }
    default: {
      return exhaustive(stopMethod);
    }
  }
};
