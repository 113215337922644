import { Stack, TextField, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { DispatchMultiplayerAction } from '../../../../common/multiplayer/types/dispatch';
import useMultiplayer from '../../../../common/multiplayer/use-multiplayer';
import {
  DriversWithRoutesDocument,
  NumberOfStopsDocument,
  type RouteFragment,
  RoutesDocument,
  useUpdateRouteMutation,
} from '../../../../generated/graphql';
import useFetchRoutes from '../../hooks/use-fetch-routes';

const RouteNameField = ({
  route,
  color = 'black',
  forceEdit = false,
  onEnter,
}: {
  readonly route: RouteFragment;
  readonly color?: string;
  readonly forceEdit?: boolean;
  readonly onEnter?: () => void;
}) => {
  const { sendDispatchUserLocationEvent, sendDispatchUserLeaveLocationEvent } =
    useMultiplayer();
  const [name, setName] = useState<string>('');
  const [changed, setChanged] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<boolean>(false);
  const { fetchRoute } = useFetchRoutes();
  const [updateRoute] = useUpdateRouteMutation({
    refetchQueries: [
      RoutesDocument,
      NumberOfStopsDocument,
      DriversWithRoutesDocument,
    ],
  });

  useEffect(() => {
    setShowInput(forceEdit);
  }, [forceEdit]);

  useEffect(() => {
    setName(route.name);
  }, [route]);

  const updateName = async () => {
    if (!isNil(name) && changed) {
      await updateRoute({
        variables: {
          updateRouteInput: {
            routeUpdateInput: {
              name,
              nameManuallyChanged: true,
              uuid: route.uuid,
            },
          },
        },
      });
      await fetchRoute(route.uuid);
    }
    setChanged(false);
  };

  return (
    <Stack direction="row" spacing={0}>
      {(!showInput || route.locked) && (
        <Typography noWrap variant="subtitle2" sx={{ color }}>
          {name}
        </Typography>
      )}
      {showInput && !route.locked && (
        <TextField
          sx={{ width: '170px', input: { color } }}
          data-cy="route-name-input"
          variant="standard"
          size="small"
          inputProps={{
            style: {
              fontSize: '0.875rem',
            },
          }}
          value={name}
          onDragStart={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter' && !isNil(onEnter)) {
              await updateName();
              onEnter();
              e.stopPropagation();
            }
          }}
          onChange={(e) => {
            setName(e.target.value);
            setChanged(true);
          }}
          onFocus={() => {
            sendDispatchUserLocationEvent({
              action: DispatchMultiplayerAction.EDITING,
              routeUuid: route.uuid,
            });
          }}
          onBlur={async () => {
            await updateName();
            sendDispatchUserLeaveLocationEvent();
            if (!forceEdit) {
              setShowInput(false);
            }
          }}
        />
      )}
    </Stack>
  );
};

export default memo(RouteNameField);
